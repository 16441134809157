import { history } from "../_helpers/history";
const api_url = process.env.REACT_APP_API_URL;

console.log("env var", api_url);

export const addLocations = (formdata) => {
  return (dispatch, getState) => {
    dispatch({ type: "LOCATION_ADDING" });
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    fetch(api_url + "api/v2/create_project/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "LOCATION_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 201) {
          dispatch({ type: "ADD_LOCATION", locus: res.data });
          if (res.data.success) {
            history.push({
              pathname: "/dashboard",
              state: { project_name: res.data },
            });
          }
          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          history.push("/login");

          throw res.data;
        } else {
          dispatch({ type: "LOCATION_FAIL", data: res.data });
          throw res.data;
        }
      });
  };
};

export const addCompanyLocations = (formdata) => {
  return (dispatch, getState) => {
    dispatch({ type: "LOCATION_ADDING" });
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    fetch(api_url + "api/v2/create_project/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "LOCATION_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 201) {
          dispatch({ type: "ADD_LOCATION", locus: res.data });
          if (res.data.success) {
            history.push({
              pathname: "/company-dashboard",
              state: { project_name: res.data },
            });
          }
          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          history.push("/login");

          throw res.data;
        } else {
          dispatch({ type: "LOCATION_FAIL", data: res.data });
          throw res.data;
        }
      });
  };
};
export const addBuildingLocations = (formdata) => {
  return (dispatch, getState) => {
    dispatch({ type: "NONBUILDING_ADDING" });
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    fetch(api_url + "api/asset/nonbuilding/" + id + "/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "NONBUILDING_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 201) {
          dispatch({ type: "ADD_NONBUILDING", locus: res.data });
          history.push({
            pathname: "/dashboard",
            state: { project_name: res.data },
          });
          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          history.push("/login");
          throw res.data;
        } else {
          dispatch({ type: "NONBUILDING_FAIL", data: res.data });
          throw res.data;
        }
      });
  };
};
export const getLocations = () => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    fetch(api_url + "api/portfolio/" + id + "/", { headers })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "LOCATION_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "FETCH_LOCATION", locus: res.data });

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          history.push("/login");
          throw res.data;
        } else {
          dispatch({ type: "LOCATION_FAIL", data: res.data });
          throw res.data;
        }
      });
  };
};
export const getAssets = () => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    fetch(api_url + "api/asset/" + id + "/", { headers })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "ASSET_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "FETCH_ASSET", assets: res.data });

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          history.push("/login");
          throw res.data;
        } else {
          dispatch({ type: "ASSET_FAIL", data: res.data });
          throw res.data;
        }
      });
  };
};
export const getUser = () => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    fetch(api_url + "api/user/permissions/" + id + "/", { headers })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "PERMISSION_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "FETCH_PERMISSION", permissions: res.data });

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          history.push("/login");
          throw res.data;
        } else {
          dispatch({ type: "PERMISSION_FAIL", permissions: res.data });
          throw res.data;
        }
      });
  };
};

export const getName = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    fetch(api_url + "api/search/deepsearch/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "SEARCH_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "FETCH_SEARCH", name: res.data });

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          history.push("/login");
          throw res.data;
        } else {
          dispatch({ type: "SEARCH_FAIL", name: res.data });
          throw res.data;
        }
      });
  };
};

export const getCompanyName = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");

    if (token) {
      headers["Authorization"] = `Token ${token}`;
      headers["x-typesense-api-key"] = "4BGFeVwv12CXhlhiJs8FtMrRcQIoztX7";
    }

    fetch(
      `https://search.intensel.net/collections/company_locations/documents/search?q=${formdata}&query_by=company_name,ticker&group_by=ticker,&include_fields=ticker,company_name,index`,
      {
        headers,
        method: "GET",
      }
    )
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "COMPANY_SEARCH_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "FETCH_COMPANY_SEARCH", company_name: res.data });
          console.log("search", res.data);
          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          history.push("/login");
          throw res.data;
        } else {
          dispatch({ type: "COMPANY_SEARCH_FAIL", company_name: res.data });
          throw res.data;
        }
      });
  };
};

export const getCompanyLocations = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");

    if (token) {
      headers["Authorization"] = `Token ${token}`;
      headers["x-typesense-api-key"] = "4BGFeVwv12CXhlhiJs8FtMrRcQIoztX7";
    }

    fetch(
      `https://search.intensel.net/collections/company_locations/documents/search?q=${formdata}&query_by=ticker&filter_by=ticker:=${formdata}&include_fields=address_data,latitude,longitude&per_page=50`,
      {
        headers,
        method: "GET",
      }
    )
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "COMPANY_LOCATION_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "FETCH_COMPANY_LOCATION",
            company_location: res.data,
          });

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          history.push("/login");
          throw res.data;
        } else {
          dispatch({
            type: "COMPANY_LOCATION_FAIL",
            company_location: res.data,
          });
          throw res.data;
        }
      });
  };
};
export const getCoordinates = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token =
      "pk.eyJ1IjoiaW50ZW5zZWxtYXAiLCJhIjoiY2t0anFybWpjMDBmYjMwcGd1MDN0eWsyZiJ9.mwGRBkdc2cUvG0hv66K7Ow";

    fetch(
      "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
        formdata +
        ".json?access_token=" +
        token,
      { headers, method: "GET" }
    )
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "COORDINATES_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "FETCH_COORDINATES", point: res.data });
          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          history.push("/login");
          throw res.data;
        } else {
          dispatch({ type: "COORDINATES_FAIL", point: res.data });
          throw res.data;
        }
      });
  };
};

export const getIndices = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    fetch(api_url + "api/asset/index/" + id + "/?index=" + formdata, {
      headers,
      method: "GET",
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "INDICES_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "POST_INDICES", indices: res.data });

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          history.push("/login");
          throw res.data;
        } else {
          dispatch({ type: "INDICES_FAIL", indices: res.data });
          throw res.data;
        }
      });
  };
};

export const addIndex = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    fetch(api_url + "api/asset/index/" + id + "/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "INDEX_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 201) {
          dispatch({ type: "ADD_INDEX", index: res.data });
          history.push({ pathname: "/project" });
          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          history.push("/login");
          throw res.data;
        } else {
          dispatch({ type: "INDEX_FAIL", index: res.data });
          throw res.data;
        }
      });
  };
};

export const getTransition = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    fetch(api_url + "api/report/create_carbon_analysis/" + id + "/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "TRANSITION_FAIL", transition: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 201) {
          dispatch({ type: "GET_TRANSITION", transition: res.data });
          if (res.data.success) {
            history.push({ pathname: "/project" });
          }

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          history.push("/login");
          throw res.data;
        } else {
          dispatch({ type: "TRANSITION_FAIL", transition: res.data });
          throw res.data;
        }
      });
  };
};

export const getsbti = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    fetch(api_url + "api/report/science_based_targets/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "SBTI_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "GET_SBTI", sbti: res.data });

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          history.push("/login");
          throw res.data;
        } else {
          dispatch({ type: "SBTI_FAIL", sbti: res.data });
          throw res.data;
        }
      });
  };
};

export const ModifyTransition = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    fetch(api_url + "api/report/modify_carbon_analysis/" + id + "/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "MODIFY_TRANSITION_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "MODIFY_TRANSITION", modify_transition: res.data });
          history.go(0);

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          history.push("/login");
          throw res.data;
        } else {
          dispatch({
            type: "MODIFY_TRANSITION_FAIL",
            modify_transition: res.data,
          });
          throw res.data;
        }
      });
  };
};

export const PropertyRisk = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    fetch(api_url + "api/report/create_real_estate_project/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "PROPERTY_RISK_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "PROPERTY_RISK", property: res.data });
          history.push({
            pathname: "/realestate-dashboard",
            state: res.data,
          });
          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          history.push("/login");
          throw res.data;
        } else {
          dispatch({
            type: "PROPERTY_RISK_FAIL",
            property: res.data,
          });
          throw res.data;
        }
      });
  };
};

export const getPropertyRisk = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    fetch(api_url + "api/report/get_real_estate_data/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "GET_PROPERTY_RISK_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "GET_PROPERTY_RISK", property: res.data });

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          history.push("/login");
          throw res.data;
        } else {
          dispatch({
            type: "GET_PROPERTY_RISK_FAIL",
            property: res.data,
          });
          throw res.data;
        }
      });
  };
};

export const Notification = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    fetch(api_url + "api/user/mark_notification_read/", {
      headers,
      method: "POST",
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "NOTIFICATION_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "NOTIFICATION_SUCCESS", notification: res.data });

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          history.push("/login");
          throw res.data;
        } else {
          dispatch({
            type: "NOTIFICATION_FAIL",
            property: res.data,
          });
          throw res.data;
        }
      });
  };
};
/*export const fetchChangelogs = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    fetch(api_url + "api/v2/change_logs/", {
      headers,
      method: "GET",
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "CHANGE_LOGS", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "CHANGE_LOGS", changelogs: res.data });

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          history.push("/login");
          throw res.data;
        } else {
          dispatch({
            type: "CHANGE_LOGS_FAIL",
            changelogs: res.data,
          });
          throw res.data;
        }
      });
  };
};*/
export const fetchKeywords = () => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    fetch(api_url + "api/v2/keyword_help/", {
      headers,
      method: "GET",
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "KEYWORD", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "KEYWORD", keyword: res.data });

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          history.push("/login");
          throw res.data;
        } else {
          dispatch({
            type: "KEYWORD_FAIL",
            changelogs: res.data,
          });
          throw res.data;
        }
      });
  };
};
