const initialState = {
  project: [],
  errors: {},
  csv: [],
  invoice: [],
  report: [],
  detailreport: [],
  windreport: [],
  lossreport: [],
  countrywiselossreport: [],
  overallmapimage: [],
  assetmapimage: [],
  tcfd: [],
  ppt: [],
  map: [],
  overall_map: [],
  link: [],
  list: [],
  transiton_project: [],
  carbon_project: [],
  carbon_project_data: [],
  delete_carbon_project_data: [],
  download_carbon_project_data: [],
  filter: [],
};

export default function project(state = initialState, action) {
  switch (action.type) {
    case "ADD_PROJECT":
      return { ...state, project: action.project };
    case "ADD_REPORT":
      return { ...state, report: action.report };
    case "DELETE_PROJECT":
      return { ...state, project: action.project };
    case "FETCH_PROJECT":
      return { ...state, project: action.project };
    case "CARBON_PROJECT":
      return { ...state, carbon_project: action.carbon_project };
    case "GET_CARBON_PROJECT":
      return { ...state, carbon_project_data: action.carbon_project_data };
    case "DELETE_CARBON_PROJECT":
      return {
        ...state,
        delete_carbon_project_data: action.delete_carbon_project_data,
      };
    case "DOWNLOAD_CARBON_PROJECT":
      return {
        ...state,
        download_carbon_project_data: action.download_carbon_project_data,
      };
    case "UPDATE_FILTER":
      return {
        ...state,
        filter: action.filter,
      };
    case "FETCH_PROJECT_LIST":
      return { ...state, list: action.list };
    case "FETCH_INVOICE":
      return { ...state, invoice: action.invoice };
    case "FETCH_REPORT":
      return { ...state, report: action.report };
    case "TRANSITION_PROJECT":
      return { ...state, transition_project: action.transition_project };
    case "FETCH_DETAILREPORT":
      return { ...state, detailreport: action.detailreport };
    case "FETCH_LOSSREPORT":
      return { ...state, lossreport: action.lossreport };
    case "FETCH_WINDREPORT":
      return { ...state, windreport: action.windreport };
    case "FETCH_COUNTRYWISELOSSREPORT":
      return { ...state, countrywiselossreport: action.countrywiselossreport };
    case "FETCH_OVERALLMAPIMAGE":
      return { ...state, overallmapimage: action.overallmapimage };
    case "FETCH_ASSETMAPIMAGE":
      return { ...state, assetmapimage: action.assetmapimage };
    case "FETCH_TCFDREPORT":
      return { ...state, tcfd: action.tcfd };
    case "FETCH_PPTREPORT":
      return { ...state, ppt: action.ppt };
    case "FETCH_ASSETMAP":
      return { ...state, map: action.map };
    case "FETCH_MAP":
      return { ...state, overall_map: action.overall_map };
    case "FETCH_LINK":
      return { ...state, link: action.link };
    case "FETCH_INSURANCE":
      return { ...state, insurance: action.insurance };
    case "AUTHENTICATION_ERROR":
    case "PROJECT_FAIL":
      return { ...state, errors: action.data, project: null };
    case "CARBON_PROJECT_FAIL":
      return { ...state, errors: action.data, carbon_project: null };
    case "DELETE_CARBON_PROJECT_FAIL":
      return { ...state, errors: action.data, delete_carbon_project: null };
    case "DOWNLOAD_CARBON_PROJECT_FAIL":
      return {
        ...state,
        errors: action.data,
        download_carbon_project_data: null,
      };
    case "UPDATE_FILTER_FAIL":
      return {
        ...state,
        errors: action.data,
        filter: null,
      };
    case "PROJECT_LIST_FAIL":
      return { ...state, errors: action.data, list: null };
    case "TRANSITION_PROJECT_FAIL":
      return { ...state, errors: action.data, transition_project: null };
    case "GET_CARBON_PROJECT_FAIL":
      return { ...state, errors: action.data, carbon_project_data: null };
    case "GET_CSV":
      return { ...state, csv: action.csv };
    case "CSV_FAIL":
      return { ...state, errors: action.data, csv: null };
    case "CSV_ERROR":
      return { ...state, csv: action.csv, errors: null };
    case "INVOICE_FAIL":
      return { ...state, errors: action.data, invoice: null };
    case "REPORT_FAIL":
      return { ...state, errors: action.data, report: null };
    case "DETAILREPORT_FAIL":
      return { ...state, errors: action.data, detailreport: null };
    case "WINDREPORT_FAIL":
      return { ...state, errors: action.data, windreport: null };
    case "LOSSREPORT_FAIL":
      return { ...state, errors: action.data, lossreport: null };
    case "COUNTRYWISELOSSREPORT_FAIL":
      return { ...state, errors: action.data, countrywiselossreport: null };
    case "OVERALLMAPIMAGE_FAIL":
      return { ...state, errors: action.data, overallmapimage: null };
    case "ASSETMAPIMAGE_FAIL":
      return { ...state, errors: action.data, assetmapimage: null };
    case "TCFDREPORT_FAIL":
      return { ...state, errors: action.data, tcfd: null };
    case "PPTREPORT_FAIL":
      return { ...state, errors: action.data, ppt: null };
    case "ASSETMAP_FAIL":
      return { ...state, errors: action.data, map: null };
    case "MAP_FAIL":
      return { ...state, errors: action.data, overall_map: null };
    case "LINK_FAIL":
      return { ...state, errors: action.data, link: null };
    case "INSURANCE_FAIL":
      return { ...state, errors: action.data, insurance: null };
    default:
      return state;
  }
}
