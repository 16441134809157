import { history } from "../_helpers/history";

const api_url = process.env.REACT_APP_API_URL;

export const getAssets = () => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    fetch(api_url + "api/asset/" + id + "/", { headers })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "ASSET_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "FETCH_ASSET", asset: res.data });

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          history.push("/login");
          throw res.data;
        } else {
          dispatch({ type: "ASSET_FAIL", data: res.data });
          throw res.data;
        }
      });
  };
};
export const updateAsset = (formdata) => {
  return (dispatch, getState) => {
    dispatch({ type: "ASSET_LOADING" });

    let headers = {};

    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    fetch(api_url + "api/v2/modify-asset/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "ASSET_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "UPDATE_ASSET", asset: res.data });
          history.go(0);
          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          history.push("/login");
          throw res.data;
        } else {
          dispatch({ type: "ASSET_FAIL", data: res.data });
          throw res.data;
        }
      });
  };
};
export const deleteAsset = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    fetch(api_url + "api/asset/delete/" + id + "/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "ASSET_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 201) {
          dispatch({ type: "DELETE_ASSET", assets: res.data });
          history.go(0);
          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          history.push("/login");
          throw res.data;
        } else {
          dispatch({ type: "ASSET_FAIL", data: res.data });
          throw res.data;
        }
      });
  };
};
export const Typhoon = (formdata) => {
  return (dispatch, getState) => {
    let headers = {};
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    fetch(api_url + "api/hazard/typhoon_track/" + id + "/", {
      headers,
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          dispatch({ type: "TYPHOON_FAIL", data: res.data });
          console.log("Server Error");
          throw res.data;
        }
      })
      .then((res) => {
        if (res.status === 201) {
          dispatch({ type: "TYPHOON_TRACK", typhoon: res.data });

          return res.data;
        } else if (res.status === 401 || res.status === 403) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          history.push("/login");
          throw res.data;
        } else {
          dispatch({ type: "TYPHOON_FAIL", data: res.data });
          throw res.data;
        }
      });
  };
};
