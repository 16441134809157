import React, { Component } from "react";
import { loadModules } from "esri-loader";

export default class PopupMap extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    loadModules(
      [
        "esri/Map",
        "esri/views/MapView",
        "esri/layers/FeatureLayer",
        "esri/widgets/Legend",
        "esri/Graphic",
        "esri/widgets/Search",
        "esri/symbols/WebStyleSymbol",
        "esri/widgets/Fullscreen",
      ],
      { css: true }
    ).then(
      ([
        ArcGISMap,
        MapView,
        FeatureLayer,
        Legend,
        Graphic,
        Search,
        WebStyleSymbol,
        Fullscreen,
      ]) => {
        console.log("type of lat", this.props.data.latitude);
        const defaultSym = {
          type: "simple-fill", // autocasts as new SimpleFillSymbol()
          outline: {
            // autocasts as new SimpleLineSymbol()
            color: [255, 255, 255, 0.5],
            width: "0.5px",
          },
        };
        const renderer = {
          type: "simple", // autocasts as new SimpleRenderer()
          symbol: defaultSym,
          // label: "Flood at RCP 85 shapefile projection",
          visualVariables: [
            {
              type: "color",
              field: "FL8570",
              stops: [
                {
                  value: 0.22,
                  color: "#FFFFCC",
                  label: "0 - 0.22",
                },
                {
                  value: 0.609,
                  color: "#FFEDA0",
                  label: "0.22 - 0.609",
                },
                {
                  value: 0.929,
                  color: "#FED976",
                  label: "0.609 - 0.929",
                },
                {
                  value: 1.25,
                  color: "#FEB24C",
                  label: "0.929 - 1.25",
                },
                {
                  value: 1.64,
                  color: "#FD8D3C",
                  label: "1.25 - 1.64",
                },
                {
                  value: 2.19,
                  color: "#FC4E2A",
                  label: "1.64 - 2.19",
                },
                {
                  value: 2.9,
                  color: "#E31A1C",
                  label: "2.19 - 2.9",
                },
                {
                  value: 4,
                  color: "#BD0026",
                  label: "2.9 - 4",
                },
                {
                  value: 6,
                  color: "#940025",
                  label: "4 - 6",
                },
                {
                  value: 8,
                  color: "#67001F",
                  label: "6 - 8",
                },
              ],
            },
          ],
        };
        // Adding shapefile as a feature layer
        const povLayer = new FeatureLayer({
          url: "https://services9.arcgis.com/VkScCoIvdvWoNy79/arcgis/rest/services/shapefiles/FeatureServer/0",
          renderer: renderer,
          title: "Flood in Hong Kong",
          popupTemplate: {
            // autocasts as new PopupTemplate()
            title: "{name}, {Type}",
            content: "Flood Value {FL8570}",
          },
        });

        const map = new ArcGISMap({
          basemap: "streets-night-vector",
          layers: [povLayer],
        });

        const view = new MapView({
          container: "viewDiv1",
          map: map,
          center: [this.props.data.longitude, this.props.data.latitude],
          zoom: 16,
        });
        var point = {
          type: "point",
          longitude: this.props.data.longitude,
          latitude: this.props.data.latitude,
        };

        var WebStyleSymbol = new WebStyleSymbol({
          name: "tear-pin-1",
          styleName: "Esri2DPointSymbolsStyle",
        });

        var pointGraphic = new Graphic({
          geometry: point,
          symbol: WebStyleSymbol,
        });

        view.graphics.add(pointGraphic);

        var fullscreen = new Fullscreen({
          view: view,
        });
        view.ui.add(fullscreen, "bottom-left");
      }
    );
  }
  componentWillUnmount() {
    if (this.view) {
      this.view.destroy();
    }
  }

  render() {
    return (
      <div
        id="viewDiv1"
        style={{ height: "200px", padding: "10px", width: "100%" }}
      ></div>
    );
  }
}
