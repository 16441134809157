import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { Icon, Button, Modal, Image, Popup } from "semantic-ui-react";
import { Progress } from "antd";

import common from "../assets/adaptation/4.png";
import toolkit_1 from "../assets/adaptation/5.png";
import toolkit_2 from "../assets/adaptation/6.png";
import toolkit_3 from "../assets/adaptation/7.png";
import toolkit_4 from "../assets/adaptation/8.png";
import toolkit_5 from "../assets/adaptation/9.png";
import toolkit_6 from "../assets/adaptation/10.png";
import toolkit_7 from "../assets/adaptation/11.png";
import toolkit_8 from "../assets/adaptation/12.png";
import toolkit_9 from "../assets/adaptation/13.png";
import toolkit_10 from "../assets/adaptation/14.png";
import toolkit_11 from "../assets/adaptation/15.png";
import toolkit_12 from "../assets/adaptation/16.png";
import toolkit_13 from "../assets/adaptation/17.png";
import toolkit_14 from "../assets/adaptation/18.png";
import toolkit_15 from "../assets/adaptation/19.png";
import toolkit_16 from "../assets/adaptation/20.png";
import toolkit_17 from "../assets/adaptation/21.png";
import toolkit_18 from "../assets/adaptation/22.png";

const Toolkit = (props) => {
  return (
    <div className="toolkit" style={{ padding: "2%" }}>
      <br />
      <br />
      <Tabs defaultActiveKey="toolkit">
        <Tab title="Adaptation Toolkits" eventKey="toolkit">
          <br />
          <Tabs defaultActiveKey="resiliency">
            <Tab
              title="Concept and Key Features of Adaptation Action"
              eventKey="resiliency"
            >
              <Image src={common} />
              <Image src={toolkit_1} />
              <Image src={toolkit_2} />
              <Image src={toolkit_3} />
            </Tab>
            <Tab title="Adaptation Planning" eventKey="planning">
              <Image src={toolkit_4} />
              <Image src={toolkit_5} />
              <Image src={toolkit_6} />
              <Image src={toolkit_7} />
              <Image src={toolkit_8} />
            </Tab>
            <Tab title="Adaptation Measures" eventKey="measures">
              <Image src={toolkit_9} />
              <Image src={toolkit_10} />
              <Image src={toolkit_11} />
            </Tab>
            <Tab title="Adaptation Strategy" eventKey="strategy">
              <Image src={toolkit_12} />
              <Image src={toolkit_13} />
              <Image src={toolkit_14} />
              <Image src={toolkit_15} />
              <Image src={toolkit_16} />
            </Tab>
            <Tab title="More Resources" eventKey="resources">
              <Image src={toolkit_17} />
              <Image src={toolkit_18} />
            </Tab>
          </Tabs>
        </Tab>
      </Tabs>
    </div>
  );
};

export default Toolkit;
