import React, { useState, useEffect } from "react";
import whiteswan from "../assets/logo1.png";
import { auth } from "../actions";
import { connect } from "react-redux";
import logo from "../assets/logo.png";
import Profile from "../assets/profile.png";
import "./navmenu.css";
import {
  Navbar,
  Nav,
  Container,
  Breadcrumb,
  Row,
  NavDropdown,
} from "react-bootstrap";
import { Button } from "semantic-ui-react";

const TopNavbar = ({ logout }) => {
  const [tierData, setTierData] = useState(null);

  useEffect(() => {
    const tierDataFromLocalStorage = localStorage.getItem("tier_data");
    if (tierDataFromLocalStorage) {
      try {
        const parsedTierData = JSON.parse(tierDataFromLocalStorage);
        setTierData(parsedTierData);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        // Handle the error here, such as setting default state or displaying an error message.
      }
    }
  }, []);
  console.log("tier", tierData);

  const handleLogout = () => {
    logout();
  };

  return (
    <>
      <Navbar collpaseOnSelect fixed="top" expand="sm" bg="dark" variant="dark">
        <Container fluid>
          <Row>
            <Navbar.Brand style={{ padding: "0.5em" }}>
              <img src={logo} height="40" width="160" />
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="topnav">
                <Nav.Link href="/project">Projects</Nav.Link>
                <NavDropdown id="modulesdd" title="Modules">
                  <NavDropdown.Item href="/location">
                    Physical Risk
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/transition">
                    Transition Risk
                  </NavDropdown.Item>

                  {/* {tierData &&
                  tierData.allowed_modules.includes("real_estate") ? (
                    <NavDropdown.Item href="/realestate">
                      Real Estate
                    </NavDropdown.Item>
                  ) : null}*/}
                </NavDropdown>
                <NavDropdown id="documents" title="Documents">
                  <NavDropdown.Item href="/methodology">
                    Methodology
                  </NavDropdown.Item>
                  {/*<NavDropdown.Item href="/toolkit">
                    Adaptation Toolkit
                  </NavDropdown.Item>*/}
                  <NavDropdown.Item href="https://docs.intensel.net/">
                    APIDocs
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/changelog">
                    ChangeLogs
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Row>
        </Container>
      </Navbar>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.auth.errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(auth.logout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopNavbar);
