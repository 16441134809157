import React, { Component } from "react";

import { Row, Col } from "react-bootstrap";
import { ResponsiveBar } from "@nivo/bar";
import { Column } from "@ant-design/charts";

import { Button } from "semantic-ui-react";

import { Modal } from "semantic-ui-react";

let data = [];
let colors = [];
let config = {};

export default class YEARDonut extends Component {
  state = {
    activeIndex: 0,
    piedata: "",
    key_finding: false,
    analysis: "rcp",
  };

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index,
      piedata: "",
    });
  };
  handleKeyFindings = () => {
    this.setState({
      key_finding: true,
    });
  };
  handleClose = () => {
    this.setState({
      key_finding: false,
    });
  };

  render() {
    this.state.piedata = this.props.data;
    this.state.analysis = this.props.analysis;

    let x = 0;
    let y = 0;
    let z = 0;
    let w = 0;
    let max = 0;

    if (this.state.analysis == "rcp" && this.state.piedata["rcp_constant"]) {
      x = this.state.piedata["rcp_constant"]["2020"];
      y = this.state.piedata["rcp_constant"][`2030`];
      z = this.state.piedata["rcp_constant"][`2050`];
      max = this.state.piedata["rcp_max"];

      if (x > 1000 || y > 1000 || z > 1000) {
        data = [
          {
            Year: "2020",
            Loss: parseFloat(x.toFixed(2)),
          },
          {
            Year: "2030",
            Loss: parseFloat(y.toFixed(2)),
          },
          {
            Year: "2050",
            Loss: parseFloat(z.toFixed(2)),
          },
        ];
        config = {
          data,
          xField: "Year",
          yAxis: {
            title: {
              text: "Loss in Million USD",
            },
            max: max + 0.2 * max,
          },
          yField: "Loss",
          minColumnWidth: 20,
          maxColumnWidth: 20,
          legend: {
            layout: "vertical",
            position: "right",
          },
          label: {
            position: "top",

            style: {
              fill: "#000000",
              opacity: 0.6,
            },
          },
          xAxis: {
            label: {
              autoHide: true,
              autoRotate: false,
            },
          },
          colorField: "Year",
          seriesField: "Year",

          color: ["#f47a7b", "#8cb0f8", "#002060"],
        };
      } else {
        data = [
          {
            Year: "2020",
            Loss: parseFloat(x.toFixed(2)),
          },
          {
            Year: "2030",
            Loss: parseFloat(y.toFixed(2)),
          },
          {
            Year: "2050",
            Loss: parseFloat(z.toFixed(2)),
          },
        ];
        config = {
          data,
          yAxis: {
            title: {
              text: "Loss in Million USD",
            },
            max: max + 0.2 * max,
          },

          xAxis: {
            label: {
              content: "Year",
            },
          },

          xField: "Year",
          yField: "Loss",
          minColumnWidth: 20,
          maxColumnWidth: 20,
          legend: {
            layout: "vertical",
            position: "right",
          },
          label: {
            position: "top",

            style: {
              fill: "#000000",
              opacity: 0.6,
            },
          },

          colorField: "Year",
          seriesField: "Year",

          color: ["#f47a7b", "#8cb0f8", "#002060"],
        };
      }
    } else if (
      this.state.analysis === "ssp" &&
      this.state.piedata["rcp_constant"]["2100"]
    ) {
      x = this.state.piedata["rcp_constant"]["2020"];
      y = this.state.piedata["rcp_constant"][`2030`];
      z = this.state.piedata["rcp_constant"][`2050`];
      w = this.state.piedata["rcp_constant"]["2100"];
      max = this.state.piedata["rcp_max"];

      if (x > 1000 || y > 1000 || z > 1000 || w > 1000) {
        data = [
          //{name:'Exposure',value:a.toFixed(2),dot:a.toFixed(2)},
          {
            Year: "2020",
            Loss: parseFloat(x.toFixed(2)),
          },
          {
            Year: "2030",
            Loss: parseFloat(y.toFixed(2)),
          },
          {
            Year: "2050",
            Loss: parseFloat(z.toFixed(2)),
          },
          {
            Year: "2100",
            Loss: parseFloat(w.toFixed(2)),
          },
        ];
        config = {
          data,
          yAxis: {
            title: {
              text: "Loss in Million USD",
            },
            max: max + 0.2 * max,
          },
          xField: "Year",

          yField: "Loss",
          minColumnWidth: 20,
          maxColumnWidth: 20,
          legend: {
            layout: "vertical",
            position: "right",
          },
          label: {
            // 可手动配置 label 数据标签位置
            position: "top",
            // 'top', 'bottom', 'middle',
            // 配置样式
            style: {
              fill: "#000000",
              opacity: 0.6,
            },
          },
          xAxis: {
            label: {
              autoHide: true,
              autoRotate: false,
            },
          },
          colorField: "Year",
          seriesField: "Year",

          color: ["#f47a7b", "#8CB0F8", "#002060", "#256EFF"],
        };
      } else {
        data = [
          {
            Year: "2020",
            Loss: parseFloat(x.toFixed(2)),
          },
          {
            Year: "2030",
            Loss: parseFloat(y.toFixed(2)),
          },
          {
            Year: "2050",
            Loss: parseFloat(z.toFixed(2)),
          },
          {
            Year: "2100",
            Loss: parseFloat(w.toFixed(2)),
          },
        ];
        config = {
          data,
          yAxis: {
            title: {
              text: "Loss in Million USD",
            },
            max: max + 0.2 * max,
          },
          xField: "Year",

          yField: "Loss",
          minColumnWidth: 20,
          maxColumnWidth: 20,
          legend: {
            layout: "vertical",
            position: "right",
          },
          label: {
            // 可手动配置 label 数据标签位置
            position: "top",
            // 'top', 'bottom', 'middle',
            // 配置样式
            style: {
              fill: "#000000",
              opacity: 0.6,
            },
          },
          xAxis: {
            label: {
              autoHide: true,
              autoRotate: false,
            },
          },
          colorField: "Year",
          seriesField: "Year",

          color: ["#f47a7b", "#8CB0F8", "#002060", "#256EFF"],
        };
      }
    }

    return (
      <div>
        <Row style={{ marginLeft: "-15px", marginRight: "-15px" }}>
          {config.data && config.data.length > 0 ? (
            <Col md={12} xs={12} sm={12} className="graph">
              {this.state.analysis == "ssp" ? (
                <Column {...config} />
              ) : (
                <Column {...config} />
              )}
            </Col>
          ) : null}
        </Row>
        <Button
          style={{
            color: "#002060",
            backgroundColor: "white",
            borderColor: "#002060",
            border: "1px solid",
          }}
          variant="info"
          onClick={this.handleKeyFindings}
        >
          Key Findings
        </Button>
        <Modal
          open={this.state.key_finding}
          onClose={this.handleClose}
          closeIcon
        >
          <Modal.Header style={{ backgroundColor: "white" }}>
            Key Findings
          </Modal.Header>
          <Modal.Content>
            {this.props.key_find ? (
              <p className="key_finding">
                {this.state.analysis == "rcp" ? (
                  <span>
                    This indicates the Loss projection for 2030 and 2050
                    relative to the historical baseline of 2020 under the
                    Business-as-Usual case. For{" "}
                    {this.state.analysis.toUpperCase()}{" "}
                    <span className="key_find">{this.props.rcp} </span>, Climate
                    Value at Risk (CVaR) increases{" "}
                    <span className="key_find">
                      {" "}
                      {this.props.key_find.increased_by_2030}{" "}
                    </span>{" "}
                    times by 2030 and{" "}
                    <span className="key_find">
                      {this.props.key_find.increased_by_2050}{" "}
                    </span>{" "}
                    times by 2050 compared to current losses (2020).
                  </span>
                ) : (
                  <span>
                    This indicates the Loss projection for 2030 , 2050 and 2100
                    relative to the historical baseline of 2020 under the
                    Business-as-Usual case. For{" "}
                    {this.state.analysis.toUpperCase()}{" "}
                    <span className="key_find">{this.props.ssp} </span>, Climate
                    Value at Risk (CVaR) increases{" "}
                    <span className="key_find">
                      {" "}
                      {this.props.key_find.increased_by_2030}{" "}
                    </span>{" "}
                    times by 2030 and{" "}
                    <span className="key_find">
                      {this.props.key_find.increased_by_2050}{" "}
                    </span>{" "}
                    times by 2050 and{" "}
                    <span className="key_find">
                      {this.props.key_find.increased_by_2100} times by 2100
                    </span>
                    compared to current losses (2020).
                  </span>
                )}
              </p>
            ) : null}
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}
