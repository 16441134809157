import React,{Component} from 'react';
import { ReCaptcha } from 'react-recaptcha-google'; 
import {Form,Button,Input,TextArea,Grid,Container,Message,Image,Header} from 'semantic-ui-react';
import  {Redirect } from 'react-router-dom';
import signup from '../assets/signup.png';
import logo from '../assets/logo1.png';
import {connect} from 'react-redux';
import {auth} from '../actions';
import Zoom from 'react-reveal/Zoom';
import PropTypes from 'prop-types';
import Spinner from './spinner';
import './register.css';



function ValidationMessage(props) {
  if (!props.valid) {
    return(
      <div className='error-msg'>{props.message}</div>
    )
  }
  return null;
}





class Register extends Component{

	componentDidMount(){
	if(this.captchaDemo){
		this.captchaDemo.reset();
		
	}
}
onLoadRecaptcha(){
	if(this.captchaDemo){
		this.captchaDemo.reset();
		
	}
}


	state={
		first_name:'',
		last_name:'',
		username:'',
		email:'',
		password:'',
		confirm_password:'',
		company_name:'',
		passwordConfirmValid:false,
		formValid:false,
		errorMsg:{},
		loading:false
	};

	validateForm=()=>{
		const {passwordConfirmValid} = this.state
		this.setState({
			formValid:passwordConfirmValid
		})
	}

    updatePasswordConfirm = (confirm_password) => {
   	
    this.setState({confirm_password}, this.validatePasswordConfirm)
    }

    validatePasswordConfirm = () => {
    const {confirm_password, password} = this.state;
    let passwordConfirmValid = true;
    let errorMsg = {...this.state.errorMsg}

    if (password !== confirm_password) {
      passwordConfirmValid = false;
      errorMsg.confirm_password = 'Passwords do not match'
      
    }

    this.setState({passwordConfirmValid, errorMsg}, this.validateForm);
  }
	onSubmit = e =>{
		e.preventDefault();
		this.setState({loading:true},()=>{
		this.props.register(this.state.first_name,this.state.last_name,this.state.username,this.state.email,this.state.password,this.state.confirm_password,this.state.company_name)});
	}
	
	render(){
			if(auth.isUserAuthenticated()){
					return <Redirect to="/location"/>
				}
			console.log(this.props.errors)	
		return(
		<Grid style={{ height:'100vh',backgroundColor:'#ecf0f3'}} verticalAlign='middle' centered>	
		<Grid.Row style={{ padding:'5em',backgroundColor:'#ecf0f3',boxShadow:'10px 10px 10px #d1d9e6,-10px -10px 10px #f9f9f9',margin:'auto',marginLeft:'15%',marginRight:'15%'}}>	

		<div className="switch">
		
			<Image src={logo} size='small' centered/>
			
			<div class="switch__circle"></div>
            <div class="switch__circle switch__circle--t"></div>
		
		</div>
		<Grid.Column computer={4} style={{backgroundColor:'#ecf0f3'}}></Grid.Column>
		 <Grid.Column computer={8} tablet={10} mobile={16} style={{backgroundColor:'#ecf0f3'}}>
		 	
		 	
		 	<h2 style={{fontSize:'34px',textAlign:'center'}}>Create Account</h2>
		 	{ (this.props.errors.length>0) ?  <div className="row" style={{textAlign:"center"}}>
                  <span style={{color:'red'}}>{this.props.errors[0].message}</span>
                </div> : null}
		 	<br/>
			<Form>
				<Form.Group widths='equal'>
					<Form.Field 

					 id="form-input-control-first-name"
					 control={Input}
					 
					 placeholder='First Name'
					 value={this.state.first_name}
					 onChange={e=>this.setState({first_name:e.target.value})}

					 />
					 <span class="enter"></span>

					<Form.Field 
					 id="form-input-control-last-name"
					 control={Input}
					 
					 placeholder='Last Name'
					 value={this.state.last_name}
					 onChange={e=>this.setState({last_name:e.target.value})}
					 />
				</Form.Group>
				<Form.Field 
					 id="form-input-control-username"
					 control={Input}
					 
					 placeholder='Username'
					 value={this.state.username}
					 onChange={e=>this.setState({username:e.target.value})}
					 />
				<Form.Field 
					 id="form-input-control-email"
					 control={Input}
					 
					 placeholder='Email'
					 value={this.state.email}
					 onChange={e=>this.setState({email:e.target.value})}
					 
					 />
				< ValidationMessage valid={this.state.passwordConfirmValid} message={this.state.errorMsg.confirm_password} />
				<Form.Group widths='equal'>
				<Form.Field 
					 id="form-input-control-password"
					 control={Input}
					 
					 type="password"
					 placeholder='Password'
					 value={this.state.password}
					 onChange={e=>this.setState({password:e.target.value})}
					 />
				<Form.Field 
					 id="form-input-control-confirm-password"
					 control={Input}
					 
					 type="password"
					 placeholder='Confirm Password'
					 value={this.state.confirm_password}
					 onChange={e=>this.updatePasswordConfirm(e.target.value)}
					 />
				
				</Form.Group>
				<Form.Field 
					 id="form-input-control-company"
					 control={Input}
					 
					 placeholder='Company Name'
					 value={this.state.company_name}
					 onChange={(e)=>this.setState({company_name:e.target.value})}
					 />
		
        	{(this.state.loading && this.props.errors.length==0)?<Button style={{backgroundColor:'#002060',marginLeft:'40%'}}><Spinner/></Button>:
				<Button style={{backgroundColor:'#002060',fontSize:'16px',color:'white',marginLeft:'40%',borderRadius:'25px',boxShadow:'8px 8px 16px #d1d9e6, -8px -8px 16px #f9f9f9'}} disabled={!this.state.formValid} onClick={this.onSubmit} primary>Register</Button>
        	}
			</Form>

			<br/>
				Already have an Account ? <a href="/login">Login</a>
			
		</Grid.Column>
		
		</Grid.Row>
		</Grid>
			)
	}
}

const mapStateToProps = state => {
  let errors = [];
  if (state.auth.errors) {
    errors = Object.keys(state.auth.errors).map(field => {
      return {field, message: state.auth.errors[field]};
    });
  }
  return {
    errors,
    isAuthenticated: state.auth.isAuthenticated
  };
}

const mapDispatchToProps = dispatch => {
  return {
    register: (first_name,last_name,username, email,password ,confirm_password,company_name) => dispatch(auth.register(first_name,last_name,username,email, password ,confirm_password,company_name)),
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(Register);