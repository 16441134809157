import React, { Component } from "react";
import { connect } from "react-redux";
import { project, auth } from "../actions";
import TransitionTable from "./transitiontable";
import { Row, Col, Container } from "react-bootstrap";
import { Table } from "antd";

const columns = [
  {
    title: "Company Name",
    key: "company_name",
    dataIndex: "company_name",
  },
  /*{
    title: "Projected Scope 1 and 2 GHG Emission",
    dataIndex: "projected_scope_1_and_2_GHG_emission",
    key: "projected_scope_1_and_2_GHG_emission",
  },*/

  {
    title: "Weighted Average Carbon Intensity",
    dataIndex: "weighted_average_carbon_intensity",
    key: "weighted_average_carbon_intensity",
  },
  {
    title: "Total Carbon Emission",
    dataIndex: "total_carbon_emission",
    key: "total_carbon_emission",
  },
];

class TransitionAnalysis extends Component {
  state = {};
  componentDidMount() {
    let formdata = new FormData();
    formdata.append(
      "portfolio_name",
      this.props.location.state.carbon_project_name
    );
    this.props.getCarbonProject(formdata);
  }

  render() {
    return (
      <Container fluid>
        <Row>
          <Col lg={10} className="transition">
            {this.props.carbon_project.table_data ? (
              <>
                <h2>Output Data</h2>
                <p>
                  Carbon Intensity:{this.props.carbon_project.carbon_intensity}
                </p>
                <br />
                <p>
                  Carbon FootPrints:{this.props.carbon_project.carbon_footprint}
                </p>
                <br />
                <p>
                  Total Carbon Emission:
                  {this.props.carbon_project.total_carbon_emission_portfolio}
                </p>
                <br />
                <Table
                  columns={columns}
                  dataSource={this.props.carbon_project.table_data}
                />
              </>
            ) : null}
          </Col>
        </Row>
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    errors: state.locus.errors,
    carbon_project: state.project.carbon_project,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCarbonProject: (formdata) => {
      dispatch(project.getCarbonProject(formdata));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransitionAnalysis);
