import React, { useState, useEffect } from "react";
import { geoMercator, geoPath } from "d3-geo";
import { feature } from "topojson-client";

const HomeMap = (props) => {
  console.log("Home Map", props.variable, props.year, props.rcp);
  const projection = geoMercator()
    .scale(160)
    .translate([800 / 2, 450 / 2]);

  // State to store geography data
  const [geographies, setGeographies] = useState([]);
  // State to store hovered country
  const [currentValue, setCurrentValue] = useState(null);
  // State to store hovered index
  const [hoveredCountryIndex, setHoveredCountryIndex] = useState(null);

  useEffect(() => {
    // fetch("https://cdn.jsdelivr.net/npm/world-atlas@2/countries-50m.json")
    fetch(
      `https://intensel-backend-data.s3.us-east-1.amazonaws.com/public/geodataset/${props.variable}/${props.variable}_${props.rcp}_${props.year}.geojson`
    ).then((response) => {
      if (response.status !== 200) {
        console.log(`There was a problem: ${response.status}`);
        return;
      }
      response.json().then((worlddata) => {
        console.log(worlddata.objects.data);

        // console.log(worlddata.objects.try.geometries);
        // extract the features from the topojson
        //setGeographies((feature(worlddata, worlddata.objects.data) as GeoJson Properties)!.features);
        console.log(feature(worlddata, worlddata.objects.data).features);
        setGeographies(feature(worlddata, worlddata.objects.data).features);
      });
    });
  }, [props.variable, props.rcp, props.year]);
  const handleCountryClick = (countryIndex) => {
    console.log("Clicked on country: ", geographies[countryIndex]);
  };

  // get color depending on value 0-7
  const getColor = (value) => {
    switch (true) {
      case value >= 0 && value < 1:
        return "#ffffcc";
      case value >= 1 && value < 2:
        return "#c7e9b4";
      case value >= 2 && value < 3:
        return "#7fcdbb";
      case value >= 3 && value < 4:
        return "#41b6c4";
      case value >= 4 && value < 5:
        return "#1d91c0";
      case value >= 5 && value < 6:
        return "#225ea8";
      case value >= 6 && value < 7:
        return "#253494";
      case value >= 7:
        return "#081d58";
      default:
        return "#ffffcc";
    }
  };

  return (
    <div className="text-center">
      <svg
        className="h-auto max-w-6xl w-auto mx-auto mt-5 rounded-xl shadow-md"
        viewBox="0 0 850 450"
      >
        <g className="countries">
          {
            // draw each country
            geographies.map((d, i) => (
              <path
                key={`path-${i}`}
                d={geoPath().projection(projection)(d)}
                className="country"
                // Color each country based on the data
                // fill={`rgba(38,50,56,${1 / geographies.length * i})`}
                fill={
                  i === hoveredCountryIndex
                    ? "#F53F2B"
                    : getColor(d.properties.non_zero_mean)
                }
                // Show a stroke on hover
                stroke={i === hoveredCountryIndex ? "#FFFFFF" : "#000000"}
                // Change the stroke width on hover
                strokeWidth={i === hoveredCountryIndex ? 1 : 0.1}
                onClick={() => handleCountryClick(i)}
                onMouseEnter={() => {
                  const { name, non_zero_mean } = d.properties;
                  setCurrentValue({
                    name: name,
                    value: non_zero_mean,
                  });
                  setHoveredCountryIndex(i);
                }}
                onMouseLeave={() => {
                  setCurrentValue(null);
                  setHoveredCountryIndex(null);
                }}
              >
                {/* Tooltip */}
                <title>{d.properties.non_zero_mean}</title>
              </path>
            ))
          }
        </g>
      </svg>
      {/* Floating Info Tab */}
      <div className="absolute bottom-2 right-3">
        <div className="bg-white rounded-lg shadow-lg p-4">
          <div className="flex flex-row items-center justify-center">
            <div className="flex flex-col">
              {currentValue ? (
                <>
                  <div className="text-gray-600 font-medium text-sm">
                    Country
                  </div>
                  <div className="text-gray-900 font-bold text-lg">
                    {currentValue.name} : {currentValue.value}
                  </div>
                </>
              ) : (
                <div className="text-gray-900 font-bold text-lg">
                  Hover over a country
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeMap;
