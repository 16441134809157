import React, { Component } from "react";
import {
  Form,
  Button,
  Input,
  TextArea,
  Grid,
  Container,
  Message,
  Image,
  Header,
  Menu,
  Select,
  Dropdown,
  Icon,
  Table,
  Modal,
  Popup,
  StepTitle,
  Progress,
} from "semantic-ui-react";
import logo from "../assets/logo.png";
import "./project.css";
import { connect } from "react-redux";
import { locus, auth, project } from "../actions";
import { Row, Col, Card, Tabs, Tab } from "react-bootstrap";
import Spinner from "./loader";
import CsvDownload from "react-json-to-csv";
import dropbox from "../assets/dropbox.png";
import team1 from "../assets/team_1.png";
import team2 from "../assets/team_2.png";

import var_eg from "../assets/var_eg.jpg";
//import Report from "./report";
import { Popconfirm } from "antd";
import "antd/dist/antd.css";
import pptxgen from "pptxgenjs";
import { runppt } from "./ppt";
import { changeTitle } from "../redux/actions";
import { Redirect, withRouter } from "react-router-dom";
import HorizontalTimeline from "react-horizontal-timeline";

let options = [];

const VALUES = [
  "2021-1-02",
  "2021-1-01",
  "2021-2-01",
  "2021-3-01",
  "2021-4-01",
  "2021-5-01",
  "2021-6-01",
  "2021-7-01",
  "2021-8-01",
  "2021-9-01",
  "2021-10-01",
  "2021-11-01",
  "2021-12-01",
];

class RealEstateProject extends Component {
  state = {
    name: "",
    description: "",
    status: "",
    assets: [],
    loading: false,
    activeItemName: "",
    modalOpen: false,
    variables: [],
    others: "",
    year: "",
    rcp: "",
    modalloading: false,
    lossmodalOpen: false,
    reportmodal: false,
    portfolios_realestate: "",
    downloadmodalOpen: false,
    new_project: "",
    portfolio_loss: "",
    modalEdit: false,
    loading: false,
    dropbox: true,
    value: 0,
    previous: 0,
    share: false,
  };

  componentDidMount() {
    this.props.getProjectList("real_estate");
  }

  onSubmit = (e) => {
    e.preventDefault();
    let formdata = new FormData();
    formdata.append("oldname", this.state.activeItemName);
    formdata.append("status", this.state.status);
    formdata.append("description", this.state.description);
    formdata.append("name", this.state.new_project);
    this.setState({ loading: true }, () => {
      this.props.addProject(formdata);
    });
  };
  handleChange = (value, key) => {
    this.setState({ [key]: value }, () =>
      console.log("othres", this.state.others)
    );
  };

  handlePptReport(portfolio) {
    let formdata = new FormData();
    formdata.append("project_name", portfolio);

    this.setState({ loading: true }, () => {
      this.props.getPptReport(formdata);
    });
  }
  handleShare = () => {
    this.setState({ share: true });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let formdata = new FormData();
    formdata.append("portfolio", this.state.activeItemName.slice(10));
    formdata.append("variables", JSON.stringify(this.state.variables));
    formdata.append("others", JSON.stringify(this.state.others));
    formdata.append("year", JSON.stringify(this.state.year));
    formdata.append("rcp", JSON.stringify(this.state.rcp));
    formdata.append(
      "portfolio_loss",
      JSON.stringify(this.state.portfolio_loss)
    );

    this.setState({ modalloading: true }, () => {
      this.props.getCSV(formdata);
    });
  };
  handleView = () => {
    console.log("view ", this.props.csv.success);
    this.props.history.push({
      pathname: "/pivottable",
      state: { data: this.props.csv.success },
    });
  };
  handleLossSubmit = (e) => {
    e.preventDefault();
    let formdata = new FormData();
    formdata.append("portfolio", this.state.activeItemName.slice(10));
    formdata.append("year", this.state.year);
    this.setState({ modalloading: true }, () => {
      this.props.getLoss(formdata);
    });
  };

  handleSummarySubmit = (e) => {
    e.preventDefault();
    let formdata = new FormData();
    formdata.append("portfolio", this.state.activeItemName.slice(10));
    this.setState({ modalloading: true }, () => {
      this.props.getSummary(formdata);
    });
  };

  handleLogout = () => {
    this.props.logout();
  };
  handleOpen = (portfolio, variable) =>
    this.setState(
      { modalOpen: true, activeItemName: portfolio, activeVariable: variable },
      () => this.handleVariable()
    );

  handleEdit = (portfolio) => {
    this.setState({ modalEdit: true, activeItemName: portfolio });
  };

  handleLossModalOpen = (portfolio) =>
    this.setState({ lossmodalOpen: true, activeItemName: portfolio }, () =>
      console.log(this.state.activeItemName)
    );

  handleReport = (portfolio) =>
    this.setState({ reportmodal: true, activeItemName: portfolio }, () =>
      console.log(this.state.activeItemName)
    );

  handleLossModalClose = () => {
    this.setState({ lossmodalOpen: false }, () =>
      window.location.reload(false)
    );
  };
  handleSummaryModalOpen = (portfolio) =>
    this.setState({ summarymodalOpen: true, activeItemName: portfolio });

  handleSummaryModalClose = () => {
    this.setState({ summarymodalOpen: false }, () =>
      window.location.reload(false)
    );
  };

  handleDropbox = (base, map) => {
    if (this.props.link.url == undefined) {
      console.log("function called", map);
      let formdata = new FormData();
      formdata.append("ppt", base);
      formdata.append("project_name", map);
      this.props.Dropbox(formdata);
    }
  };

  handleClose = () =>
    this.setState(
      {
        modalOpen: false,
        lossmodalOpen: false,
        reportmodal: false,
        modalEdit: false,
        dropbox: false,
      },
      () => window.location.reload(false)
    );

  handleModify = (portfolio) => {
    this.props.history.push({
      pathname: "/realestate-dashboard",
      state: { project_name: portfolio.project_name },
    });
  };

  handleDelete = (portfolio) => {
    let formdata = new FormData();
    formdata.append("portfolio_name", portfolio.project_name);
    this.props.deleteCarbonProject(formdata);
  };
  handleDownload = (portfolio) => {
    let formdata = new FormData();
    formdata.append("portfolio_name", portfolio);
    this.props.downloadCarbonProject(formdata);
  };
  handleDownloadModalOpen = (portfolio) =>
    this.setState({
      downloadmodalOpen: true,
      activeItemName: portfolio.project_name,
    });

  handleDownloadModalClose = () => {
    this.setState({ downloadmodalOpen: false }, () =>
      window.location.reload(false)
    );
  };

  render() {
    const {
      value,
      others,
      variables,
      rcp,
      year,
      status,
      portfolio_loss,
    } = this.state;
    let user_id = localStorage.getItem("user_id");

    if (this.state.loading) {
      return <div id="loading"></div>;
    }
    if (this.props.list && this.props.list.data.length > 0) {
      let assets = this.props.list.data;
      this.state.portfolios_realestate = assets.reverse();
    }
    console.log("portfolio real estate", this.state.portfolios_realestate);

    return (
      <>
        <div className="project_card" style={{ padding: "1%" }}>
          <Row>
            {this.props.list.data && this.props.list.data.length > 0 ? (
              <Col lg={12} className="card_eg_top">
                <Row>
                  {" "}
                  <Col className="card_info">
                    <p className="card_info2">
                      <span className="card_info2a">
                        All Projects For &nbsp;{" "}
                      </span>

                      {this.props.list.username}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col className="card_info">
                    <p className="card_info3">
                      <span className="card_info3a">
                        Total Number of Projects &nbsp;
                      </span>

                      {this.props.list.data.length}
                    </p>
                  </Col>
                </Row>
              </Col>
            ) : null}
          </Row>
          <Row>
            <Col lg={12}>
              <Row>
                {this.state.portfolios_realestate &&
                this.state.portfolios_realestate.length > 0
                  ? this.state.portfolios_realestate
                      .reverse()
                      .map((portfolio, index) => (
                        <Col lg={4}>
                          <Card className="card_eg" key={index}>
                            <Card.Body>
                              <Card.Title style={{ fontWeight: "bold" }}>
                                {portfolio.project_name}{" "}
                                {portfolio.created_at ? (
                                  <span
                                    style={{
                                      float: "right",
                                      fontSize: "12px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {portfolio.created_at.slice(0, 10)}
                                  </span>
                                ) : null}
                              </Card.Title>

                              <p></p>

                              <Button
                                style={{
                                  backgroundColor: "#ffeecc",
                                  color: "#ffab00",
                                  border: "1px solid #FDB827",
                                  borderRadius: "25px",
                                }}
                                variant="primary"
                                onClick={() => this.handleModify(portfolio)}
                              >
                                Analyse
                              </Button>

                              <br />
                            </Card.Body>
                          </Card>
                        </Col>
                      ))
                  : null}
              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.project.errors,

    list: state.project.list,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getProjectList: (type) => {
      dispatch(project.getProjectList(type));
    },
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RealEstateProject)
);
