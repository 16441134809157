import React, { useRef, useEffect, useState } from "react";
import ReactMapGL from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Marker, Popup, FlyToInterpolator, Layer, Source } from "react-map-gl";
import data from "../constants/menu";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import mapboxgl from "mapbox-gl"; // eslint-disable-next-line import/no-webpack-loader-syntax

mapboxgl.accessToken =
  "pk.eyJ1IjoiYmFjay1vZmZpY2UtbWFuYWdlbWVudCIsImEiOiJjbGZxcjcyZ2wwMTk1M3hraTBmYndycnhnIn0.nCdXVyBW6n3Db_vdDcE3gA";

let mapcards = [];

export default function RealEstateMap(props) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(114.1838);
  const [lat, setLat] = useState(22.2797);
  const [zoom, setZoom] = useState(15.5);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/light-v10",
      center: [lng, lat],
      zoom: zoom,
    });
  });

  useEffect(() => {
    if (!map.current) return;
    map.current.on("load", () => {
      // Insert the layer beneath any symbol layer.
      const layers = map.current.getStyle().layers;
      const labelLayerId = layers.find(
        (layer) => layer.type === "symbol" && layer.layout["text-field"]
      ).id;

      // The 'building' layer in the Mapbox Streets
      // vector tileset contains building height data
      // from OpenStreetMap.
      map.current.addLayer(
        {
          id: "add-3d-buildings",
          source: "composite",
          "source-layer": "building",
          filter: ["==", "extrude", "true"],
          type: "fill-extrusion",
          minzoom: 15,
          paint: {
            "fill-extrusion-color": "#aaa",

            // Use an 'interpolate' expression to
            // add a smooth transition effect to
            // the buildings as the user zooms in.
            "fill-extrusion-height": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "height"],
            ],
            "fill-extrusion-base": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "min_height"],
            ],
            "fill-extrusion-opacity": 0.6,
          },
        },
        labelLayerId
      );
    }); // wait for map to initialize
  });

  useEffect(() => {
    if (!map.current) return;

    map.current.on("click", (e) => {
      const marker = new mapboxgl.Marker()
        .setLngLat([e.lngLat.lng, e.lngLat.lat])

        .addTo(map.current);

      //console.log(geocoder.query("Henessy Road"));
      // console.log(geocoder.query([e.lngLat.lng, e.lngLat.lat].toString()));
      fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${e.lngLat.lng},${e.lngLat.lat}.json?access_token=pk.eyJ1IjoiYmFjay1vZmZpY2UtbWFuYWdlbWVudCIsImEiOiJjbGZxcjcyZ2wwMTk1M3hraTBmYndycnhnIn0.nCdXVyBW6n3Db_vdDcE3gA`
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          console.log(data);
          let address = data.features[0].place_name.split(",")[0];

          props.location(address, e.lngLat.lat, e.lngLat.lng);
        });
    });
  }, [props.location]);

  return (
    <div>
      <div ref={mapContainer} className="realestate-map-container" />
    </div>
  );
}
