import React, { PureComponent } from "react";
import { Column } from "@ant-design/charts";

import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ScatterChart,
  Scatter,
  Cell,
  ResponsiveContainer,
} from "recharts";
import { Row, Col } from "react-bootstrap";
import { Button } from "semantic-ui-react";
import { ResponsiveBar } from "@nivo/bar";
import {
  VictoryBar,
  VictoryChart,
  VictoryTheme,
  VictoryLine,
  VictoryAxis,
} from "victory";
import { Modal } from "semantic-ui-react";

let data = [];
let config = {};

export default class IndexRCPDonut extends PureComponent {
  state = {
    activeIndex: 0,
    piedata: "",
    key_finding: false,
  };

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index,
      piedata: "",
    });
  };
  handleKeyFindings = () => {
    this.setState({
      key_finding: true,
    });
  };
  handleClose = () => {
    this.setState({
      key_finding: false,
    });
  };

  render() {
    let bottom =
      this.props.analysis == "rcp"
        ? "Representative concentration pathways (RCPs)"
        : "Shared Socioeconomic pathways (SSPs)";
    this.state.piedata = this.props.data;
    let x = 0;
    let y = 0;
    let z = 0;
    let a = 0;
    let b = 0;
    let c = 0;

    let max = 0;
    console.log("company rcpdonut", this.state.piedata);
    if (this.state.piedata) {
      x = this.state.piedata["company"]["scenario_loss_for_year"]["26"];
      y = this.state.piedata["company"]["scenario_loss_for_year"][`45`];
      z = this.state.piedata["company"]["scenario_loss_for_year"][`85`];
      a = this.state.piedata["index"]["scenario_loss_for_year"]["26"];
      b = this.state.piedata["index"]["scenario_loss_for_year"][`45`];
      c = this.state.piedata["index"]["scenario_loss_for_year"][`85`];
      max = this.state.piedata["index"]["scenario_max"];
    }
    if (x > 1000 || y > 1000 || z > 1000) {
      data = [
        {
          RCP: "2.6",
          Loss: parseFloat(x.toFixed(2)),
        },
        {
          RCP: "4.5",
          Loss: parseFloat(y.toFixed(2)),
        },
        {
          RCP: "8.5",
          Loss: parseFloat(z.toFixed(2)),
        },
      ];
      config = {
        data,
        xField: "RCP",
        yAxis: {
          title: {
            text: "Loss in Million USD",
          },
          max: max + 0.2 * max,
        },
        yField: "Loss",
        minColumnWidth: 20,
        maxColumnWidth: 20,
        legend: {
          layout: "vertical",
          position: "right",
        },
        label: {
          position: "top",

          style: {
            fill: "#000000",
            opacity: 0.6,
          },
        },
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: false,
          },
        },
        colorField: "RCP",
        seriesField: "RCP",

        color: ["#2077b4", "#f67e2a", "#4da02d"],
      };
    } else {
      data = [
        { name: "Index", RCP: "2.6", Loss: parseFloat(a.toFixed(2)) },

        {
          name: "Index",
          RCP: "4.5",
          Loss: parseFloat(b.toFixed(2)),
        },

        {
          name: "Index",
          RCP: "8.5",
          Loss: parseFloat(c.toFixed(2)),
        },
      ];
      config = {
        data,
        isGroup: true,
        seriesField: "RCP",
        xField: "name",
        yAxis: {
          title: {
            text: "Loss in Million USD",
          },
          max: max + 0.2 * max,
        },
        yField: "Loss",
        minColumnWidth: 20,
        maxColumnWidth: 20,
        legend: {
          layout: "vertical",
          position: "right",
        },
        label: {
          position: "top",

          style: {
            fill: "#000000",
            opacity: 0.6,
          },
        },
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: false,
          },
        },
        colorField: "RCP",
        seriesField: "RCP",

        color: ["#2077b4", "#f67e2a", "#4da02d"],
      };
    }

    return (
      <div>
        <Row style={{ marginLeft: "-15px", marginRight: "-15px" }}>
          <Col
            md={12}
            xs={12}
            sm={12}
            className="graph"
            style={{ height: "35vh" }}
          >
            {config.data && config.data.length > 0 ? (
              <Col
                md={12}
                xs={12}
                sm={12}
                className="graph"
                style={{ height: "35vh" }}
              >
                {this.state.analysis == "ssp" ? (
                  <Column {...config} />
                ) : (
                  <Column {...config} />
                )}
              </Col>
            ) : null}
          </Col>
        </Row>
      </div>
    );
  }
}
