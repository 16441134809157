import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "./Map.css";
let cord = [];
let color = ["#F2F12D", "#EED322", "#E6B71E", "#DA9C20", "#CA8323", "#B86B25"];
mapboxgl.accessToken =
  "pk.eyJ1IjoiYmFjay1vZmZpY2UtbWFuYWdlbWVudCIsImEiOiJjbGZxcjcyZ2wwMTk1M3hraTBmYndycnhnIn0.nCdXVyBW6n3Db_vdDcE3gA";

/*class Map extends React.PureComponent 
    constructor(props) {
        super(props);
        this.state = {
            lng: 111.181713464683471,
            lat: 22.27932901447338,
            zoom: 3,
            line: this.props.data
        };
        this.mapContainer = React.createRef();
    }*/
const Map = (props) => {
  const mapContainer = useRef();
  const [lng, setLng] = useState(111.18);
  const [lat, setLat] = useState(22.27);
  const [zoom, setZoom] = useState(3);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/dark-v10",
      center: [lng, lat],
      zoom: zoom,
    });

    cord = [];
    for (let i = 0; i < props.data.length; i++) {
      cord.push({
        type: "Feature",
        properties: {
          color: color[i],
        },
        geometry: { type: "LineString", coordinates: props.data[i] },
      });
    }
    console.log("cordinates after", cord);

    map.on("load", function() {
      map.addSource(
        "lines",

        {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: cord,
          },
        }
      );
      map.addLayer({
        id: "lines",
        type: "line",
        source: "lines",
        paint: {
          "line-width": 3,
          // Use a get expression (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-get)
          // to set the line-color to a feature property value.
          "line-color": ["get", "color"],
        },
      });
      return () => map.remove();
    });
  }, [props.data]);

  /*  componentDidUpdate(prevProps, prevState) {
        console.log("data changes",this.props.data)
        if (prevProps.data !== this.props.data) {
            this.setState({ line: this.props.data }, () => console.log("lines", this.state.line))
        }
    }*/

  return (
    <div>
      <div
        ref={mapContainer}
        className="map-container"
        style={{ padding: "1.2em" }}
      />
    </div>
  );
};

export default Map;
