import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import { Icon } from "semantic-ui-react";
import { Progress } from "antd";

const LossPercentComponent = (props) => {
  console.log("Losses 2020", props.newdata);
  let severity = props.data["mitigation"]["severity"];

  return (
    <Tabs defaultActiveKey="total">
      <Tab title="Per Hazard" eventKey="General">
        <br />

        {props.data["general"]["Rainfall Flood Loss"] ? (
          <div className="Progress">
            <p style={{ fontSize: "14px" }}>
              <span className={"dark-mode-primary-color"}>
                Rainfall Flood Loss{" "}
              </span>
              <b style={{ float: "right" }}>
                {props.data["general"]["Rainfall Flood Loss"][2] ? (
                  <span className={"dark-mode-primary-color"}>
                    {props.data["general"]["Rainfall Flood Loss"][2].toFixed(2)}{" "}
                    %
                  </span>
                ) : null}
              </b>
            </p>
            <Progress
              percent={props.newdata["general"]["Rainfall Flood Loss"][2]}
              className="lessloss"
            />
            <Progress
              percent={props.data["general"]["Rainfall Flood Loss"][2]}
            />
          </div>
        ) : null}

        <div className="Progress">
          <p style={{ fontSize: "14px" }}>
            <span className={"dark-mode-primary-color"}>River Flood Loss </span>
            <b style={{ float: "right" }}>
              {props.data["general"]["River Flood Loss"][2] ? (
                <span className={"dark-mode-primary-color"}>
                  {props.data["general"]["River Flood Loss"][2].toFixed(2)} %
                </span>
              ) : null}
            </b>
          </p>
          <Progress
            percent={props.newdata["general"]["River Flood Loss"][2]}
            className="lessloss"
          />
          <Progress percent={props.data["general"]["River Flood Loss"][2]} />
        </div>

        <div className="Progress">
          <p style={{ fontSize: "14px" }}>
            <span className={"dark-mode-primary-color"}>Storm Surge Loss </span>
            <b style={{ float: "right" }}>
              {props.data["general"]["Storm Surge Loss"][2] ? (
                <span className={"dark-mode-primary-color"}>
                  {props.data["general"]["Storm Surge Loss"][2].toFixed(2)} %
                </span>
              ) : null}
            </b>
          </p>
          <Progress
            percent={props.newdata["general"]["Storm Surge Loss"][2]}
            className="lessloss"
          />
          <Progress percent={props.data["general"]["Storm Surge Loss"][2]} />
        </div>

        <div className="Progress">
          <p style={{ fontSize: "14px" }}>
            <span className={"dark-mode-primary-color"}>Typhoon Loss </span>
            <b style={{ float: "right" }}>
              {props.data["general"]["Typhoon Loss"][2] ? (
                <span className={"dark-mode-primary-color"}>
                  {props.data["general"]["Typhoon Loss"][2].toFixed(2)} %
                </span>
              ) : null}
            </b>
          </p>
          <Progress
            percent={props.newdata["general"]["Typhoon Loss"][2]}
            className="lessloss"
          />
          <Progress percent={props.data["general"]["Typhoon Loss"][2]} />
        </div>

        {props.data["general"]["Temprature/heat loss"] ? (
          <div className="Progress">
            <p style={{ fontSize: "14px" }}>
              <span className={"dark-mode-primary-color"}>
                Temperature/Heat Loss{" "}
              </span>
              <b style={{ float: "right" }}>
                {props.data["general"]["Temprature/heat loss"][2] ? (
                  <span className={"dark-mode-primary-color"}>
                    {props.data["general"]["Temprature/heat loss"][2].toFixed(
                      2
                    )}{" "}
                    %
                  </span>
                ) : null}
              </b>
            </p>
            <Progress
              percent={props.newdata["general"]["Temprature/heat loss"][2]}
              className="lessloss"
            />
            <Progress
              percent={props.data["general"]["Temprature/heat loss"][2]}
            />
          </div>
        ) : null}
      </Tab>
      <Tab title="Detailed" eventKey="All">
        <br />
        <div className="Progress">
          <p style={{ fontSize: "14px" }}>
            <span className={"dark-mode-primary-color"}>Total Loss </span>
            <b style={{ float: "right" }}>
              {props.data["total"]["Total Loss"][2] ? (
                <span className={"dark-mode-primary-color"}>
                  {props.data["total"]["Total Loss"][2].toFixed(2)} %
                </span>
              ) : null}
            </b>
          </p>
          <Progress
            percent={props.newdata["total"]["Total Loss"][2]}
            className="lessloss"
          />
          <Progress percent={props.data["total"]["Total Loss"][2]} />
        </div>
        <div className="Progress">
          {props.data["detailed"]["Asset Rainfall Flood Damage"] ? (
            <p style={{ fontSize: "14px" }}>
              <span className={"dark-mode-primary-color"}>
                Asset Rainfall Flood Damage{" "}
              </span>
              <b style={{ float: "right" }}>
                {props.data["detailed"]["Asset Rainfall Flood Damage"][2] ? (
                  <span className={"dark-mode-primary-color"}>
                    {props.data["detailed"][
                      "Asset Rainfall Flood Damage"
                    ][2].toFixed(2)}
                    %
                  </span>
                ) : null}
              </b>
            </p>
          ) : null}
          {props.newdata["detailed"]["Asset Rainfall Flood Damage"] ? (
            <Progress
              percent={
                props.newdata["detailed"]["Asset Rainfall Flood Damage"][2]
              }
              color="green"
              className="lessloss"
            />
          ) : null}

          {props.data["detailed"]["Asset Rainfall Flood Damage"] ? (
            <Progress
              percent={props.data["detailed"]["Asset Rainfall Flood Damage"][2]}
              color="green"
            />
          ) : null}
        </div>
        <div className="Progress">
          <p style={{ fontSize: "14px" }}>
            <span className={"dark-mode-primary-color"}>
              Asset River Flood Damage{" "}
            </span>
            <b style={{ float: "right" }}>
              {props.data["detailed"]["Asset River Flood Damage"][2] ? (
                <span className={"dark-mode-primary-color"}>
                  {props.data["detailed"][
                    "Asset River Flood Damage"
                  ][2].toFixed(2)}
                  %
                </span>
              ) : null}
            </b>
          </p>
          <Progress
            percent={props.newdata["detailed"]["Asset River Flood Damage"][2]}
            color="green"
            className="lessloss"
          />

          <Progress
            percent={props.data["detailed"]["Asset River Flood Damage"][2]}
            color="green"
          />
        </div>
        <div className="Progress">
          <p style={{ fontSize: "14px" }}>
            <span className={"dark-mode-primary-color"}>
              Asset Storm Surge Damage{" "}
            </span>
            <b style={{ float: "right" }}>
              {props.data["detailed"]["Asset Storm Surge Damage"][2] ? (
                <span className={"dark-mode-primary-color"}>
                  {props.data["detailed"][
                    "Asset Storm Surge Damage"
                  ][2].toFixed(2)}
                  %
                </span>
              ) : null}
            </b>
          </p>
          <Progress
            percent={props.newdata["detailed"]["Asset Storm Surge Damage"][2]}
            color="green"
            className="lessloss"
          />

          <Progress
            percent={props.data["detailed"]["Asset Storm Surge Damage"][2]}
            color="green"
          />
        </div>
        <div className="Progress">
          <p style={{ fontSize: "14px" }}>
            <span className={"dark-mode-primary-color"}>
              Asset Typhoon Damage{" "}
            </span>
            <b style={{ float: "right" }}>
              {props.data["detailed"]["Asset Typhoon Damage"][2] ? (
                <span className={"dark-mode-primary-color"}>
                  {props.data["detailed"]["Asset Typhoon Damage"][2].toFixed(2)}{" "}
                  %
                </span>
              ) : null}
            </b>
          </p>
          <Progress
            percent={props.newdata["detailed"]["Asset Typhoon Damage"][2]}
            color="green"
            className="lessloss"
          />

          <Progress
            percent={props.data["detailed"]["Asset Typhoon Damage"][2]}
            color="green"
          />
        </div>
        <div className="Progress">
          {props.data["detailed"]["Operational Rainfall Flood Loss"] ? (
            <p style={{ fontSize: "14px" }}>
              <span className={"dark-mode-primary-color"}>
                Operational Rainfall Flood Loss{" "}
              </span>
              <b style={{ float: "right" }}>
                {props.data["detailed"][
                  "Operational Rainfall Flood Loss"
                ][2] ? (
                  <span className={"dark-mode-primary-color"}>
                    {props.data["detailed"][
                      "Operational Rainfall Flood Loss"
                    ][2].toFixed(2)}
                    %
                  </span>
                ) : null}
              </b>
            </p>
          ) : null}
          {props.newdata["detailed"]["Operational Rainfall Flood Loss"] ? (
            <Progress
              percent={
                props.newdata["detailed"]["Operational Rainfall Flood Loss"][2]
              }
              color="green"
              className="lessloss"
            />
          ) : null}

          {props.data["detailed"]["Operational Rainfall Flood Loss"] ? (
            <Progress
              percent={
                props.data["detailed"]["Operational Rainfall Flood Loss"][2]
              }
              color="green"
            />
          ) : null}
        </div>
        <div className="Progress">
          <p style={{ fontSize: "14px" }}>
            <span className={"dark-mode-primary-color"}>
              Operational River Flood Loss{" "}
            </span>
            <b style={{ float: "right" }}>
              {props.data["detailed"]["Operational River Flood Loss"][2] ? (
                <span className={"dark-mode-primary-color"}>
                  {props.data["detailed"][
                    "Operational River Flood Loss"
                  ][2].toFixed(2)}{" "}
                  %
                </span>
              ) : null}
            </b>
          </p>
          <Progress
            percent={
              props.newdata["detailed"]["Operational River Flood Loss"][2]
            }
            color="green"
            className="lessloss"
          />

          <Progress
            percent={props.data["detailed"]["Operational River Flood Loss"][2]}
            color="green"
          />
        </div>
        <div className="Progress">
          <p style={{ fontSize: "14px" }}>
            <span className={"dark-mode-primary-color"}>
              Operational Storm Surge Loss{" "}
            </span>
            <b style={{ float: "right" }}>
              {props.data["detailed"]["Operational Storm Surge Loss"][2] ? (
                <span className={"dark-mode-primary-color"}>
                  {props.data["detailed"][
                    "Operational Storm Surge Loss"
                  ][2].toFixed(2)}{" "}
                  %
                </span>
              ) : null}
            </b>
          </p>
          <Progress
            percent={
              props.newdata["detailed"]["Operational Storm Surge Loss"][2]
            }
            color="green"
            className="lessloss"
          />

          <Progress
            percent={props.data["detailed"]["Operational Storm Surge Loss"][2]}
            color="green"
          />
        </div>
        <div className="Progress">
          <p style={{ fontSize: "14px" }}>
            <span className={"dark-mode-primary-color"}>
              Operational Typhoon Loss{" "}
            </span>
            <b style={{ float: "right" }}>
              {props.data["detailed"]["Operational Typhoon Loss"][2] ? (
                <span className={"dark-mode-primary-color"}>
                  {props.data["detailed"][
                    "Operational Typhoon Loss"
                  ][2].toFixed(2)}{" "}
                  %
                </span>
              ) : null}
            </b>
          </p>
          <Progress
            percent={props.newdata["detailed"]["Operational Typhoon Loss"][2]}
            color="green"
            className="lessloss"
          />

          <Progress
            percent={props.data["detailed"]["Operational Typhoon Loss"][2]}
            color="green"
          />
        </div>
        <div className="Progress">
          {props.data["detailed"]["Temprature/heat loss"] ? (
            <p style={{ fontSize: "14px" }}>
              <span className={"dark-mode-primary-color"}>
                Temperature/Heat Loss
              </span>
              <b style={{ float: "right" }}>
                {props.data["detailed"]["Temprature/heat loss"][2] ? (
                  <span className={"dark-mode-primary-color"}>
                    {props.data["detailed"]["Temprature/heat loss"][2].toFixed(
                      2
                    )}{" "}
                    %
                  </span>
                ) : null}
              </b>
            </p>
          ) : null}
          {props.newdata["detailed"]["Temprature/heat loss"] ? (
            <Progress
              percent={props.newdata["detailed"]["Temprature/heat loss"][2]}
              color="green"
              className="lessloss"
            />
          ) : null}

          {props.data["detailed"]["Temprature/heat loss"] ? (
            <Progress
              percent={props.data["detailed"]["Temprature/heat loss"][2]}
              color="green"
            />
          ) : null}
        </div>
      </Tab>
      {/*   <Tab title="Energy" eventKey="energy">
        <br />

        <p style={{ fontSize: "14px" }}>
          <span className={"dark-mode-primary-color"}>Total Energy Loss</span>
          <b style={{ float: "right" }}>
            {props.data.energy["total_loss"][0] < 1000 ? (
              <span className={"dark-mode-primary-color"}>
                ${props.data.energy["total_loss"][0].toFixed(2)} Million
              </span>
            ) : (
              <span className="dark-mode-theme-color-1">
                ${(props.data.energy["total_loss"][0] / 1000).toFixed(2)}{" "}
                Billion
              </span>
            )}
          </b>
        </p>
        <Progress
          percent={props.newdata.energy["total_loss"][2]}
          color="green"
          className="lessloss"
        />

        <Progress percent={props.data.energy["total_loss"][2]} color="green" />

        <p style={{ fontSize: "14px" }}>
          <span className={"dark-mode-primary-color"}>
            Solar Production Loss
          </span>
          <b style={{ float: "right" }}>
            {props.data.energy["solar_loss"][0] < 1000 ? (
              <span className={"dark-mode-primary-color"}>
                ${props.data.energy["solar_loss"][0].toFixed(2)} Million
              </span>
            ) : (
              <span className="dark-mode-theme-color-1">
                ${(props.data.energy["solar_loss"][0] / 1000).toFixed(2)}{" "}
                Billion
              </span>
            )}
          </b>
        </p>
        <Progress
          percent={props.newdata.energy["solar_loss"][2]}
          color="green"
          className="lessloss"
        />

        <Progress percent={props.data.energy["solar_loss"][2]} color="green" />

        <p style={{ fontSize: "14px" }}>
          <span className={"dark-mode-primary-color"}>
            Wind Production Loss
          </span>
          <b style={{ float: "right" }}>
            {props.data.energy["wind_loss"][0] < 1000 ? (
              <span className={"dark-mode-primary-color"}>
                ${props.data.energy["wind_loss"][0].toFixed(2)} Million
              </span>
            ) : (
              <span className="dark-mode-theme-color-1">
                ${(props.data.energy["wind_loss"][0] / 1000).toFixed(2)} Billion
              </span>
            )}
          </b>
        </p>
        <Progress
          percent={props.newdata.energy["wind_loss"][2]}
          color="green"
          className="lessloss"
        />

        <Progress percent={props.data.energy["wind_loss"][2]} color="green" />

        <p style={{ fontSize: "14px" }}>
          <span className={"dark-mode-primary-color"}>
            Hydro Production Loss
          </span>
          <b style={{ float: "right" }}>
            {props.data.energy["hydro_loss"][0] < 1000 ? (
              <span className={"dark-mode-primary-color"}>
                ${props.data.energy["hydro_loss"][0].toFixed(2)} Million
              </span>
            ) : (
              <span className="dark-mode-theme-color-1">
                ${(props.data.energy["hydro_loss"][0] / 1000).toFixed(2)}{" "}
                Billion
              </span>
            )}
          </b>
        </p>
        <Progress
          percent={props.newdata.energy["hydro_loss"][2]}
          color="green"
          className="lessloss"
        />

        <Progress percent={props.data.energy["hydro_loss"][2]} color="green" />
      </Tab>
      <Tab title="Agriculture" eventKey="agri">
        <br />
        <p style={{ fontSize: "14px" }}>
          <span className={"dark-mode-primary-color"}>
            Total Agriculture Loss
          </span>
          <b style={{ float: "right" }}>
            {props.data.agriculture["total_loss"][0] < 1000 ? (
              <span className={"dark-mode-primary-color"}>
                ${props.data.agriculture["total_loss"][0].toFixed(2)} Million
              </span>
            ) : (
              <span className="dark-mode-theme-color-1">
                ${(props.data.agriculture["total_loss"][0] / 1000).toFixed(2)}{" "}
                Billion
              </span>
            )}
          </b>
        </p>
        <Progress
          percent={props.newdata.agriculture["total_loss"][2]}
          color="green"
          className="lessloss"
        />

        <Progress
          percent={props.data.agriculture["total_loss"][2]}
          color="green"
        />

        <p style={{ fontSize: "14px" }}>
          <span className={"dark-mode-primary-color"}>
            Wheat Production Loss
          </span>
          <b style={{ float: "right" }}>
            {props.data.agriculture["wheat"][0] < 1000 ? (
              <span className={"dark-mode-primary-color"}>
                ${props.data.agriculture["wheat"][0].toFixed(2)} Million
              </span>
            ) : (
              <span className="dark-mode-theme-color-1">
                ${(props.data.agriculture["wheat"][0] / 1000).toFixed(2)}{" "}
                Billion
              </span>
            )}
          </b>
        </p>
        <Progress
          percent={props.newdata.agriculture["wheat"][2]}
          color="green"
          className="lessloss"
        />

        <Progress percent={props.data.agriculture["wheat"][2]} color="green" />
        <p style={{ fontSize: "14px" }}>
          <span className={"dark-mode-primary-color"}>
            Rice Production Loss
          </span>
          <b style={{ float: "right" }}>
            {props.data.agriculture["rice"][0] < 1000 ? (
              <span className={"dark-mode-primary-color"}>
                ${props.data.agriculture["rice"][0].toFixed(2)} Million
              </span>
            ) : (
              <span className="dark-mode-theme-color-1">
                ${(props.data.agriculture["rice"][0] / 1000).toFixed(2)} Billion
              </span>
            )}
          </b>
        </p>
        <Progress
          percent={props.newdata.agriculture["rice"][2]}
          color="green"
          className="lessloss"
        />

        <Progress percent={props.data.agriculture["rice"][2]} color="green" />
        <p style={{ fontSize: "14px" }}>
          <span className={"dark-mode-primary-color"}>
            Maize Production Loss
          </span>
          <b style={{ float: "right" }}>
            {props.data.agriculture["maize"][0] < 1000 ? (
              <span className={"dark-mode-primary-color"}>
                ${props.data.agriculture["maize"][0].toFixed(2)} Million
              </span>
            ) : (
              <span className="dark-mode-theme-color-1">
                ${(props.data.agriculture["maize"][0] / 1000).toFixed(2)}{" "}
                Billion
              </span>
            )}
          </b>
        </p>
        <Progress
          percent={props.newdata.agriculture["maize"][2]}
          color="green"
          className="lessloss"
        />

        <Progress percent={props.data.agriculture["maize"][2]} color="green" />
        <p style={{ fontSize: "14px" }}>
          <span className={"dark-mode-primary-color"}>
            Fruit Production Loss
          </span>
          <b style={{ float: "right" }}>
            {props.data.agriculture["fruit"][0] < 1000 ? (
              <span className={"dark-mode-primary-color"}>
                ${props.data.agriculture["fruit"][0].toFixed(2)} Million
              </span>
            ) : (
              <span className="dark-mode-theme-color-1">
                ${(props.data.agriculture["fruit"][0] / 1000).toFixed(2)}{" "}
                Billion
              </span>
            )}
          </b>
        </p>
        <Progress
          percent={props.newdata.agriculture["fruit"][2]}
          color="green"
          className="lessloss"
        />

        <Progress percent={props.data.agriculture["fruit"][2]} color="green" />
        <p style={{ fontSize: "14px" }}>
          <span className={"dark-mode-primary-color"}>
            Vegetable Production Loss
          </span>
          <b style={{ float: "right" }}>
            {props.data.agriculture["vegetable"][0] < 1000 ? (
              <span className={"dark-mode-primary-color"}>
                ${props.data.agriculture["vegetable"][0].toFixed(2)} Million
              </span>
            ) : (
              <span className="dark-mode-theme-color-1">
                ${(props.data.agriculture["vegetable"][0] / 1000).toFixed(2)}{" "}
                Billion
              </span>
            )}
          </b>
        </p>
        <Progress
          percent={props.newdata.agriculture["vegetable"][2]}
          color="green"
          className="lessloss"
        />

        <Progress
          percent={props.data.agriculture["vegetable"][2]}
          color="green"
        />
            </Tab>*/}
      <Tab title="Overall" eventKey="total">
        <div className="Progress">
          <p style={{ fontSize: "14px" }}>
            <span className={"dark-mode-primary-color"}>Total Loss</span>
            <b style={{ float: "right" }}>
              {props.data["total"]["Total Loss"][2] ? (
                <span className={"dark-mode-primary-color"}>
                  {props.data["total"]["Total Loss"][2].toFixed(2)} %
                </span>
              ) : null}
            </b>
          </p>
          <Progress
            percent={props.newdata["total"]["Total Loss"][2]}
            color="green"
            className="lessloss"
          />

          <Progress
            percent={props.data["total"]["Total Loss"][2]}
            color="green"
          />
        </div>
        <div className="Progress">
          <p style={{ fontSize: "14px" }}>
            <span className={"dark-mode-primary-color"}>Total Damage</span>
            <b style={{ float: "right" }}>
              {props.data["total"]["Total Damage"][2] ? (
                <span className={"dark-mode-primary-color"}>
                  {props.data["total"]["Total Damage"][2].toFixed(2)}%
                </span>
              ) : null}
            </b>
          </p>
          <Progress
            percent={props.newdata["total"]["Total Damage"][2]}
            color="green"
            className="lessloss"
          />

          <Progress
            percent={props.data["total"]["Total Damage"][2]}
            color="green"
          />
        </div>
        <div className="Progress">
          <p style={{ fontSize: "14px" }}>
            <span className={"dark-mode-primary-color"}>Total Operational</span>
            <b style={{ float: "right" }}>
              {props.data["total"]["Total Operational"][2] ? (
                <span className={"dark-mode-primary-color"}>
                  {props.data["total"]["Total Operational"][2].toFixed(2)} %
                </span>
              ) : null}
            </b>
          </p>
          <Progress
            percent={props.newdata["total"]["Total Operational"][2]}
            color="green"
            className="lessloss"
          />

          <Progress
            percent={props.data["total"]["Total Operational"][2]}
            color="green"
          />
        </div>
      </Tab>
      {/* <Tab title="Mitigation" eventKey="mitigation">
        <div className="Progress">
          <p style={{ fontSize: "14px" }}>
            <span className={"dark-mode-primary-color"}>
              River Flood {severity} Loss
            </span>
            <b style={{ float: "right" }}>
              {props.data["mitigation"][`River Flood ${severity} Loss`][2] ? (
                <span className={"dark-mode-primary-color"}>
                  {props.data["mitigation"][
                    `River Flood ${severity} Loss`
                  ][2].toFixed(2)}{" "}
                  %
                </span>
              ) : null}
            </b>
          </p>
          <Progress
            percent={props.data["general"]["River Flood Loss"][2]}
            color="green"
            className="lessloss"
          />

          <Progress
            percent={
              props.data["mitigation"][`River Flood ${severity} Loss`][2]
            }
            color="green"
          />
        </div>
        {props.data["general"]["Rainfall Flood Loss"] ? (
          <div className="Progress">
            <p style={{ fontSize: "14px" }}>
              <span className={"dark-mode-primary-color"}>
                Rainfall Flood {severity} Loss
              </span>
              <b style={{ float: "right" }}>
                {props.data["mitigation"][
                  `Rainfall Flood ${severity} Loss`
                ][2] ? (
                  <span className={"dark-mode-primary-color"}>
                    {props.data["mitigation"][
                      `Rainfall Flood ${severity} Loss`
                    ][2].toFixed(2)}{" "}
                    %
                  </span>
                ) : null}
              </b>
            </p>
            <Progress
              percent={props.data["general"]["Rainfall Flood Loss"][2]}
              color="green"
              className="lessloss"
            />

            <Progress
              percent={
                props.data["mitigation"][`Rainfall Flood ${severity} Loss`][2]
              }
              color="green"
            />
          </div>
        ) : null}
            </Tab>*/}
    </Tabs>
  );
};

export default LossPercentComponent;
