import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import { Icon } from "semantic-ui-react";
import { Progress } from "antd";

const LossComponent = (props) => {
  console.log("Losses 2020", props.data["mitigation"]["severity"]);
  let severity = props.data["mitigation"]["severity"];
  return (
    <Tabs defaultActiveKey="General">
      <Tab title="Per Hazard" eventKey="General">
        <br />
        {props.data["general"]["Rainfall Flood Loss"] ? (
          <div className="Progress">
            <p style={{ fontSize: "14px" }}>
              <span className={"dark-mode-primary-color"}>
                Rainfall Flood Loss{" "}
              </span>
              <b style={{ float: "right" }}>
                {props.data["general"]["Rainfall Flood Loss"][0] < 1000 ? (
                  <span className={"dark-mode-primary-color"}>
                    $
                    {props.data["general"]["Rainfall Flood Loss"][0].toFixed(2)}{" "}
                    Million
                  </span>
                ) : (
                  <span className="dark-mode-theme-color-1">
                    $
                    {(
                      props.data["general"]["Rainfall Flood Loss"][0] / 1000
                    ).toFixed(2)}{" "}
                    Billion
                  </span>
                )}
              </b>
            </p>
            <Progress
              percent={props.newdata["general"]["Rainfall Flood Loss"][1]}
              className="lessloss"
              format={(percent) =>
                `$ ${props.newdata["general"]["Rainfall Flood Loss"][0].toFixed(
                  2
                )} Mil`
              }
            />
            <Progress
              percent={props.data["general"]["Rainfall Flood Loss"][1]}
              format={(percent) =>
                `$ ${props.data["general"]["Rainfall Flood Loss"][0].toFixed(
                  2
                )} Mil`
              }
            />
          </div>
        ) : null}
        <div className="Progress">
          <p style={{ fontSize: "14px" }}>
            <span className={"dark-mode-primary-color"}>River Flood Loss </span>
            <b style={{ float: "right" }}>
              {props.data["general"]["River Flood Loss"][0] < 1000 ? (
                <span className={"dark-mode-primary-color"}>
                  ${props.data["general"]["River Flood Loss"][0].toFixed(2)}{" "}
                  Million
                </span>
              ) : (
                <span className="dark-mode-theme-color-1">
                  $
                  {(
                    props.data["general"]["River Flood Loss"][0] / 1000
                  ).toFixed(2)}{" "}
                  Billion
                </span>
              )}
            </b>
          </p>
          <Progress
            percent={props.newdata["general"]["River Flood Loss"][1]}
            className="lessloss"
            format={(percent) =>
              `$ ${props.newdata["general"]["River Flood Loss"][0].toFixed(
                2
              )} Mil`
            }
          />
          <Progress
            percent={props.data["general"]["River Flood Loss"][1]}
            format={(percent) =>
              `$ ${props.data["general"]["River Flood Loss"][0].toFixed(2)} Mil`
            }
          />
        </div>
        <div className="Progress">
          <p style={{ fontSize: "14px" }}>
            <span className={"dark-mode-primary-color"}>Storm Surge Loss </span>
            <b style={{ float: "right" }}>
              {props.data["general"]["Storm Surge Loss"][0] < 1000 ? (
                <span className={"dark-mode-primary-color"}>
                  ${props.data["general"]["Storm Surge Loss"][0].toFixed(2)}{" "}
                  Million
                </span>
              ) : (
                <span className="dark-mode-theme-color-1">
                  $
                  {(
                    props.data["general"]["Storm Surge Loss"][0] / 1000
                  ).toFixed(2)}{" "}
                  Billion
                </span>
              )}
            </b>
          </p>
          <Progress
            percent={props.newdata["general"]["Storm Surge Loss"][1]}
            className="lessloss"
            format={(percent) =>
              `$ ${props.newdata["general"]["Storm Surge Loss"][0].toFixed(
                2
              )} Mil`
            }
          />
          <Progress
            percent={props.data["general"]["Storm Surge Loss"][1]}
            format={(percent) =>
              `$ ${props.data["general"]["Storm Surge Loss"][0].toFixed(2)} Mil`
            }
          />
        </div>
        <div className="Progress">
          <p style={{ fontSize: "14px" }}>
            <span className={"dark-mode-primary-color"}>Typhoon Loss </span>
            <b style={{ float: "right" }}>
              {props.data["general"]["Typhoon Loss"][0] < 1000 ? (
                <span className={"dark-mode-primary-color"}>
                  ${props.data["general"]["Typhoon Loss"][0].toFixed(2)} Million
                </span>
              ) : (
                <span className="dark-mode-theme-color-1">
                  $
                  {(props.data["general"]["Typhoon Loss"][0] / 1000).toFixed(2)}{" "}
                  Billion
                </span>
              )}
            </b>
          </p>
          <Progress
            percent={props.newdata["general"]["Typhoon Loss"][1]}
            className="lessloss"
            format={(percent) =>
              `$ ${props.newdata["general"]["Typhoon Loss"][0].toFixed(2)} Mil`
            }
          />
          <Progress
            percent={props.data["general"]["Typhoon Loss"][1]}
            format={(percent) =>
              `$ ${props.data["general"]["Typhoon Loss"][0].toFixed(2)} Mil`
            }
          />
        </div>

        {props.data["general"]["Temprature/heat loss"] ? (
          <div className="Progress">
            <p style={{ fontSize: "14px" }}>
              <span className={"dark-mode-primary-color"}>
                Temperature/heat Loss{" "}
              </span>
              <b style={{ float: "right" }}>
                {props.data["general"]["Temprature/heat loss"][0] < 1000 ? (
                  <span className={"dark-mode-primary-color"}>
                    $
                    {props.data["general"]["Temprature/heat loss"][0].toFixed(
                      2
                    )}{" "}
                    Million
                  </span>
                ) : (
                  <span className="dark-mode-theme-color-1">
                    $
                    {(
                      props.data["general"]["Temprature/heat loss"][0] / 1000
                    ).toFixed(2)}{" "}
                    Billion
                  </span>
                )}
              </b>
            </p>
            <Progress
              percent={props.newdata["general"]["Temprature/heat loss"][1]}
              className="lessloss"
              format={(percent) =>
                `$ ${props.newdata["general"][
                  "Temprature/heat loss"
                ][0].toFixed(2)} Mil`
              }
            />
            <Progress
              percent={props.data["general"]["Temprature/heat loss"][1]}
              format={(percent) =>
                `$ ${props.data["general"]["Temprature/heat loss"][0].toFixed(
                  2
                )} Mil`
              }
            />
          </div>
        ) : null}
      </Tab>
      <Tab title="Detailed" eventKey="All">
        <br />
        <div className="Progress">
          <p style={{ fontSize: "14px" }}>
            <span className={"dark-mode-primary-color"}>Total Loss </span>
            <b style={{ float: "right" }}>
              {props.data["total"]["Total Loss"][0] < 1000 ? (
                <span className={"dark-mode-primary-color"}>
                  ${props.data["total"]["Total Loss"][0].toFixed(2)} Million
                </span>
              ) : (
                <span className="dark-mode-theme-color-1">
                  ${(props.data["total"]["Total Loss"][0] / 1000).toFixed(2)}{" "}
                  Billion
                </span>
              )}
            </b>
          </p>
          <Progress
            percent={props.newdata["total"]["Total Loss"][1]}
            className="lessloss"
            format={(percent) =>
              `$ ${props.newdata["total"]["Total Loss"][0].toFixed(2)} Mil`
            }
          />
          <Progress
            percent={props.data["total"]["Total Loss"][1]}
            format={(percent) =>
              `$ ${props.data["total"]["Total Loss"][0].toFixed(2)} Mil`
            }
          />
        </div>
        <div className="Progress">
          {props.data["detailed"]["Asset Rainfall Flood Damage"] ? (
            <p style={{ fontSize: "14px" }}>
              <span className={"dark-mode-primary-color"}>
                Asset Rainfall Flood Damage{" "}
              </span>
              <b style={{ float: "right" }}>
                {props.data["detailed"]["Asset Rainfall Flood Damage"][0] <
                1000 ? (
                  <span className={"dark-mode-primary-color"}>
                    $
                    {props.data["detailed"][
                      "Asset Rainfall Flood Damage"
                    ][0].toFixed(2)}{" "}
                    Million
                  </span>
                ) : (
                  <span className="dark-mode-theme-color-1">
                    $
                    {(
                      props.data["detailed"]["Asset Rainfall Flood Damage"][0] /
                      1000
                    ).toFixed(2)}{" "}
                    Billion
                  </span>
                )}
              </b>
            </p>
          ) : null}
          {props.newdata["detailed"]["Asset Rainfall Flood Damage"] ? (
            <Progress
              percent={
                props.newdata["detailed"]["Asset Rainfall Flood Damage"][1]
              }
              color="green"
              className="lessloss"
              format={(percent) =>
                `$ ${props.newdata["detailed"][
                  "Asset Rainfall Flood Damage"
                ][0].toFixed(2)} Mil`
              }
            />
          ) : null}

          {props.data["detailed"]["Asset Rainfall Flood Damage"] ? (
            <Progress
              percent={props.data["detailed"]["Asset Rainfall Flood Damage"][1]}
              color="green"
              format={(percent) =>
                `$ ${props.data["detailed"][
                  "Asset Rainfall Flood Damage"
                ][0].toFixed(2)} Mil`
              }
            />
          ) : null}
        </div>
        <div className="Progress">
          <p style={{ fontSize: "14px" }}>
            <span className={"dark-mode-primary-color"}>
              Asset River Flood Damage{" "}
            </span>
            <b style={{ float: "right" }}>
              {props.data["detailed"]["Asset River Flood Damage"][0] < 1000 ? (
                <span className={"dark-mode-primary-color"}>
                  $
                  {props.data["detailed"][
                    "Asset River Flood Damage"
                  ][0].toFixed(2)}{" "}
                  Million
                </span>
              ) : (
                <span className="dark-mode-theme-color-1">
                  $
                  {(
                    props.data["detailed"]["Asset River Flood Damage"][0] / 1000
                  ).toFixed(2)}{" "}
                  Billion
                </span>
              )}
            </b>
          </p>
          <Progress
            percent={props.newdata["detailed"]["Asset River Flood Damage"][1]}
            color="green"
            className="lessloss"
            format={(percent) =>
              `$ ${props.newdata["detailed"][
                "Asset River Flood Damage"
              ][0].toFixed(2)} Mil`
            }
          />

          <Progress
            percent={props.data["detailed"]["Asset River Flood Damage"][1]}
            color="green"
            format={(percent) =>
              `$ ${props.data["detailed"][
                "Asset River Flood Damage"
              ][0].toFixed(2)} Mil`
            }
          />
        </div>
        <div className="Progress">
          <p style={{ fontSize: "14px" }}>
            <span className={"dark-mode-primary-color"}>
              Asset Storm Surge Damage{" "}
            </span>
            <b style={{ float: "right" }}>
              {props.data["detailed"]["Asset Storm Surge Damage"][0] < 1000 ? (
                <span className={"dark-mode-primary-color"}>
                  $
                  {props.data["detailed"][
                    "Asset Storm Surge Damage"
                  ][0].toFixed(2)}{" "}
                  Million
                </span>
              ) : (
                <span className="dark-mode-theme-color-1">
                  $
                  {(
                    props.data["detailed"]["Asset Storm Surge Damage"][0] / 1000
                  ).toFixed(2)}{" "}
                  Billion
                </span>
              )}
            </b>
          </p>
          <Progress
            percent={props.newdata["detailed"]["Asset Storm Surge Damage"][1]}
            color="green"
            className="lessloss"
            format={(percent) =>
              `$ ${props.newdata["detailed"][
                "Asset Storm Surge Damage"
              ][0].toFixed(2)} Mil`
            }
          />

          <Progress
            percent={props.data["detailed"]["Asset Storm Surge Damage"][1]}
            color="green"
            format={(percent) =>
              `$ ${props.data["detailed"][
                "Asset Storm Surge Damage"
              ][0].toFixed(2)} Mil`
            }
          />
        </div>
        <div className="Progress">
          <p style={{ fontSize: "14px" }}>
            <span className={"dark-mode-primary-color"}>
              Asset Typhoon Damage{" "}
            </span>
            <b style={{ float: "right" }}>
              {props.data["detailed"]["Asset Typhoon Damage"][0] < 1000 ? (
                <span className={"dark-mode-primary-color"}>
                  $
                  {props.data["detailed"]["Asset Typhoon Damage"][0].toFixed(2)}{" "}
                  Million
                </span>
              ) : (
                <span className="dark-mode-theme-color-1">
                  $
                  {(
                    props.data["detailed"]["Asset Typhoon Damage"][0] / 1000
                  ).toFixed(2)}{" "}
                  Billion
                </span>
              )}
            </b>
          </p>
          <Progress
            percent={props.newdata["detailed"]["Asset Typhoon Damage"][1]}
            color="green"
            className="lessloss"
            format={(percent) =>
              `$ ${props.newdata["detailed"]["Asset Typhoon Damage"][0].toFixed(
                2
              )} Mil`
            }
          />

          <Progress
            percent={props.data["detailed"]["Asset Typhoon Damage"][1]}
            color="green"
            format={(percent) =>
              `$ ${props.data["detailed"]["Asset Typhoon Damage"][0].toFixed(
                2
              )} Mil`
            }
          />
        </div>
        <div className="Progress">
          {props.data["detailed"]["Operational Rainfall Flood Loss"] ? (
            <p style={{ fontSize: "14px" }}>
              <span className={"dark-mode-primary-color"}>
                Operational Rainfall Flood Loss{" "}
              </span>
              <b style={{ float: "right" }}>
                {props.data["detailed"]["Operational Rainfall Flood Loss"][0] <
                1000 ? (
                  <span className={"dark-mode-primary-color"}>
                    $
                    {props.data["detailed"][
                      "Operational Rainfall Flood Loss"
                    ][0].toFixed(3)}{" "}
                    Million
                  </span>
                ) : (
                  <span className="dark-mode-theme-color-1">
                    $
                    {(
                      props.data["detailed"][
                        "Operational Rainfall Flood Loss"
                      ][0] / 1000
                    ).toFixed(2)}{" "}
                    Billion
                  </span>
                )}
              </b>
            </p>
          ) : null}
          {props.newdata["detailed"]["Operational Rainfall Flood Loss"] ? (
            <Progress
              percent={
                props.newdata["detailed"]["Operational Rainfall Flood Loss"][1]
              }
              color="green"
              className="lessloss"
              format={(percent) =>
                `$ ${props.newdata["detailed"][
                  "Operational Rainfall Flood Loss"
                ][0].toFixed(3)} Mil`
              }
            />
          ) : null}

          {props.data["detailed"]["Operational Rainfall Flood Loss"] ? (
            <Progress
              percent={
                props.data["detailed"]["Operational Rainfall Flood Loss"][1]
              }
              color="green"
              format={(percent) =>
                `$ ${props.data["detailed"][
                  "Operational Rainfall Flood Loss"
                ][0].toFixed(2)} Mil`
              }
            />
          ) : null}
        </div>
        <div className="Progress">
          <p style={{ fontSize: "14px" }}>
            <span className={"dark-mode-primary-color"}>
              Operational River Flood Loss{" "}
            </span>
            <b style={{ float: "right" }}>
              {props.data["detailed"]["Operational River Flood Loss"][0] <
              1000 ? (
                <span className={"dark-mode-primary-color"}>
                  $
                  {props.data["detailed"][
                    "Operational River Flood Loss"
                  ][0].toFixed(2)}{" "}
                  Million
                </span>
              ) : (
                <span className="dark-mode-theme-color-1">
                  $
                  {(
                    props.data["detailed"]["Operational River Flood Loss"][0] /
                    1000
                  ).toFixed(2)}{" "}
                  Billion
                </span>
              )}
            </b>
          </p>
          <Progress
            percent={
              props.newdata["detailed"]["Operational River Flood Loss"][1]
            }
            color="green"
            className="lessloss"
            format={(percent) =>
              `$ ${props.newdata["detailed"][
                "Operational River Flood Loss"
              ][0].toFixed(2)} Mil`
            }
          />

          <Progress
            percent={props.data["detailed"]["Operational River Flood Loss"][1]}
            color="green"
            format={(percent) =>
              `$ ${props.data["detailed"][
                "Operational River Flood Loss"
              ][0].toFixed(2)} Mil`
            }
          />
        </div>
        <div className="Progress">
          <p style={{ fontSize: "14px" }}>
            <span className={"dark-mode-primary-color"}>
              Operational Storm Surge Loss{" "}
            </span>
            <b style={{ float: "right" }}>
              {props.data["detailed"]["Operational Storm Surge Loss"][0] <
              1000 ? (
                <span className={"dark-mode-primary-color"}>
                  $
                  {props.data["detailed"][
                    "Operational Storm Surge Loss"
                  ][0].toFixed(2)}{" "}
                  Million
                </span>
              ) : (
                <span className="dark-mode-theme-color-1">
                  $
                  {(
                    props.data["detailed"]["Operational Storm Surge Loss"][0] /
                    1000
                  ).toFixed(2)}{" "}
                  Billion
                </span>
              )}
            </b>
          </p>
          <Progress
            percent={
              props.newdata["detailed"]["Operational Storm Surge Loss"][1]
            }
            color="green"
            className="lessloss"
            format={(percent) =>
              `$ ${props.newdata["detailed"][
                "Operational Storm Surge Loss"
              ][0].toFixed(2)} Mil`
            }
          />

          <Progress
            percent={props.data["detailed"]["Operational Storm Surge Loss"][1]}
            color="green"
            format={(percent) =>
              `$ ${props.data["detailed"][
                "Operational Storm Surge Loss"
              ][0].toFixed(2)} Mil`
            }
          />
        </div>
        <div className="Progress">
          <p style={{ fontSize: "14px" }}>
            <span className={"dark-mode-primary-color"}>
              Operational Typhoon Loss{" "}
            </span>
            <b style={{ float: "right" }}>
              {props.data["detailed"]["Operational Typhoon Loss"][0] < 1000 ? (
                <span className={"dark-mode-primary-color"}>
                  $
                  {props.data["detailed"][
                    "Operational Typhoon Loss"
                  ][0].toFixed(2)}{" "}
                  Million
                </span>
              ) : (
                <span className="dark-mode-theme-color-1">
                  $
                  {(
                    props.data["detailed"]["Operational Typhoon Loss"][0] / 1000
                  ).toFixed(2)}{" "}
                  Billion
                </span>
              )}
            </b>
          </p>
          <Progress
            percent={props.newdata["detailed"]["Operational Typhoon Loss"][1]}
            color="green"
            className="lessloss"
            format={(percent) =>
              `$ ${props.newdata["detailed"][
                "Operational Typhoon Loss"
              ][0].toFixed(2)} Mil`
            }
          />

          <Progress
            percent={props.data["detailed"]["Operational Typhoon Loss"][1]}
            color="green"
            format={(percent) =>
              `$ ${props.data["detailed"][
                "Operational Typhoon Loss"
              ][0].toFixed(2)} Mil`
            }
          />
        </div>
        <div className="Progress">
          {props.data["detailed"]["Temprature/heat loss"] ? (
            <p style={{ fontSize: "14px" }}>
              <span className={"dark-mode-primary-color"}>
                Temperature/Heat Loss
              </span>
              <b style={{ float: "right" }}>
                {props.data["detailed"]["Temprature/heat loss"][0] < 1000 ? (
                  <span className={"dark-mode-primary-color"}>
                    $
                    {props.data["detailed"]["Temprature/heat loss"][0].toFixed(
                      2
                    )}{" "}
                    Million
                  </span>
                ) : (
                  <span className="dark-mode-theme-color-1">
                    $
                    {(
                      props.data["detailed"]["Temprature/heat loss"][0] / 1000
                    ).toFixed(2)}{" "}
                    Billion
                  </span>
                )}
              </b>
            </p>
          ) : null}
          {props.newdata["detailed"]["Temprature/heat loss"] ? (
            <Progress
              percent={props.newdata["detailed"]["Temprature/heat loss"][1]}
              color="green"
              className="lessloss"
              format={(percent) =>
                `$ ${props.newdata["detailed"][
                  "Temprature/heat loss"
                ][0].toFixed(2)} Mil`
              }
            />
          ) : null}

          {props.data["detailed"]["Temprature/heat loss"] ? (
            <Progress
              percent={props.data["detailed"]["Temprature/heat loss"][1]}
              color="green"
              format={(percent) =>
                `$ ${props.data["detailed"]["Temprature/heat loss"][0].toFixed(
                  2
                )} Mil`
              }
            />
          ) : null}
        </div>
      </Tab>
      {/*   <Tab title="Energy" eventKey="energy">
        <br />

        <p style={{ fontSize: "14px" }}>
          <span className={"dark-mode-primary-color"}>Total Energy Loss</span>
          <b style={{ float: "right" }}>
            {props.data['detailed'].energy["total_loss"][0] < 1000 ? (
              <span className={"dark-mode-primary-color"}>
                ${props.data['detailed'].energy["total_loss"][0].toFixed(2)} Million
              </span>
            ) : (
              <span className="dark-mode-theme-color-1">
                ${(props.data['detailed'].energy["total_loss"][0] / 1000).toFixed(2)}{" "}
                Billion
              </span>
            )}
          </b>
        </p>
        <Progress
          percent={props.newdata['detailed'].energy["total_loss"][1]}
          color="green"
          className="lessloss"
        />

        <Progress percent={props.data['detailed'].energy["total_loss"][1]} color="green" />

        <p style={{ fontSize: "14px" }}>
          <span className={"dark-mode-primary-color"}>
            Solar Production Loss
          </span>
          <b style={{ float: "right" }}>
            {props.data['detailed'].energy["solar_loss"][0] < 1000 ? (
              <span className={"dark-mode-primary-color"}>
                ${props.data['detailed'].energy["solar_loss"][0].toFixed(2)} Million
              </span>
            ) : (
              <span className="dark-mode-theme-color-1">
                ${(props.data['detailed'].energy["solar_loss"][0] / 1000).toFixed(2)}{" "}
                Billion
              </span>
            )}
          </b>
        </p>
        <Progress
          percent={props.newdata['detailed'].energy["solar_loss"][1]}
          color="green"
          className="lessloss"
        />

        <Progress percent={props.data['detailed'].energy["solar_loss"][1]} color="green" />

        <p style={{ fontSize: "14px" }}>
          <span className={"dark-mode-primary-color"}>
            Wind Production Loss
          </span>
          <b style={{ float: "right" }}>
            {props.data['detailed'].energy["wind_loss"][0] < 1000 ? (
              <span className={"dark-mode-primary-color"}>
                ${props.data['detailed'].energy["wind_loss"][0].toFixed(2)} Million
              </span>
            ) : (
              <span className="dark-mode-theme-color-1">
                ${(props.data['detailed'].energy["wind_loss"][0] / 1000).toFixed(2)} Billion
              </span>
            )}
          </b>
        </p>
        <Progress
          percent={props.newdata['detailed'].energy["wind_loss"][1]}
          color="green"
          className="lessloss"
        />

        <Progress percent={props.data['detailed'].energy["wind_loss"][1]} color="green" />

        <p style={{ fontSize: "14px" }}>
          <span className={"dark-mode-primary-color"}>
            Hydro Production Loss
          </span>
          <b style={{ float: "right" }}>
            {props.data['detailed'].energy["hydro_loss"][0] < 1000 ? (
              <span className={"dark-mode-primary-color"}>
                ${props.data['detailed'].energy["hydro_loss"][0].toFixed(2)} Million
              </span>
            ) : (
              <span className="dark-mode-theme-color-1">
                ${(props.data['detailed'].energy["hydro_loss"][0] / 1000).toFixed(2)}{" "}
                Billion
              </span>
            )}
          </b>
        </p>
        <Progress
          percent={props.newdata['detailed'].energy["hydro_loss"][1]}
          color="green"
          className="lessloss"
        />

        <Progress percent={props.data['detailed'].energy["hydro_loss"][1]} color="green" />
      </Tab>
      <Tab title="Agriculture" eventKey="agri">
        <br />
        <p style={{ fontSize: "14px" }}>
          <span className={"dark-mode-primary-color"}>
            Total Agriculture Loss
          </span>
          <b style={{ float: "right" }}>
            {props.data['detailed'].agriculture["total_loss"][0] < 1000 ? (
              <span className={"dark-mode-primary-color"}>
                ${props.data['detailed'].agriculture["total_loss"][0].toFixed(2)} Million
              </span>
            ) : (
              <span className="dark-mode-theme-color-1">
                ${(props.data['detailed'].agriculture["total_loss"][0] / 1000).toFixed(2)}{" "}
                Billion
              </span>
            )}
          </b>
        </p>
        <Progress
          percent={props.newdata['detailed'].agriculture["total_loss"][1]}
          color="green"
          className="lessloss"
        />

        <Progress
          percent={props.data['detailed'].agriculture["total_loss"][1]}
          color="green"
        />

        <p style={{ fontSize: "14px" }}>
          <span className={"dark-mode-primary-color"}>
            Wheat Production Loss
          </span>
          <b style={{ float: "right" }}>
            {props.data['detailed'].agriculture["wheat"][0] < 1000 ? (
              <span className={"dark-mode-primary-color"}>
                ${props.data['detailed'].agriculture["wheat"][0].toFixed(2)} Million
              </span>
            ) : (
              <span className="dark-mode-theme-color-1">
                ${(props.data['detailed'].agriculture["wheat"][0] / 1000).toFixed(2)}{" "}
                Billion
              </span>
            )}
          </b>
        </p>
        <Progress
          percent={props.newdata['detailed'].agriculture["wheat"][1]}
          color="green"
          className="lessloss"
        />

        <Progress percent={props.data['detailed'].agriculture["wheat"][1]} color="green" />
        <p style={{ fontSize: "14px" }}>
          <span className={"dark-mode-primary-color"}>
            Rice Production Loss
          </span>
          <b style={{ float: "right" }}>
            {props.data['detailed'].agriculture["rice"][0] < 1000 ? (
              <span className={"dark-mode-primary-color"}>
                ${props.data['detailed'].agriculture["rice"][0].toFixed(2)} Million
              </span>
            ) : (
              <span className="dark-mode-theme-color-1">
                ${(props.data['detailed'].agriculture["rice"][0] / 1000).toFixed(2)} Billion
              </span>
            )}
          </b>
        </p>
        <Progress
          percent={props.newdata['detailed'].agriculture["rice"][1]}
          color="green"
          className="lessloss"
        />

        <Progress percent={props.data['detailed'].agriculture["rice"][1]} color="green" />
        <p style={{ fontSize: "14px" }}>
          <span className={"dark-mode-primary-color"}>
            Maize Production Loss
          </span>
          <b style={{ float: "right" }}>
            {props.data['detailed'].agriculture["maize"][0] < 1000 ? (
              <span className={"dark-mode-primary-color"}>
                ${props.data['detailed'].agriculture["maize"][0].toFixed(2)} Million
              </span>
            ) : (
              <span className="dark-mode-theme-color-1">
                ${(props.data['detailed'].agriculture["maize"][0] / 1000).toFixed(2)}{" "}
                Billion
              </span>
            )}
          </b>
        </p>
        <Progress
          percent={props.newdata['detailed'].agriculture["maize"][1]}
          color="green"
          className="lessloss"
        />

        <Progress percent={props.data['detailed'].agriculture["maize"][1]} color="green" />
        <p style={{ fontSize: "14px" }}>
          <span className={"dark-mode-primary-color"}>
            Fruit Production Loss
          </span>
          <b style={{ float: "right" }}>
            {props.data['detailed'].agriculture["fruit"][0] < 1000 ? (
              <span className={"dark-mode-primary-color"}>
                ${props.data['detailed'].agriculture["fruit"][0].toFixed(2)} Million
              </span>
            ) : (
              <span className="dark-mode-theme-color-1">
                ${(props.data['detailed'].agriculture["fruit"][0] / 1000).toFixed(2)}{" "}
                Billion
              </span>
            )}
          </b>
        </p>
        <Progress
          percent={props.newdata['detailed'].agriculture["fruit"][1]}
          color="green"
          className="lessloss"
        />

        <Progress percent={props.data['detailed'].agriculture["fruit"][1]} color="green" />
        <p style={{ fontSize: "14px" }}>
          <span className={"dark-mode-primary-color"}>
            Vegetable Production Loss
          </span>
          <b style={{ float: "right" }}>
            {props.data['detailed'].agriculture["vegetable"][0] < 1000 ? (
              <span className={"dark-mode-primary-color"}>
                ${props.data['detailed'].agriculture["vegetable"][0].toFixed(2)} Million
              </span>
            ) : (
              <span className="dark-mode-theme-color-1">
                ${(props.data['detailed'].agriculture["vegetable"][0] / 1000).toFixed(2)}{" "}
                Billion
              </span>
            )}
          </b>
        </p>
        <Progress
          percent={props.newdata['detailed'].agriculture["vegetable"][1]}
          color="green"
          className="lessloss"
        />

        <Progress
          percent={props.data['detailed'].agriculture["vegetable"][1]}
          color="green"
        />
            </Tab>*/}
      <Tab title="Overall" eventKey="total">
        <div className="Progress">
          <p style={{ fontSize: "14px" }}>
            <span className={"dark-mode-primary-color"}>Total Loss</span>
            <b style={{ float: "right" }}>
              {props.data["total"]["Total Loss"][0] < 1000 ? (
                <span className={"dark-mode-primary-color"}>
                  ${props.data["total"]["Total Loss"][0].toFixed(2)} Million
                </span>
              ) : (
                <span className="dark-mode-theme-color-1">
                  ${(props.data["total"]["Total Loss"][0] / 1000).toFixed(2)}{" "}
                  Billion
                </span>
              )}
            </b>
          </p>
          <Progress
            percent={props.newdata["total"]["Total Loss"][1]}
            color="green"
            className="lessloss"
            format={(percent) =>
              `$ ${props.newdata["total"]["Total Loss"][0].toFixed(2)} Mil`
            }
          />

          <Progress
            percent={props.data["total"]["Total Loss"][1]}
            format={(percent) =>
              `$ ${props.data["total"]["Total Loss"][0].toFixed(2)} Mil`
            }
            color="green"
          />
        </div>
        <div className="Progress">
          <p style={{ fontSize: "14px" }}>
            <span className={"dark-mode-primary-color"}>Total Damage</span>
            <b style={{ float: "right" }}>
              {props.data["total"]["Total Damage"][0] < 1000 ? (
                <span className={"dark-mode-primary-color"}>
                  ${props.data["total"]["Total Damage"][0].toFixed(2)} Million
                </span>
              ) : (
                <span className="dark-mode-theme-color-1">
                  ${(props.data["total"]["Total Damage"][0] / 1000).toFixed(2)}{" "}
                  Billion
                </span>
              )}
            </b>
          </p>
          <Progress
            percent={props.newdata["total"]["Total Damage"][1]}
            color="green"
            className="lessloss"
            format={(percent) =>
              `$ ${props.newdata["total"]["Total Damage"][0].toFixed(2)} Mil`
            }
          />

          <Progress
            percent={props.data["total"]["Total Damage"][1]}
            format={(percent) =>
              `$ ${props.data["total"]["Total Damage"][0].toFixed(2)} Mil`
            }
            color="green"
          />
        </div>

        <div className="Progress">
          <p style={{ fontSize: "14px" }}>
            <span className={"dark-mode-primary-color"}>Total Operational</span>
            <b style={{ float: "right" }}>
              {props.data["total"]["Total Operational"][0] < 1000 ? (
                <span className={"dark-mode-primary-color"}>
                  ${props.data["total"]["Total Operational"][0].toFixed(2)}{" "}
                  Million
                </span>
              ) : (
                <span className="dark-mode-theme-color-1">
                  $
                  {(props.data["total"]["Total Operational"][0] / 1000).toFixed(
                    2
                  )}{" "}
                  Billion
                </span>
              )}
            </b>
          </p>
          <Progress
            percent={props.newdata["total"]["Total Operational"][1]}
            color="green"
            className="lessloss"
            format={(percent) =>
              `$ ${props.newdata["total"]["Total Operational"][0].toFixed(
                2
              )} Mil`
            }
          />

          <Progress
            percent={props.data["total"]["Total Operational"][1]}
            color="green"
            format={(percent) =>
              `$ ${props.data["total"]["Total Operational"][0].toFixed(2)} Mil`
            }
          />
        </div>
      </Tab>
      {/*  <Tab title="Mitigation" eventKey="mitigation">
        {props.data["mitigation"] ? (
          <div className="Progress">
            <p style={{ fontSize: "14px" }}>
              <span className={"dark-mode-primary-color"}>
                River Flood {props.data["mitigation"]["severity"]} Loss
              </span>
              <b style={{ float: "right" }}>
                {props.data["mitigation"][`River Flood ${severity} Loss`][0] <
                1000 ? (
                  <span className={"dark-mode-primary-color"}>
                    $
                    {props.data["mitigation"][
                      `River Flood ${severity} Loss`
                    ][0].toFixed(2)}{" "}
                    Million
                  </span>
                ) : (
                  <span className="dark-mode-theme-color-1">
                    $
                    {(
                      props.data["mitigation"][
                        `River Flood ${severity} Loss`
                      ][0] / 1000
                    ).toFixed(2)}{" "}
                    Billion
                  </span>
                )}
              </b>
            </p>
            <Progress
              percent={props.data["general"]["River Flood Loss"][1]}
              color="green"
              className="lessloss"
              format={(percent) =>
                `$ ${props.data["general"]["River Flood Loss"][0].toFixed(
                  2
                )} Mil`
              }
            />

            <Progress
              percent={
                props.data["mitigation"][`River Flood ${severity} Loss`][1]
              }
              format={(percent) =>
                `$ ${props.data["mitigation"][
                  `River Flood ${severity} Loss`
                ][0].toFixed(2)} Mil`
              }
              color="green"
            />
          </div>
        ) : null}
        {props.data["general"]["Rainfall Flood Loss"] ? (
          <div className="Progress">
            <p style={{ fontSize: "14px" }}>
              <span className={"dark-mode-primary-color"}>
                Rainfall Flood {severity} Loss
              </span>
              <b style={{ float: "right" }}>
                {props.data["mitigation"][
                  `Rainfall Flood ${severity} Loss`
                ][0] < 1000 ? (
                  <span className={"dark-mode-primary-color"}>
                    $
                    {props.data["mitigation"][
                      `Rainfall Flood ${severity} Loss`
                    ][0].toFixed(2)}{" "}
                    Million
                  </span>
                ) : (
                  <span className="dark-mode-theme-color-1">
                    $
                    {(
                      props.data["mitigation"][
                        `Rainfall Flood ${severity} Loss`
                      ][0] / 1000
                    ).toFixed(2)}{" "}
                    Billion
                  </span>
                )}
              </b>
            </p>
            <Progress
              percent={props.data["general"]["Rainfall Flood Loss"][1]}
              color="green"
              className="lessloss"
              format={(percent) =>
                `$ ${props.data["general"]["Rainfall Flood Loss"][0].toFixed(
                  2
                )} Mil`
              }
            />

            <Progress
              percent={
                props.data["mitigation"][`Rainfall Flood ${severity} Loss`][1]
              }
              format={(percent) =>
                `$ ${props.data["mitigation"][
                  `Rainfall Flood ${severity} Loss`
                ][0].toFixed(2)} Mil`
              }
              color="green"
            />
          </div>
        ) : null}
            </Tab>*/}
    </Tabs>
  );
};

export default LossComponent;
