import React,{Component, Fragment} from 'react';
import { Header,Grid,Icon,Image,Segment,Sidebar,Accordion } from 'semantic-ui-react';
import logo from '../assets/logo_land.png';
import whitelogo from '../assets/logo.png';  
import blueswan from '../assets/logo1.png';
import whiteswan from '../assets/logo23.png';
import Profile from '../assets/profile.png';
import './sidebar.css';
import {connect} from 'react-redux';
import {locus,asset} from '../actions';
import {history} from '../_helpers/history';
import ReactDOM from 'react-dom';
import { Nav, NavItem, Collapse } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withRouter } from 'react-router-dom';
import {reveal as Menu} from 'react-burger-menu';
import menuItems from '../constants/menu';

import {
  setContainerClassnames,
  addContainerClassname,
  changeDefaultClassnames,
  changeSelectedMenuHasSubItems
} from '../redux/actions';

class SideNavbar extends Component{
  constructor(props){
    super(props)
  }
  state={
    assets:'',
    isLevel2SidebarOpen: false,
    sidebarLevel: 1,
    sidebarDirection: "right",
    selectedParentMenu: '',
    viewingParentMenu: '',
    collapsedMenus: []
  }

  delete=(project,asset)=>{
    console.log("delete asset",project,asset)
    let formdata=new FormData();
    formdata.append("project_name",`Project - ${project}`)
    formdata.append("asset_name",asset)
    this.props.deleteAsset(formdata)

  }

  componentDidMount(){
    this.props.getUser();
    window.addEventListener('resize', this.handleWindowResize);
    this.handleWindowResize();
    this.handleProps();
    this.setSelectedLiActive(this.setHasSubItemStatus);
  }
  componentDidUpdate(prevProps,prevState){
    if(prevProps.locus!==this.props.locus){
      this.setState({assets:this.props.locus})
    }
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setSelectedLiActive(this.setHasSubItemStatus);
    
      window.scrollTo(0, 0);
      }
      this.handleProps();
  }
  componentWillUnmount() {
    this.removeEvents();
    window.removeEventListener('resize', this.handleWindowResize);
    }
  shouldComponentUpdate(nextProps, nextState){
    if(this.props.navButtonClicks == nextProps.navButtonClicks - 1){
      this.toggleSidebar()
      return false
    } else{
      return true
    }
  }

  addLocation(assets){
    console.log("transfer",assets);
    history.push({
      pathname:'/location',
      state:{
        assets:assets,
      }
    })

  }
  handleWindowResize = event => {
    if (event && !event.isTrusted) {
      return;
    }
    const { containerClassnames } = this.props;
    let nextClasses = this.getMenuClassesForResize(containerClassnames);
    this.props.setContainerClassnames(
      0,
      nextClasses.join(' '),
      this.props.selectedMenuHasSubItems
    );
    };

    handleDocumentClick = e => {
    const container = this.getContainer();
    let isMenuClick = false;
    if (
      e.target &&
      e.target.classList &&
      (e.target.classList.contains('menu-button') ||
      e.target.classList.contains('menu-button-mobile'))
    ) {
      isMenuClick = true;
    } else if (
      e.target.parentElement &&
      e.target.parentElement.classList &&
      (e.target.parentElement.classList.contains('menu-button') ||
      e.target.parentElement.classList.contains('menu-button-mobile'))
    ) {
      isMenuClick = true;
    } else if (
      e.target.parentElement &&
      e.target.parentElement.parentElement &&
      e.target.parentElement.parentElement.classList &&
      (e.target.parentElement.parentElement.classList.contains('menu-button') ||
      e.target.parentElement.parentElement.classList.contains(
        'menu-button-mobile'
      ))
    ) {
      isMenuClick = true;
    }
    if (container.contains(e.target) || container === e.target || isMenuClick) {
      return;
    }
    this.setState({
      viewingParentMenu: ''
    });
    this.toggle();
    };
  
    getMenuClassesForResize = classes => {
    const { menuHiddenBreakpoint, subHiddenBreakpoint } = this.props;
    let nextClasses = classes.split(' ').filter(x => x !== '');
    const windowWidth = window.innerWidth;
    if (windowWidth < menuHiddenBreakpoint) {
      nextClasses.push('menu-mobile');
    } else {
      nextClasses = nextClasses.filter(x => x !== 'menu-mobile');
      if (
      nextClasses.includes('menu-default') &&
      nextClasses.includes('menu-sub-hidden')
      ) {
      nextClasses = nextClasses.filter(x => x !== 'menu-sub-hidden');
      }
    }
    return nextClasses;
    };
  
    getContainer = () => {
    return ReactDOM.findDOMNode(this);
    };
  toggle = () => {
    const hasSubItems = this.getIsHasSubItem();
    this.props.changeSelectedMenuHasSubItems(hasSubItems);
    const { containerClassnames, menuClickCount } = this.props;
    const currentClasses = containerClassnames
      ? containerClassnames.split(' ').filter(x => x !== '')
      : '';
    let clickIndex = -1;

    if (!hasSubItems) {
      if (
      currentClasses.includes('menu-default') &&
      (menuClickCount % 4 === 0 || menuClickCount % 4 === 3)
      ) {
      clickIndex = 1;
      } else if (
      currentClasses.includes('menu-sub-hidden') &&
      (menuClickCount === 2 || menuClickCount === 3)
      ) {
      clickIndex = 0;
      } else if (
      currentClasses.includes('menu-hidden') ||
      currentClasses.includes('menu-mobile')
      ) {
      clickIndex = 0;
      }
    } else {
      if (currentClasses.includes('menu-sub-hidden') && menuClickCount === 3) {
      clickIndex = 2;
      } else if (
      currentClasses.includes('menu-hidden') ||
      currentClasses.includes('menu-mobile')
      ) {
      clickIndex = 0;
      }
    }
    if (clickIndex >= 0) {
      this.props.setContainerClassnames(
      clickIndex,
      containerClassnames,
      hasSubItems
      );
    }
    };
  
    handleProps = () => {
    this.addEvents();
    };
  
    addEvents = () => {
    ['click', 'touchstart', 'touchend'].forEach(event =>
      document.addEventListener(event, this.handleDocumentClick, true)
    );
    };
  
    removeEvents = () => {
    ['click', 'touchstart', 'touchend'].forEach(event =>
      document.removeEventListener(event, this.handleDocumentClick, true)
    );
    };
  
    setSelectedLiActive = callback => {
    const oldli = document.querySelector('.sub-menu  li.active');
    if (oldli != null) {
      oldli.classList.remove('active');
    }
  
    const oldliSub = document.querySelector('.third-level-menu  li.active');
    if (oldliSub != null) {
      oldliSub.classList.remove('active');
    }
  
    /* set selected parent menu */
    const selectedSublink = document.querySelector('.third-level-menu  a.active');
    if (selectedSublink != null) {
      selectedSublink.parentElement.classList.add('active');
    }
  
    const selectedlink = document.querySelector('.sub-menu  a.active');
    if (selectedlink != null) {
      selectedlink.parentElement.classList.add('active');
      this.setState(
      {
        selectedParentMenu: selectedlink.parentElement.parentElement.getAttribute(
        'data-parent'
        )
      },
      callback
      );
    } else {
      var selectedParentNoSubItem = document.querySelector(
      '.main-menu  li a.active'
      );
      if (selectedParentNoSubItem != null) {
      this.setState(
        {
        selectedParentMenu: selectedParentNoSubItem.getAttribute(
          'data-flag'
        )
        },
        callback
      );
      } else if (this.state.selectedParentMenu === '') {
      this.setState(
        {
        selectedParentMenu: menuItems[0].id
        },
        callback
      );
      }
    }
    };
  
    setHasSubItemStatus = () => {
    const hasSubmenu = this.getIsHasSubItem();
    this.props.changeSelectedMenuHasSubItems(hasSubmenu);
    this.toggle();
    };
  
    getIsHasSubItem = () => {
    const { selectedParentMenu } = this.state;
    const menuItem = menuItems.find(x => x.id === selectedParentMenu);
    if (menuItem)
      return menuItem && menuItem.subs && menuItem.subs.length > 0
      ? true
      : false;
    else return false;
    };
    openSubMenu = (e, menuItem) => {
    const selectedParent = menuItem.id;
    const hasSubMenu = menuItem.subs && menuItem.subs.length > 0;
    this.props.changeSelectedMenuHasSubItems(hasSubMenu);

    if(selectedParent == "My Assets"||"Hazards"){
      let collapsedMenus = this.state.collapsedMenus;
      for(let i = 1; i < document.getElementsByClassName("rotate-arrow-icon opacity-50").length; i++){
        collapsedMenus.push(document.getElementsByClassName("rotate-arrow-icon opacity-50")[i].getAttribute("id"));
      }
      this.setState({
        collapsedMenus
      });
    }

    if (!hasSubMenu) {
      this.setState({
      viewingParentMenu: selectedParent,
      selectedParentMenu: selectedParent
      });
      this.toggle();
    } else {
      e.preventDefault();
  
      const { containerClassnames, menuClickCount } = this.props;
      const currentClasses = containerClassnames
      ? containerClassnames.split(' ').filter(x => x !== '')
      : '';

      if (!currentClasses.includes('menu-mobile')) {
      if (
        currentClasses.includes('menu-sub-hidden') &&
        (menuClickCount === 2 || menuClickCount === 0)
      ) {
        this.props.setContainerClassnames(3, containerClassnames, hasSubMenu);
      } else if (
        currentClasses.includes('menu-hidden') &&
        (menuClickCount === 1 || menuClickCount === 3)
      ) {
        this.props.setContainerClassnames(2, containerClassnames, hasSubMenu);
      } else if (
        currentClasses.includes('menu-default') &&
        !currentClasses.includes('menu-sub-hidden') &&
        (menuClickCount === 1 || menuClickCount === 3)
      ) {
        this.props.setContainerClassnames(0, containerClassnames, hasSubMenu);
      }
      } else {
      this.props.addContainerClassname(
        'sub-show-temporary',
        containerClassnames
      );
      }
      this.setState({
      viewingParentMenu: selectedParent
      });
    }
    };
  
    toggleMenuCollapse = (e, menuKey) => {
    e.preventDefault();
  
    let collapsedMenus = this.state.collapsedMenus;
    if (collapsedMenus.indexOf(menuKey) > -1) {
      this.setState({
      collapsedMenus: collapsedMenus.filter(x => x !== menuKey)
      });
    } else {
      collapsedMenus.push(menuKey);
      this.setState({
      collapsedMenus
      });
    }
    return false;
    };
  render(){

    const isDarkMode = localStorage.getItem("theme") == "dark"



// const rootPanels = [
//   { key: 'panel-1', title: {children:(<a><Icon name="folder"></Icon>My Assets</a>)}, content: { content: Level1Content } }
  
// ]

const {
  selectedParentMenu,
  viewingParentMenu,
  collapsedMenus
  } = this.state;

    
    return(
      <>
      <div className="sidebar">
        <div className="main-menu">
          <div className="scroll">
            <PerfectScrollbar
        options={{ suppressScrollX: true, wheelPropagation: false }}
            >
              <Nav vertical className="list-unstyled">
                    
                     <NavItem>
                      {isDarkMode?<Image src={whiteswan} size='small' style={{padding:'7.5%'}}/>:<Image src={whiteswan} size="small" style={{padding:'15%'}}/>}  
                     </NavItem>
                   <br/><br/><br/>
                {menuItems &&
                  menuItems.map(item => {
                    return (
                      
                      <NavItem
                        key={item.id}
                        className={classnames({
                          active:
                            (selectedParentMenu === item.id &&
                              viewingParentMenu === '') ||
                            viewingParentMenu === item.id
                        })}
                      >
                        {item.newWindow ? (
                          <a
                            href={item.to}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <i className={item.icon}/> 
                            <span id={item.label}>{item.id}</span>
                          </a>
                        ) : !item.disabled ? (
                          <NavLink
                            to={item.to}
                            onClick={e => this.openSubMenu(e, item)}
                            data-flag={item.id}
                          >
                            <i className={item.icon} />{' '}
                            <span id={item.label}>{item.id}</span>
                          </NavLink>
                        ) : (
              <NavLink
                to={item.to}
                data-flag={item.id}
                onClick={e => e.preventDefault()}
              >
                <i className={item.icon} />{' '}
                <span id={item.label}>{item.id}</span>
              </NavLink>
            )}
                      </NavItem>
                      
                      
                    );
                  })}
                  
                  <NavItem className="logo"> 
                    <p style={{color:'white',textAlign:'center',fontWeight:'bold'}}>Powered By</p>
                      {isDarkMode?<Image src={whitelogo} size="small" style={{padding:'7.5%'}}/>:<Image src={whitelogo} size="small" style={{padding:'7.5%'}}/>}
                  </NavItem>
              </Nav>
            </PerfectScrollbar>
          </div>
     
    </div>

     
      </div>

       <Menu noOverlay>
                    
                     <NavItem>
                      {isDarkMode?<Image src={whiteswan} size='small' style={{padding:'7.5%'}}/>:<Image src={whiteswan} size="small" style={{padding:'15%'}}/>}  
                     </NavItem>
                     <br/><br/><br/>
                    <NavItem>
                      <NavLink to="/project" >
                        <i className="iconsminds-file"/><span id="menu.main">Project</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/dashboard" >
                        <i className="iconsminds-dashboard"/><span id="menu.main">Dashboard</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/location" >
                        <i className="iconsminds-home"/><span id="menu.main">Location</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/hazards" >
                        <i className="iconsminds-cloud-rain"/><span id="menu.main">Hazards</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/invoice" >
                        <i className="iconsminds-invoice"/><span id="menu.main">Invoice</span>
                      </NavLink>
                    </NavItem>
                    
                    <NavItem>
                      <NavLink to="/methodology" >
                        <i className="iconsminds-methodology"/><span id="menu.main">Methodology</span>
                      </NavLink>
                    </NavItem>
                  <NavItem>

                  </NavItem>
                  <NavItem className="logo"> 
                    <p style={{color:'white',textAlign:'center',fontWeight:'bold'}}>Powered By</p>
                      {isDarkMode?<Image src={whitelogo} size="small" style={{padding:'7.5%'}}/>:<Image src={whitelogo} size="small" style={{padding:'7.5%'}}/>}
                  </NavItem>
              </Menu>
    </>
      )
  }
}
const mapStateToProps = state =>{
  const {
    containerClassnames,
    subHiddenBreakpoint,
    menuHiddenBreakpoint,
    menuClickCount,
    selectedMenuHasSubItems
  } = state.menu;

  return {
    errors:state.locus.errors,
    permissions:state.locus.permissions,
    asset:state.asset.asset,
    containerClassnames,
    subHiddenBreakpoint,
    menuHiddenBreakpoint,
    menuClickCount,
    selectedMenuHasSubItems
  }
}
const mapDispatchToProps = dispatch =>{
  return{
    getLocations:()=>{
      dispatch(locus.getLocations());
    },
    getUser:()=>{
      dispatch(locus.getUser());
    },
    deleteAsset:(formdata)=>{
      dispatch(asset.deleteAsset(formdata))
    },
    setContainerClassnames:(clickIndex, strCurrentClasses,selectedMenuHasSubItems) => {dispatch(setContainerClassnames(clickIndex, strCurrentClasses,selectedMenuHasSubItems))},
    addContainerClassname:(classname, strCurrentClasses) => {dispatch(addContainerClassname(classname, strCurrentClasses))},
    changeDefaultClassnames:(strCurrentClasses) => {dispatch(changeDefaultClassnames(strCurrentClasses))},
    changeSelectedMenuHasSubItems:(payload) => {dispatch(changeSelectedMenuHasSubItems(payload))},
    // setContainerClassnames,
    // addContainerClassname,
    // changeDefaultClassnames,
    // changeSelectedMenuHasSubItems
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SideNavbar)
  );
