import React, { Component } from "react";
import { ReCaptcha } from "react-recaptcha-google";
import {
  Form,
  Button,
  Input,
  TextArea,
  Grid,
  Container,
  Message,
  Image,
  Header,
  Icon,
  Dropdown,
  Checkbox,
} from "semantic-ui-react";
import { Redirect } from "react-router-dom";
import login from "../assets/login.png";
import logo from "../assets/logo1.png";
import { connect } from "react-redux";
import { auth } from "../actions";

import Spinner from "./spinner";

import styles from "./login.css";

const AnalysisOptions = [
  { key: "HongKong", value: "local", text: "HongKong" },
  { key: "Global", value: "global", text: "Global" },
];

class Login extends Component {
  state = {
    username: "",
    password: "",
    company_code: "",
    loading: false,
    remember: true,
    analysis: "",
  };

  componentDidMount() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
    }
  }
  onLoadRecaptcha() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    let formdata = new FormData();
    formdata.append("username", this.state.username);
    formdata.append("password", this.state.password);
    formdata.append("company_code", this.state.company_code);

    this.setState({ loading: true }, () => {
      this.props.login(formdata);
    });
  };
  handleAnalysis = (e, value) => {
    this.setState({ analysis: value.value }, () =>
      console.log("this.state.ana", this.state.analysis)
    );
  };

  render() {
    if (auth.isUserAuthenticated()) {
      return <Redirect to="/location" />;
    }
    console.log(this.props.errors);
    return (
      <Grid
        id="login"
        style={{ height: "100vh", backgroundColor: "#ecf0f3" }}
        verticalAlign="middle"
        centered
      >
        <Grid.Row
          style={{
            padding: "5em",
            backgroundColor: "#ecf0f3",
            boxShadow: "10px 10px 10px #d1d9e6,-10px -10px 10px #f9f9f9",
            margin: "auto",
            marginLeft: "15%",
            marginRight: "15%",
          }}
        >
          <div className="switch">
            <Image src={logo} size="small" centered />

            <div class="switch__circle_login"></div>
            <div class="switch__circle switch__circle--t"></div>
          </div>

          <Grid.Column
            computer={4}
            style={{ backgroundColor: "#ecf0f3" }}
          ></Grid.Column>

          <Grid.Column computer={8} tablet={16} mobile={16}>
            <h2 style={{ fontSize: "34px", textAlign: "center" }}>Sign In</h2>
            {this.props.errors.length > 0 ? (
              <div className="row" style={{ textAlign: "center" }}>
                <span style={{ color: "red" }}>
                  {this.props.errors[0].message}
                </span>
              </div>
            ) : null}
            <br />
            <Form id="login">
              <Form.Group widths="equal">
                <Form.Field
                  id="form-input-control-username"
                  control={Input}
                  placeholder="Username"
                  value={this.state.username}
                  onChange={(e) => this.setState({ username: e.target.value })}
                />
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field
                  id="form-input-control-password"
                  control={Input}
                  placeholder="Password"
                  type="password"
                  value={this.state.password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field
                  id="form-input-control-company"
                  control={Input}
                  placeholder="Company Code"
                  value={this.state.company_code}
                  onChange={(e) =>
                    this.setState({ company_code: e.target.value })
                  }
                />
              </Form.Group>

              <Form.Field
                id="form-input-control-remember"
                control={Checkbox}
                label="Remember Me"
                checked={this.state.remember}
                onChange={(e) => this.setState({ remember: e.target.checked })}
                style={{ width: "100%" }}
              />

              {this.state.loading && this.props.errors.length == 0 ? (
                <Button
                  style={{ backgroundColor: "#002060", marginLeft: "40%" }}
                >
                  <Spinner />
                </Button>
              ) : (
                <Button
                  style={{
                    backgroundColor: "#002060",
                    fontSize: "16px",
                    color: "white",
                    marginLeft: "40%",
                    borderRadius: "25px",
                    boxShadow: "8px 8px 16px #d1d9e6, -8px -8px 16px #f9f9f9",
                  }}
                  onClick={this.onSubmit}
                  primary
                >
                  Login
                </Button>
              )}
            </Form>
            <br />
            {/*Don't have an Account ? <a href="/register">Create an Account</a>*/}
            <a style={{ float: "right" }} href="#">
              Forgot password ?
            </a>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  let errors = [];
  if (state.auth.errors) {
    errors = Object.keys(state.auth.errors).map((field) => {
      return { field, message: state.auth.errors[field] };
    });
  }

  return {
    errors,
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (formdata) => {
      return dispatch(auth.login(formdata));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
