import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Container, Row, Col } from "react-bootstrap";
import "semantic-ui-css/semantic.min.css";
import { BrowserRouter } from "react-router-dom";
import "mapbox-gl/dist/mapbox-gl.css";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import errorbg from "./assets/error-bg.jpg";

function FallbackComponent() {
  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <div class="errorbg">
            <img src={errorbg} />
          </div>
          <div class="error-card">
            <div class="error-card-body">
              <p class="error-card-main-text">404</p>
              <h5 class="error-card-title">Page Not Found</h5>
              <p class="error-card-text">
                The link you have clicked is broken or the page may have been
                removed or renamed.
              </p>
              <a href="/" class="btn btn-primary">
                Go Back
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
const myFallback = <FallbackComponent />;

Sentry.init({
  dsn: "https://3f4caee3f74b4c4cb08be0e20fd1f5e0@o1029519.ingest.sentry.io/5996520",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <BrowserRouter>
    <Sentry.ErrorBoundary fallback={myFallback}>
      <App />
    </Sentry.ErrorBoundary>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
