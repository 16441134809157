import React, { Component } from "react";
import { connect } from "react-redux";

import tr1 from "../assets/toprow1.png";
import tr2 from "../assets/tr2.png";
import tr3 from "../assets/toprow3.png";
import tr4 from "../assets/toprow4.png";
import { Container, Row, Col } from "react-bootstrap";
import { Icon, Modal } from "semantic-ui-react";
import { locus, auth } from "../actions";
import { ResponsiveHeatMap } from "@nivo/heatmap";
import { Table } from "antd";
import PropertyMap from "./propertymap";

const columns_typhoon = [
  {
    title: "Elevation (m)",
    key: "elevation",
    dataIndex: "elevation",
  },

  {
    title: "Slope (%)",
    key: "slope",
    dataIndex: "slope",
  },
  {
    title: "Lulc",
    key: "lulc",
    dataIndex: "lulc",
  },
  {
    title: "Precipitation (mm)",
    key: "precipitation",
    dataIndex: "precipitation",
  },
  {
    title: "Typhoon Windspeed (kmph)",
    key: "typhoon_windspeed",
    dataIndex: "typhoon_windspeed",
  },

  {
    title: "Score",
    key: "score",
    dataIndex: "score",
  },
  {
    title: "Severity",
    key: "severity",
    dataIndex: "severity",
  },
];
const data_typhoon = [
  {
    elevation: "0-20",
    precipitation: "244-333",
    slope: ">10",
    lulc: "Agriculture",
    severity: "Very High",

    typhoon_windspeed: ">193",
    score: "100",
  },
  {
    elevation: "21-40",
    precipitation: "206-244",
    slope: "5-10",
    lulc: "Builtup",
    severity: "High",

    typhoon_windspeed: "117-193",
    score: "80",
  },
  {
    elevation: "41-80",
    precipitation: "173-206",
    slope: "3-5",
    lulc: "Plantation",
    severity: "Medium",

    typhoon_windspeed: "88-117",
    score: "60",
  },
  {
    elevation: "81-150",
    precipitation: "125-173",
    slope: "1-3",
    lulc: "Evergreen Forest",
    severity: "Low",

    typhoon_windspeed: "61-88",
    score: "40",
  },
  {
    elevation: ">150",
    precipitation: "43-125",
    slope: "0-1",
    lulc: "Scrub Land",
    severity: "Very Low",

    typhoon_windspeed: "<61",
    score: "20",
  },
];

const columns = [
  {
    title: "Elevation (m)",
    key: "elevation",
    dataIndex: "elevation",
  },
  {
    title: "Hand (m)",
    key: "hand",
    dataIndex: "hand",
  },
  {
    title: "Slope (%)",
    key: "slope",
    dataIndex: "slope",
  },
  {
    title: "Lulc",
    key: "lulc",
    dataIndex: "lulc",
  },

  {
    title: "Flood (m)",
    key: "flood",
    dataIndex: "flood",
  },
  {
    title: "Score",
    key: "score",
    dataIndex: "score",
  },
  {
    title: "Severity",
    key: "severity",
    dataIndex: "severity",
  },
];
const data = [
  {
    elevation: "0-20",
    hand: "0-1",
    slope: ">10",
    lulc: "Agriculture",
    severity: "Very High",

    flood: ">2",
    score: "100",
  },
  {
    elevation: "21-40",
    hand: "1-2",
    slope: "5-10",
    lulc: "Builtup",
    severity: "High",

    flood: "1-2",
    score: "80",
  },
  {
    elevation: "41-80",
    hand: "2-3",
    slope: "3-5",
    lulc: "Plantation",
    severity: "Moderate",

    flood: "0.5-1",
    score: "60",
  },
  {
    elevation: "81-150",
    hand: "3-4",
    slope: "1-3",
    lulc: "Forest",
    severity: "Low",

    flood: "0.25-0.5",
    score: "40",
  },
  {
    elevation: ">150",
    hand: "4-5",
    slope: "0-1",
    lulc: "WasteLands",
    severity: "Very Low",

    flood: "0-0.25",
    score: "20",
  },
];

class RealEstateDashboard extends Component {
  state = {
    flood_score: false,
    typhoon_score: false,
  };
  componentDidMount() {
    if (this.props.location.state.project_name) {
      let formdata = new FormData();
      formdata.append("project_name", this.props.location.state.project_name);
      this.props.getPropertyRisk(formdata);
    }
  }
  handleFloodScore = () => {
    this.setState({ flood_score: true });
  };
  handleTyphoonScore = () => {
    this.setState({ typhoon_score: true });
  };
  handleClose = () => {
    this.setState({ typhoon_score: false, flood_score: false });
  };
  render() {
    const columns_data_flood = [
      {
        title: "Name",
        key: "name",
        dataIndex: "name",
      },
      {
        title: () => {
          return (
            <div>
              Flood Scores{" "}
              <Icon
                name="info"
                onClick={this.handleFloodScore}
                style={{ cursor: "pointer" }}
              />
            </div>
          );
        },
        key: "flood_score",
        dataIndex: "flood_score",
      },

      {
        title: "GDP (USD/capita) ",
        key: "gdp_value",
        dataIndex: "gdp_value",
      },
      {
        title: "Population Density (per sqkm)",
        key: "population_value",
        dataIndex: "population_value",
      },

      {
        title: "Flood Mean Score",
        key: "flood_mean_score",
        dataIndex: "flood_mean_score",
      },
      {
        title: "Flood Min. Value",
        key: "flood_min_value",
        dataIndex: "flood_min_value",
      },
      {
        title: "Flood Max. Value",
        key: "flood_max_value",
        dataIndex: "flood_max_value",
      },
      {
        title: "Flood Min. Distance (in m)",
        key: "flood_min_distance",
        dataIndex: "flood_min_distance",
      },
      {
        title: "Flood Max. Distance (in m)",
        key: "flood_max_distance",
        dataIndex: "flood_max_distance",
      },
    ];
    const columns_data_typhoon = [
      {
        title: "Name",
        key: "name",
        dataIndex: "name",
      },

      {
        title: () => {
          return (
            <div>
              Typhoon Scores{" "}
              <Icon
                name="info"
                onClick={this.handleTyphoonScore}
                style={{ cursor: "pointer" }}
              />
            </div>
          );
        },
        key: "typhoon_score",
        dataIndex: "typhoon_score",
      },

      {
        title: "GDP (USD/capita)",
        key: "gdp_value",
        dataIndex: "gdp_value",
      },
      {
        title: "Population Density (per sqkm)",
        key: "population_value",
        dataIndex: "population_value",
      },

      {
        title: "Typhoon Mean Score",
        key: "typhoon_mean_score",
        dataIndex: "typhoon_mean_score",
      },
      {
        title: "Typhoon Min. Value",
        key: "typhoon_min_value",
        dataIndex: "typhoon_min_value",
      },
      {
        title: "Typhoon Max. Value",
        key: "typhoon_max_value",
        dataIndex: "typhoon_max_value",
      },
      {
        title: "Typhoon Min. Distance (in m)",
        key: "typhoon_min_distance",
        dataIndex: "typhoon_min_distance",
      },
      {
        title: "Typhoon Max. Distance (in m)",
        key: "typhoon_max_distance",
        dataIndex: "typhoon_max_distance",
      },
    ];
    if (this.props.isLoading) {
      return <div id="loading"> </div>;
    }
    console.log("property", this.props.property);
    let heatmapdata = [];
    let flood_score_average = 0;
    let typhoon_score_average = 0;
    if (this.props.property) {
      for (let i = 0; i < this.props.property.length; i++) {
        heatmapdata.push({
          asset: this.props.property[i].name,
          data: [
            { x: "Flood Score", y: this.props.property[i].flood_score },
            { x: "Typhoon Score", y: this.props.property[i].typhoon_score },
          ],
        });
        flood_score_average += this.props.property[i].flood_score;
        flood_score_average = flood_score_average / this.props.property.length;
        typhoon_score_average += this.props.property[i].typhoon_score;
        typhoon_score_average =
          typhoon_score_average / this.props.property.length;
      }
    }
    return (
      <Container fluid={true}>
        <br />
        <Row>
          <h2>Property Risk Analysis</h2>
        </Row>
        <br />
        <Row className="toprow" style={{ marginLeft: "2%" }}>
          <Col lg={2} className="borderleft">
            <Row>
              <Col lg={6}>
                <img
                  src={tr1}
                  style={{
                    height: "50px",
                  }}
                />
              </Col>
              <Col lg={6}>
                {this.props.property.length > 0 ? (
                  <p className="trpara">
                    Number of Assets <br />
                    {this.props.property.length} &nbsp;
                  </p>
                ) : null}
              </Col>
            </Row>
          </Col>
          <Col lg={1}></Col>
          <Col lg={2} className="borderleft">
            <Row>
              <Col lg={6}>
                <img
                  src={tr2}
                  style={{
                    height: "50px",
                  }}
                />
              </Col>
              <Col lg={6}>
                <p className="trpara2">
                  Mean Flood Score <br />
                  {flood_score_average.toFixed(2)}&nbsp;
                </p>
              </Col>
            </Row>
          </Col>
          <Col lg={1}></Col>
          <Col lg={2} className="borderleft">
            <Row>
              <Col lg={6}>
                <img
                  src={tr3}
                  style={{
                    height: "50px",
                  }}
                />
              </Col>
              <Col lg={6}>
                <p className="trpara">
                  Mean Typhoon Score <br />
                  {typhoon_score_average.toFixed(2)}
                  <br /> &nbsp;
                </p>
              </Col>
            </Row>
          </Col>
          <Col lg={1}></Col>
          <Col lg={2} className="borderleft">
            <Row>
              <Col lg={6}>
                <img
                  src={tr4}
                  style={{
                    height: "50px",
                  }}
                />
              </Col>
              <Col lg={6}>
                <p className="trpara2">
                  Mean Combined Risk Score
                  <br />
                  {((flood_score_average + typhoon_score_average) / 2).toFixed(
                    2
                  )}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg={12} className="firstshadow">
            <Row className="mainheading">
              <p>
                <span className={"dark-mode-theme-color-1"}>
                  Property Risk Heat Map (0 to 100)
                </span>
              </p>
            </Row>
            <div
              id="HeatMap"
              className="heatmap"
              style={{
                padding: "10px",
                color: "black",
                height: "450px",
                overflowY: "auto",
              }}
            >
              {heatmapdata ? (
                <ResponsiveHeatMap
                  data={heatmapdata}
                  hoverTarget="cell"
                  keys={["Flood Score", "Typhoon Score"]}
                  indexBy="asset"
                  margin={{ top: 80, right: 0, bottom: 100, left: 250 }}
                  valueFormat=">-.2s"
                  axisTop={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legend: "",
                    legendOffset: 46,
                  }}
                  colors={{
                    type: "diverging",
                    scheme: "yellow_orange_red",
                    divergeAt: 0.5,
                    minValue: 0,
                    maxValue: 100,
                  }}
                  emptyColor="#555555"
                  legends={[
                    {
                      anchor: "bottom",
                      translateX: 0,
                      translateY: 30,
                      length: 400,
                      thickness: 8,
                      direction: "row",
                      tickPosition: "after",
                      tickSize: 3,
                      tickSpacing: 4,
                      tickOverlap: false,
                      tickFormat: ">-.2s",
                      title: "Value →",
                      titleAlign: "start",
                      titleOffset: 4,
                    },
                  ]}
                />
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Row className="mainheading">
              <p>
                <span className={"dark-mode-theme-color-1"}>
                  Property risk Map
                </span>
              </p>
            </Row>
            {this.props.property.length > 0 ? (
              <PropertyMap data={this.props.property} />
            ) : null}
          </Col>
        </Row>

        {this.props.property.length > 0 ? (
          <>
            <Row>
              <Col lg={12} className="transition firstshadow">
                <Row className="mainheading">
                  <p>
                    <span className={"dark-mode-theme-color-1"}>
                      Flood Risk Score
                    </span>
                  </p>
                </Row>
                <Table
                  columns={columns_data_flood}
                  dataSource={this.props.property}
                  pagination={{ position: ["none", "none"] }}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={12} className="transition firstshadow">
                <Row className="mainheading">
                  <p>
                    <span className={"dark-mode-theme-color-1"}>
                      Typhoon Risk Score
                    </span>
                  </p>
                </Row>
                <Table
                  columns={columns_data_typhoon}
                  dataSource={this.props.property}
                  pagination={{ position: ["none", "none"] }}
                />
              </Col>
            </Row>
          </>
        ) : null}

        <Modal
          open={this.state.flood_score}
          onClose={this.handleClose}
          closeIcon
        >
          <Modal.Header style={{ backgroundColor: "white" }}>
            Property Risk Flood Legend
          </Modal.Header>
          <Modal.Content>
            <Row>
              <Col lg={12}>
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={{ position: ["none", "none"] }}
                />
              </Col>
            </Row>
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.typhoon_score}
          onClose={this.handleClose}
          closeIcon
        >
          <Modal.Header style={{ backgroundColor: "white" }}>
            Property Risk Typhoon Legend
          </Modal.Header>
          <Modal.Content>
            <Row>
              <Table
                columns={columns_typhoon}
                dataSource={data_typhoon}
                pagination={{ position: ["none", "none"] }}
              />
            </Row>
          </Modal.Content>
        </Modal>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    property: state.locus.property,
    isLoading: state.locus.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPropertyRisk: (formdata) => {
      dispatch(locus.getPropertyRisk(formdata));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RealEstateDashboard);
