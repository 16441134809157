import React, { useState, useEffect } from "react";
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Typography,
  Progress,
} from "antd";
import { Icon, Button } from "semantic-ui-react";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableClimateTable = (props) => {
  const [form] = Form.useForm();
  const originData = [];

  const [data, setData] = useState(originData);

  for (let i = 0; i < props.data.length; i++) {
    originData.push({
      key: i.toString(),
      name: props.data[i].name,
      latitude: props.data[i].latitude.toFixed(3),
      longitude: props.data[i].longitude.toFixed(3),
      type: props.data[i].type,
      area: props.data[i].area.toFixed(0),
      floors: props.data[i].floors,
      valuation: props.data[i].valuation,
      climate_score: props.data[i].climatic_score,
      overall_loss: props.data[i].overall_loss,
      address: `London Park no. ${i}`,
    });
  }
  useEffect(() => {
    if (props.table) {
      setData(originData.slice(0, props.table));
    }
  }, [props.table, setData, props.data]);

  console.log(
    "table data",
    data.sort((a, b) => b.climate_score - a.climate_score)
  );
  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      name: "",
      age: "",
      address: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        props.update(item, newData[index]);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        console.log("save ka dusra case");
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "25%",
      editable: true,
    },

    {
      title: "Type",
      dataIndex: "type",
      width: "10%",
      editable: false,
    },
    {
      title: "Area (sqm)",
      dataIndex: "area",
      width: "10%",
      editable: true,
    },
    {
      title: "Floors",
      dataIndex: "floors",
      width: "10%",
      editable: true,
    },
    {
      title: "Valuation (US$/sqm)",
      dataIndex: "valuation",
      width: "10%",
      editable: true,
      sorter: {
        compare: (a, b) => a.valuation - b.valuation,
        multiple: 3,
      },
    },
    {
      title: "Climate Score",
      dataIndex: "climatic_score",
      width: "10%",
      render: (text, record) => <Progress percent={record.climate_score} />,
    },
    {
      title: "Overall Loss (US$)",
      dataIndex: "overall_loss",
      width: "10%",
      editable: false,
    },
    {
      title: "Analyse",
      dataIndex: "",
      width: "10%",
      render: (_, record) => (
        <Button
          style={{ backgroundColor: "#fdb827" }}
          onClick={() => props.open(record.name)}
        >
          <Icon name="chart line" />
        </Button>
      ),
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <a
              href="javascript:;"
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
                color: "black",
              }}
            >
              Save
            </a>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
    {
      title: "Latitude",
      dataIndex: "latitude",
      width: "25%",
      editable: true,
    },
    {
      title: "Longitude",
      dataIndex: "longitude",
      width: "25%",
      editable: true,
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        className="dark-mode-table"
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
    </Form>
  );
};

export default EditableClimateTable;
