import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  ScatterChart,
  Scatter,
  Cell,
  XAxis,
  YAxis,
  CartesianContainer,
  Tooltip,
  Legend,
} from "recharts";

import {
  Header,
  Icon,
  Menu,
  Label,
  Button,
  Radio,
  Image,
  Form,
  Input,
  Modal,
  Popup,
  Select,
  Progress,
  Table,
  Checkbox,
  Accordion,
  Dropdown,
} from "semantic-ui-react";
import { project, auth, dashboard } from "../actions";
import { connect } from "react-redux";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";
import { ResponsiveScatterPlotCanvas } from "@nivo/scatterplot";
import { Row, Col, Container } from "react-bootstrap";

let data01 = [];

const basementOptions = [
  { key: "yes", value: "yes", text: "yes" },
  { key: "no", value: "no", text: "no" },
  { key: "unknown", value: "unknown", text: "unknown" },
];
const constructionOptions = [
  { key: "wood", value: "wood", text: "wood" },
  { key: "concrete", value: "concrete", text: "concrete" },
  { key: "masonry", value: "masonry", text: "masonry" },
  { key: "mobile home", value: "mobile home", text: "mobile home" },
  { key: "light metal", value: "light metal", text: "light metal" },
  { key: "steel", value: "steel", text: "steel" },
  { key: "unknown", value: "unknown", text: "unknown" },
];
const storiesOptions = [
  { key: 1, value: 1, text: 1 },
  { key: 2, value: 2, text: 2 },
  { key: "3 or More", value: "3 or more", text: "3 or more" },
  { key: "unknown", valeu: "unknown", text: "unknown" },
];
const occupancyOptions = [
  { key: "res", value: "residential", text: "Residential" },
  { key: "com", value: "commercial", text: "Commercial" },
  { key: "ind", value: "industrial", text: "Industrial" },
  { key: "unknown", value: "unknown", text: "Unknown" },
];

let ymax;
let data1 = [];
let data = [];

const per_sql_m_options = [
  { key: "0-5000", value: 5000, text: "$0-$5000" },
  { key: "5000-10000", value: 10000, text: "$5000-$10000" },
  { key: "10000-15000", value: 15000, text: "$10000-$15000" },
  { key: "15000-20000", value: 20000, text: "$15000-$20000" },
  { key: "20000-25000", value: 25000, text: "$20000-$25000" },
  { key: "25000-30000", value: 30000, text: "$25000-$30000" },
  { key: "30000-350000", value: 35000, text: "$30000-$35000" },
  { key: "35000-40000", value: 40000, text: "$35000-$40000" },
  { key: "40000-45000", value: 45000, text: "$40000-$45000" },
  { key: "45000-50000", value: 50000, text: "$45000-$50000" },
];
let data2 = [];

class Building extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    basement: "yes",
    construction: "concrete",
    stories: this.props.table[0].floors,
    occupancy: this.props.table[0].type,
    per_sq_m_value: this.props.table[0].valuation,
    line: "",
    scatter_river: "",
    scatter_storm: "",
    options: {
      chart: {
        id: "chart",
      },
      xaxis: {
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
      },
    },
    series: [
      {
        name: "series-1",
        data: [30, 40, 45, 50, 49, 60, 70, 91],
      },
    ],
  };

  componentDidMount() {
    console.log("fndfffdsfdljfjfo");
    let formdata = new FormData();
    formdata.append("project_name", this.props.project);
    formdata.append("asset_name", this.props.name);
    formdata.append("year", this.props.year);
    formdata.append("analysis_type", this.props.analysis_type);
    formdata.append("basement", this.state.basement);
    formdata.append("construction", this.state.construction);
    formdata.append("stories", this.props.table[0].floors);
    formdata.append("occupancy", this.props.table[0].type);
    formdata.append("per_sq_m_value", this.props.table[0].valuation);
    this.props.getBuilding(formdata);
  }
  handleChange = (value, key) => {
    this.setState({ [key]: value }, this.handleSubmit);
  };

  handleSubmit = () => {
    let formdata = new FormData();
    formdata.append("project_name", this.props.project);
    formdata.append("asset_name", this.props.name);
    formdata.append("year", this.props.year);
    formdata.append("analysis_type", this.props.analysis_type);
    formdata.append("basement", this.state.basement);
    formdata.append("construction", this.state.construction);
    formdata.append("stories", this.state.stories);
    formdata.append("occupancy", this.state.occupancy);
    formdata.append("per_sq_m_value", this.state.per_sq_m_value);
    this.props.getBuilding(formdata);
  };
  render() {
    const isDarkMode = localStorage.getItem("theme") == "dark";
    const {
      value,
      basement,
      construction,
      stories,
      occupancy,
      per_sq_m_value,
    } = this.state;

    if (this.props.building["river_flood"]) {
      this.state.scatter_river = this.props.building["river_flood"];
      this.state.scatter_storm = this.props.building["storm_surge"];
      console.log("building", this.state.scatter_river[26][0]);

      if (this.props.var.includes("Rainfall Flood")) {
        data2 = [
          {
            id: "2.6",
            data: [
              {
                x: this.props.building["rainfall_flood"][26][0],
                y: this.props.building["rainfall_flood"][26][1],
              },
            ],
          },
          {
            id: "4.5",
            data: [
              {
                x: this.props.building["rainfall_flood"][45][0],
                y: this.props.building["rainfall_flood"][45][1],
              },
            ],
          },

          {
            id: "8.5",
            data: [
              {
                x: this.props.building["rainfall_flood"][85][0],
                y: this.props.building["rainfall_flood"][85][1],
              },
            ],
          },
        ];
      }
      data = [
        {
          id: "2.6",
          data: [
            {
              x: this.state.scatter_river[26][0],
              y: this.state.scatter_river[26][1],
            },
          ],
        },
        {
          id: "4.5",
          data: [
            {
              x: this.state.scatter_river[45][0],
              y: this.state.scatter_river[45][1],
            },
          ],
        },

        {
          id: "8.5",
          data: [
            {
              x: this.state.scatter_river[85][0],
              y: this.state.scatter_river[85][1],
            },
          ],
        },
      ];
      data1 = [
        {
          id: "2.6",
          data: [
            {
              x: this.state.scatter_storm[26][0],
              y: this.state.scatter_storm[26][1],
            },
          ],
        },
        {
          id: "4.5",
          data: [
            {
              x: this.state.scatter_storm[45][0],
              y: this.state.scatter_storm[45][1],
            },
          ],
        },

        {
          id: "8.5",
          data: [
            {
              x: this.state.scatter_storm[85][0],
              y: this.state.scatter_storm[85][1],
            },
          ],
        },
      ];
      const colors = scaleOrdinal(schemeCategory10).range();
      ymax = data[2].data[0].y;
    }

    return (
      <Container fluid={true}>
        <Row>
          <Col>
            <Form.Select
              placeholder="Basement"
              width="equal"
              onChange={(e, { value }) => this.handleChange(value, "basement")}
              value={basement}
              label="Basement"
              options={basementOptions}
            />
          </Col>

          <Col>
            <Form.Select
              placeholder="Construction"
              onChange={(e, { value }) =>
                this.handleChange(value, "construction")
              }
              value={construction}
              label="Construction"
              options={constructionOptions}
            />
          </Col>

          <Col>
            <Form.Select
              placeholder="Stories"
              onChange={(e, { value }) => this.handleChange(value, "stories")}
              value={stories}
              label="Stories"
              options={storiesOptions}
            />
          </Col>

          <Col>
            <Form.Select
              placeholder="Occupancy"
              onChange={(e, { value }) => this.handleChange(value, "occupancy")}
              value={occupancy}
              label="Occupancy"
              options={occupancyOptions}
            />
          </Col>

          <Col>
            <Form.Select
              placeholder="per_sq_m"
              onChange={(e, { value }) =>
                this.handleChange(value, "per_sq_m_value")
              }
              value={per_sq_m_value}
              label="Cost per Square meter"
              options={per_sql_m_options}
            />
          </Col>
        </Row>
        <Row>
          <Col
            lg={6}
            sm={12}
            xs={12}
            className="card dark-mode-black"
            style={{ height: "500px" }}
          >
            <p>
              <span className={"dark-mode-theme-color-1"}>
                Analysis of River Flood Damage
              </span>
            </p>

            <ResponsiveScatterPlotCanvas
              data={data}
              margin={{ top: 40, right: 140, bottom: 80, left: 90 }}
              xScale={{ type: "linear", min: 0, max: "auto" }}
              xFormat={function (e) {
                return e.toFixed(2) + "";
              }}
              yScale={{ type: "linear", min: 0, max: 1.3 * ymax }}
              yFormat={function (e) {
                return "$" + e.toFixed(2);
              }}
              colors={{ scheme: "category10" }}
              nodeSize={15}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: "bottom",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Depth",
                legendPosition: "middle",
                legendOffset: 46,
              }}
              axisLeft={{
                orient: "left",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Loss in US $ Million",
                legendPosition: "middle",
                legendOffset: -60,
              }}
              legends={[
                {
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 130,
                  translateY: 0,
                  itemWidth: 100,
                  itemHeight: 12,
                  itemsSpacing: 5,
                  itemDirection: "left-to-right",
                  symbolSize: 12,
                  symbolShape: "rect",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              theme={{
                textColor: isDarkMode ? "#ffffff" : "#3a3a3a",
              }}
            />

            {/*                                                   <ScatterChart
                                                  width={400}
                                                  height={400}
                                                  margin={{
                                                    top: 20, right: 20, bottom: 20, left: 20,
                                                  }}
                                                >
                                                  <CartesianContainer />
                                                  <XAxis type="number" dataKey="x" name="Depth" value="Depth"/>
                                                  <YAxis type="number" dataKey="y" name="Loss in Million $" value="Loss in Million $" unit="mn $" />
                                                  <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                                                  
                                                  <Scatter name="" data={data} fill="#8884d8">
                                                   
                                                   {
            data.map((entry, index) => <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />)
          }
                                                  </Scatter>
                                                  
                                                  
                                                </ScatterChart>*/}
          </Col>

          <Col
            lg={6}
            xs={12}
            sm={12}
            className="card dark-mode-black"
            style={{ height: "500px" }}
          >
            <p>
              <span className={"dark-mode-theme-color-1"}>
                Analysis of Storm Damage
              </span>
            </p>
            {/* <ScatterChart
        width={400}
        height={400}
        margin={{
          top: 20, right: 20, bottom: 20, left: 20,
        }}
      >
        <CartesianContainer />
        <XAxis type="number" dataKey="x" name="Depth" value="Depth"/>
        <YAxis type="number" dataKey="y" name="Loss in Million $" value="Loss in Million $" unit="mn $" />
        <Tooltip cursor={{ strokeDasharray: '3 3' }} />
        <Scatter name="RCP" data={data1} fill="#8884d8">
          
          {
            data1.map((entry, index) => <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />)
          }
        </Scatter>
      </ScatterChart>*/}
            {data1.length > 0 ? (
              <ResponsiveScatterPlotCanvas
                data={data1}
                margin={{ top: 40, right: 140, bottom: 80, left: 90 }}
                xScale={{ type: "linear", min: 0, max: "auto" }}
                xFormat={function (e) {
                  return e + "";
                }}
                yScale={{ type: "linear", min: 0, max: "auto" }}
                yFormat={function (e) {
                  return e + "$";
                }}
                colors={{ scheme: "category10" }}
                nodeSize={15}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  orient: "bottom",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Depth",
                  legendPosition: "middle",
                  legendOffset: 46,
                }}
                axisLeft={{
                  orient: "left",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Loss in US $ Million",
                  legendPosition: "middle",
                  legendOffset: -60,
                }}
                legends={[
                  {
                    anchor: "bottom-right",
                    direction: "column",
                    justify: false,
                    translateX: 130,
                    translateY: 0,
                    itemWidth: 100,
                    itemHeight: 12,
                    itemsSpacing: 5,
                    itemDirection: "left-to-right",
                    symbolSize: 12,
                    symbolShape: "rect",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
                theme={{
                  textColor: isDarkMode ? "#ffffff" : "#3a3a3a",
                }}
              />
            ) : null}
          </Col>
          <Col width="1"></Col>
        </Row>
        {this.props.var.includes("Rainfall Flood") ? (
          <Row>
            <Col lg={6} xs={12} sm={12} style={{ height: "500px" }}>
              <p>
                <span className={"dark-mode-theme-color-1"}>
                  Analysis of Rainfall Flood Damage
                </span>
              </p>
              {data2.length > 0 ? (
                <ResponsiveScatterPlotCanvas
                  data={data2}
                  margin={{ top: 40, right: 140, bottom: 80, left: 90 }}
                  xScale={{ type: "linear", min: 0, max: "auto" }}
                  xFormat={function (e) {
                    return e + "";
                  }}
                  yScale={{ type: "linear", min: 0, max: "auto" }}
                  yFormat={function (e) {
                    return e + "$";
                  }}
                  colors={{ scheme: "category10" }}
                  nodeSize={15}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    orient: "bottom",
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Depth",
                    legendPosition: "middle",
                    legendOffset: 46,
                  }}
                  axisLeft={{
                    orient: "left",
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Loss in US $ Million",
                    legendPosition: "middle",
                    legendOffset: -60,
                  }}
                  legends={[
                    {
                      anchor: "bottom-right",
                      direction: "column",
                      justify: false,
                      translateX: 130,
                      translateY: 0,
                      itemWidth: 100,
                      itemHeight: 12,
                      itemsSpacing: 5,
                      itemDirection: "left-to-right",
                      symbolSize: 12,
                      symbolShape: "rect",
                      effects: [
                        {
                          on: "hover",
                          style: {
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]}
                  theme={{
                    textColor: isDarkMode ? "#ffffff" : "#3a3a3a",
                  }}
                />
              ) : null}
            </Col>
          </Row>
        ) : null}
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    errors: state.project.errors,
    building: state.feedback.building,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getBuilding: (formdata) => {
      dispatch(dashboard.getBuilding(formdata));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Building);
