class ActionProvider {
  constructor(createChatBotMessage, setStateFunc) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
  }

  async handleJavascriptList(keyword) {
    try {
      let headers = {};

      let token = localStorage.getItem("token");
      if (token) {
        headers["Authorization"] = `Token ${token}`;
        headers["Content-Type"] = "application/json";
      }
      const requestOptions = {
        method: "POST",
        headers,
        body: JSON.stringify({ keywords: keyword }),
      };

      const response = await fetch(
        "https://devapi.intensel.net/api/v2/keyword_help/",
        requestOptions
      );
      const data = await response.json();

      // Create a message for each item in the data
      const messages = data.map((item) =>
        this.createChatBotMessage(`- ${item.title}`)
      );

      // Update the chatbot state with the messages
      this.updateChatbotState(messages);
    } catch (error) {
      console.error("Error fetching Javascript resources:", error);
    }
  }

  updateChatbotState(messages) {
    // Update the chatbot state with the array of messages
    this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, ...messages],
    }));
  }
}

export default ActionProvider;
