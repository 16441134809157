import React, { useContext, useState, useEffect, useRef } from "react";
import { Table, Input, Button, Popconfirm, Form, Select } from "antd";
import { Icon } from "semantic-ui-react";
import "./transitiontable.css";
const EditableContext = React.createContext(null);

const { Option } = Select;
let countryoptions = [];
let sectoroptions = [];
let sector = [
  "Automobiles & Auto Parts",
  "Banking Services",
  "Beverages",
  "Chemicals",
  "Coal",
  "Communications & Networking",
  "Electric Utilities & IPPs",
  "Electronic Equipments & Parts",
  "Food & Drug Retailing",
  "Food & Tobacco",
  "Freight & Logistics Services",
  "Healthcare Equipment & Supplies",
  "Homebuilding & Construction Supplies",
  "Industrial Conglomerates",
  "Insurance",
  "Investment Banking & Investment Services",
  "Leisure Products",
  "Media & Publishing",
  "Metals & Mining",
  "Natural Gas Utilities",
  "Oil & Gas",
  "Professional & Commercial Services",
  "Real Estate Operations",
  "Renewable Energy",
  "Software & IT Services",
  "Telecommunications Services",
  "Textiles & Apparel",
  "Transport Infrastructure",
  "Water Utilities",
];
let country = [
  "North America",
  "Japan",
  "Europe",
  "China",
  "Southern Asia",
  "East Asia",
  "South East Asia",
  "South America",
  "Africa",
  "Middle East",
  "Australia/NZ",
  "Western Asia",
];

for (let i = 0; i < country.length; i++) {
  countryoptions.push(<Option value={country[i]}>{country[i]}</Option>);
}
for (let i = 0; i < sector.length; i++) {
  sectoroptions.push(<Option value={sector[i]}>{sector[i]}</Option>);
}

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
function onSearch(val) {
  console.log(val);
}

const EditableCell = ({
  title,
  editable,
  inputType,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;
  const inputNode =
    inputType === "select_country" ? (
      <Select
        ref={inputRef}
        showSearch
        onSearch={onSearch}
        onPressEnter={save}
        onBlur={save}
      >
        {countryoptions}
      </Select>
    ) : inputType === "select_sector" ? (
      <Select
        ref={inputRef}
        showSearch
        onSearch={onSearch}
        onPressEnter={save}
        onBlur={save}
      >
        {sectoroptions}
      </Select>
    ) : (
      <Input ref={inputRef} onPressEnter={save} onBlur={save} />
    );

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        {inputNode}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

class TransitionTable extends React.Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: "Company Name",
        dataIndex: "company_name",
        editable: true,
      },
      {
        title: "Investment (in USD)",
        dataIndex: "investment_in_usd",

        editable: true,
      },
      {
        title: "NAV % (Note 1)",
        dataIndex: "nav_in_perc",
        editable: true,
      },
      {
        title: "Market Cap (in USD)",
        dataIndex: "market_cap_in_usd",
        editable: true,
      },
      {
        title: "Revenue (in USD)",
        dataIndex: "revenue_in_usd",
        editable: true,
      },
      {
        title: "Scope 1 and 2 GHG emission (in t CO2e)",
        dataIndex: "scope_1_and_2_GHG_emission",
        editable: true,
      },
      {
        title: "Region",
        dataIndex: "region",
        editable: true,
      },
      {
        title: "Sector",
        dataIndex: "sector",
        editable: true,
      },

      {
        title: "",
        dataIndex: "operation",
        render: (_, record) =>
          this.state.dataSource.length >= 1 ? (
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => this.handleDelete(record.key)}
            >
              <Icon name="trash alternate" color="red" />
            </Popconfirm>
          ) : null,
      },
    ];
    this.state = {
      dataSource: [
        {
          company_name: "Company",
          key: 0,
          investment_in_usd: "0",
          market_cap_in_usd: "0",
          revenue_in_usd: "0",
          scope_1_and_2_GHG_emission: "0",
          region: "China",
          sector: "Coal",
          nav_in_perc: "0",
        },
      ],
      count: 1,
    };
  }

  handleRisk = (data) => {
    console.log("data", data);
    this.props.handleRisk(data);
  };
  handleDelete = (key) => {
    const dataSource = [...this.state.dataSource];
    this.setState({
      dataSource: dataSource.filter((item) => item.key !== key),
    });
  };
  handleAdd = () => {
    const { count, dataSource } = this.state;
    const newData = {
      key: count,
      company_name: "Company",
      investment_in_usd: "0",
      market_cap_in_usd: "0",
      revenue_in_usd: "0",
      scope_1_and_2_GHG_emission: "0",
      region: "China",
      sector: "Coal",
      nav_in_perc: "0",
    };
    this.setState({
      dataSource: [...dataSource, newData],
      count: count + 1,
    });
  };
  handleSave = (row) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    this.setState({
      dataSource: newData,
    });
  };

  render() {
    const { dataSource } = this.state;
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          inputType:
            col.dataIndex === "region"
              ? "select_country"
              : "text" && col.dataIndex === "sector"
              ? "select_sector"
              : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });
    return (
      <div>
        <Button
          className="rowbtn"
          onClick={this.handleAdd}
          type="primary"
          style={{
            marginBottom: 16,
          }}
        >
          Add a row
        </Button>
        <Table
          components={components}
          rowClassName={() => "editable-row"}
          bordered
          dataSource={dataSource}
          columns={columns}
        />

        <Button
          disabled={
            !(
              this.props.portfolio_value &&
              this.props.portfolio_value.length > 0
            )
          }
          style={{ float: "right", backgroundColor: "#002060", color: "white" }}
          onClick={() => this.handleRisk(this.state.dataSource)}
        >
          Submit
        </Button>
      </div>
    );
  }
}

export default TransitionTable;
