import React from "react";
import { Dimmer, Loader, Image, Segment } from "semantic-ui-react";
import { Row, Col } from "react-bootstrap";
import lock from "../assets/lock.jpg";
const Tier = () => (
  <>
    <Row>
      <Image
        src={lock}
        style={{ width: "100px", marginLeft: "auto", marginRight: "auto" }}
      />
    </Row>
    <Row>
      <Col lg={5}></Col>
      <Col lg={5}>
        {" "}
        <h3>Please upgrade tier to see content</h3>
      </Col>
    </Row>
  </>
);

export default Tier;
