const initialState = {
  asset: [],
  typhoon: [],
  errors: {},
  isLoading: true,
};

export default function asset(state = initialState, action) {
  switch (action.type) {
    case "ASSET_LOADING":
      return { ...state, isLoading: true };
    case "FETCH_ASSET":
      return { ...state, asset: action.asset };
    case "TYPHOON_TRACK":
      return { ...state, typhoon: action.typhoon };
    case "DELETE_ASSET":
      return { ...state, asset: action.asset };
    case "UPDATE_ASSET":
      return { ...state, asset: action.asset, isLoading: false };
    case "AUTHENTICATION_ERROR":
    case "ASSET_FAIL":
    case "TYPHOON_FAIL":
      return { ...state, errors: action.data, asset: null, isLoading: false };
    default:
      return state;
  }
}
