import React,{Component} from 'react';
import Chart from 'react-google-charts';
import  {ResponsiveLine} from '@nivo/line';
import {VictoryChart ,VictoryBoxPlot,VictoryLabel,VictoryContainer,VictoryAxis,VictoryLegend} from 'victory';
import {Grid} from 'semantic-ui-react';
import {Row} from 'react-bootstrap';
let data=[];
let high=0;
let array=[];
let n =0;
export default class CandleStick extends Component{
    constructor(props) {
          super(props);
          }
        
        render(){
          const isDarkMode = localStorage.getItem("theme") == "dark"
          data=[];
            
            if(this.props.data){
                n=this.props.data.length
                for(let i=0;i<this.props.data.length;i++){
                data.push({
                    x:this.props.data[i][0].slice(0,30),
                   
                    q1:(this.props.data[i][3]).toFixed(2),
                    min:(this.props.data[i][3]).toFixed(2),
               
                    median:(this.props.data[i][2]).toFixed(2),
                    max:(this.props.data[i][1]).toFixed(2),
                    q3:(this.props.data[i][1]).toFixed(2)
                   
              
              
              
              
                })
            array.push(this.props.data[i][1])
            high=Math.max(...array);
            }
        }
        
        
            return( 
            <div>
              <Row className="mainheading" style={{width:n*300,minWidth:'100%'}}><p><span className={"dark-mode-theme-color-1"}> Climate spread (% loss  adjusted by 1:100 return period)</span></p></Row>
                    <VictoryChart  domainPadding={{x:80}} height={400} width={n*300} 
                    domain={{y:[0,high+2]}}
                    theme = {{
                        axis: {
                          style: {
                            tickLabels: {
                              fill: isDarkMode ? "#ffffff" : "#3a3a3a",
                            },
                            grid: {
                                stroke: isDarkMode ? "" : "",
                            },
                            axis: {
                                stroke: isDarkMode ? "#ffffff" : "#3a3a3a",
                            },
                            axisLabel:{
                              fill: isDarkMode ? "#ffffff" : "#3a3a3a",
                            }
                          },
                        },
                    }}
              containerComponent={<VictoryContainer responsive={false}/>}
              >
  
              <VictoryAxis label="Assets" style={{
                tickLabels: {fontSize: 12, padding: 10},
                axisLabel:{padding:30}
              }}/>
              <VictoryAxis dependentAxis label="% Loss in basis point" style={{
                tickLabels: {fontSize: 15, padding: 10}
              }}/>
              

                        <VictoryBoxPlot
                  padding={60}
                    minLabels
                    maxLabels
                    medianLabels
                    minLabelComponent={<VictoryLabel dy={0}/>}
                    standalone={false}
                    maxLabelComponent={<VictoryLabel dy={-30}/>}
                    medianLabelComponent={<VictoryLabel dy={-15}/>}
                    boxWidth={20}
                    whiskerWidth={5}
                    
                            
                              data={data}
                     style={{
    labels:{padding:10},         
    min: { stroke: "#73a2ff",fontSize:9 },
    max: { stroke: "#002b82",fontSize:9 },
    q1: { fill: "#73a2ff" },
    q3: { fill: "#002b82" },
    median: { stroke: "#ffff00", strokeWidth:2,fontSize:9 },
    minLabels: { fill: "#73a2ff", },
    maxLabels: { fill: "#002b82" },
    medianLabels:{fill: isDarkMode ? "#ffff00" : "#000000"}
  }}
                          />
              
                    </VictoryChart>
               <Grid.Row className="spread" style={{marginLeft:'38%'}}>

      <p style={{fontSize:'12px',display:'-webkit-box'}}> <div class="box" style={{width:'10px',height:'10px',backgroundColor:'#73a2ff'}}></div> RCP 2.6
      <div class="box" style={{width:'10px',height:'10px',backgroundColor:'yellow',border:'1px solid black',marginLeft:'2px'}}></div> RCP 4.5
      
      
     <div class="box" style={{width:'10px',height:'10px',backgroundColor:'#002b82',display:'flex',marginLeft:'2px'}}></div>RCP 8.5</p>
      
      </Grid.Row>
                </div>
            )
        }
}
