import React, { useState, useEffect } from "react";
import { geoMercator, geoPath } from "d3-geo";
import { feature } from "topojson-client";

const geoUrl =
  "https://intensel-tiles.s3.amazonaws.com/company_dash_maps/countries-filtered-topojson.json";
const dataUrl =
  "https://intensel-tiles.s3.amazonaws.com/company_dash_maps/cvar_by_countries_rcp.json";

const CompanyMap = () => {
  const projection = geoMercator()
    .scale(160)
    .translate([800 / 2, 450 / 2]);

  // State to store geography data
  const [geographies, setGeographies] = useState([]);
  const [data, setData] = useState([]);
  // State to store hovered country
  const [currentValue, setCurrentValue] = useState(null);
  // State to store hovered index
  const [hoveredCountryIndex, setHoveredCountryIndex] = useState(null);

  useEffect(() => {
    fetch(geoUrl).then((response) => {
      if (response.status !== 200) {
        console.log(`There was a problem: ${response.status}`);
        return;
      }
      response.json().then((worlddata) => {
        setGeographies(
          feature(worlddata, worlddata.objects["countries-filtered"]).features
        );
      });
    });

    fetch(dataUrl).then((response) => {
      if (response.status !== 200) {
        console.log(`There was a problem: ${response.status}`);
        return;
      }
      response.json().then((data) => {
        console.log("data", data);
        setData(data);
      });
    });
  }, []);
  const handleCountryClick = (countryIndex) => {
    console.log("Clicked on country: ", geographies[countryIndex]);
  };

  // get color depending on value 0-7
  const getColor = (value) => {
    switch (true) {
      case value === 0:
        return "#ffffff";
      case value > 0 && value <= 2.5:
        return "#ffbf00";
      case value > 2.5 && value <= 5:
        return "#ff7f00";
      case value > 5 && value <= 7.5:
        return "#ff3f00";
      case value > 7.5 && value <= 10:
        return "#ff0000";
      default:
        return "#ffffff";
    }
  };

  return (
    <div className="text-center">
      <svg
        className="w-auto h-auto max-w-6xl mx-auto mt-5 shadow-md rounded-xl"
        viewBox="0 0 850 450"
      >
        <g className="countries">
          {
            // draw each country
            geographies.map((d, i) => (
              <path
                key={`path-${i}`}
                d={geoPath().projection(projection)(d)}
                className="country"
                // Color each country based on the data
                // fill={`rgba(38,50,56,${1 / geographies.length * i})`}
                // fill={i === hoveredCountryIndex ? "#F53F2B" : getColor((d as Feature).properties.non_zero_mean)}
                fill={
                  i === hoveredCountryIndex
                    ? "#F53F2B"
                    : getColor(
                        d.properties.ISO_A3 in data
                          ? data[d.properties.ISO_A3]["Cvar in %"]
                          : 0
                      )
                }
                // Show a stroke on hover
                stroke={i === hoveredCountryIndex ? "#FFFFFF" : "#000000"}
                // Change the stroke width on hover
                strokeWidth={i === hoveredCountryIndex ? 1 : 0.1}
                onClick={() => handleCountryClick(i)}
                onMouseEnter={() => {
                  const { NAME_EN, ISO_A3 } = d.properties;
                  setCurrentValue({
                    name: NAME_EN,
                    value: ISO_A3 in data ? data[ISO_A3]["Cvar in %"] : 0,
                  });
                  setHoveredCountryIndex(i);
                }}
                onMouseLeave={() => {
                  setCurrentValue(null);
                  setHoveredCountryIndex(null);
                }}
              >
                {/* Tooltip */}
                {/* <title>{(d as Feature).properties.non_zero_mean.toFixed(2)}</title> */}
              </path>
            ))
          }
        </g>
      </svg>
      {/* Floating Info Tab */}
      <div className="absolute bottom-2 right-3">
        <div className="p-4 bg-white rounded-lg shadow-lg">
          <div className="flex flex-row items-center justify-center">
            <div className="flex flex-col">
              {currentValue ? (
                <>
                  <div className="text-sm font-medium text-gray-600">
                    Country
                  </div>
                  <div className="text-lg font-bold text-gray-900">
                    {currentValue.name} : {currentValue.value}
                  </div>
                </>
              ) : (
                <div className="text-lg font-bold text-gray-900">
                  Hover over a country
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyMap;
