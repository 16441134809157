import React, { useState, useCallback, useEffect } from "react";
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Typography,
  Progress,
  Select,
} from "antd";
import { Icon, Button } from "semantic-ui-react";

const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "select" ? (
      <Select onChange={handleChange}>
        <Option value="Apartments">Apartments</Option>
        <Option value="Commercial">Commercial</Option>
        <Option value="education">Education</Option>
        <Option value="energy_other">Energy (Other)</Option>

        <Option value="energy_power_plant_coal_oil_gas_thermal">
          Energy Power Plant (Coal, Oil, Gas, Thermal)
        </Option>
        <Option value="energy_substation">Energy Substation</Option>
        <Option value="energy_wind_turbine">Energy (Wind Turbine)</Option>
        <Option value="Government">Government</Option>
        <Option value="healthcare">Healthcare</Option>
        <Option value="Home">Home</Option>
        <Option value="Industrial">Industrial</Option>
        <Option value="Manufacture">Manufacturing</Option>
        <Option value="Parking">Parking</Option>

        <Option value="railway_station">Railway Station</Option>

        <Option value="Residential">Residential</Option>

        <Option value="School">School</Option>
        <Option value="telecommunication_tower">Telecommunication Tower</Option>
        <Option value="water_control_vault_station">
          Water Control Vault Station
        </Option>

        <Option value="water_pumping_plant_above_ground">
          Water Pumping Plant (Above Ground)
        </Option>
        <Option value="water_pumping_plant_below_ground">
          Water Pumping Plant (Below Ground)
        </Option>
        <Option value="water_storage_tank_above_ground">
          Water Storage Tank (Above Ground)
        </Option>
        <Option value="water_storage_tank_at_ground">
          Water Storage Tank (At Ground)
        </Option>
        <Option value="water_storage_tank_below_ground">
          Water Storage Tank (Below Ground)
        </Option>
        <Option value="water_treatment_plant_closed_pressure">
          Water Treatment Plant (Closed Pressure)
        </Option>
        <Option value="water_treatment_plant_open_gravity">
          Water Treatment Plant (Open Gravity)
        </Option>
        <Option value="water_well">Water Well</Option>
        <Option value="wastewater_control_vault_station">
          Wastewater Control Vault Station
        </Option>
        <Option value="wastewater_lift_station_submersible">
          Wastewater Lift Station (Submersible)
        </Option>
        <Option value="wastewater_lift_station_wet_well_dry_well">
          Wastewater Lift Station (Wet Well, Dry Well)
        </Option>

        <Option value="wastewater_treatment_plant">
          Wastewater Treatment Plant
        </Option>
      </Select>
    ) : inputType === "number" ? (
      <InputNumber />
    ) : inputType === "select_mitigation" ? (
      <Select onChange={handleChange}>
        <Option value="0">No</Option>
        <Option value="1">Low</Option>
        <Option value="2">Medium</Option>
        <Option value="3">High</Option>
      </Select>
    ) : (
      <Input />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableTable = (props) => {
  const [form] = Form.useForm();
  const originData = [];

  const [data, setData] = useState(originData);

  for (let i = 0; i < props.data.length; i++) {
    originData.push({
      key: i.toString(),
      name: props.data[i].name,
      revenue: props.data[i].revenue.toFixed(2),
      construction_cost: props.data[i].construction_cost.toFixed(2),
      type: props.data[i].type,
      percentage: props.data[i].tip_in_perc.toFixed(2),
      area: props.data[i].area.toFixed(0),
      ownership: props.data[i].ownership,
      floors: props.data[i].floors,
      valuation: props.data[i].valuation,
      mitigation_risk: props.data[i].mitigation_risk,
      climate_score: props.data[i].climatic_score.toFixed(0) + "%",
      overall_loss: props.data[i].overall_loss,
      address: `London Park no. ${i}`,
    });
  }
  useEffect(() => {
    if (props.data) {
      setData(originData);
    }
  }, [setData, props.data]);

  console.log("table data", props.permission);

  const [editingKey, setEditingKey] = useState("");
  const [sortedInfo, setsortedInfo] = useState({});
  console.log("info", sortedInfo);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      name: "",
      age: "",
      address: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        console.log("new", newData[index]);
        props.update(item, newData[index]);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        console.log("save ka dusra case");
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
      editable: true,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "15%",
      editable: props.permission.can_edit_property_type,
    },
    {
      title: "Area (sqm)",
      key: "area",
      dataIndex: "area",
      width: "10%",
      editable: props.permission.can_edit_area,
    },
    {
      title: "Floors",
      key: "floors",
      dataIndex: "floors",
      width: "10%",
      editable: props.permission.can_edit_stories,
    },
    {
      title: "Valuation(in US$/sqm)",
      dataIndex: "valuation",
      key: "valuation",

      sorter: {
        compare: (a, b) => parseFloat(a.valuation) - parseFloat(b.valuation),
        multiple: 3,
      },
      sortOrder: sortedInfo.columnKey === "valuation" && sortedInfo.order,

      width: "10%",

      editable: props.permission.can_edit_valuation,
    },
    {
      title: () => {
        return (
          <span>
            Flood Risk Reduction Level{" "}
            <Icon name="info" onClick={() => props.info()} />
          </span>
        );
      },
      key: "mitigation_risk",
      dataIndex: "mitigation_risk",
      width: "10%",
      editable: true,
      /* render: (_, record) => (
        <Button
          style={{
            boxShadow: "10px 10px 10px #d1d9e6,-10px -10px 10px #f9f9f9",
          }}
          onClick={() => props.open(record.name)}
        >
          Add Details
        </Button>
      ),*/
    },

    {
      title: "Climate Score",
      dataIndex: "climate_score",
      key: "climate_score",

      width: "10%",
      sorter: {
        compare: (a, b) =>
          parseFloat(a.climate_score) - parseFloat(b.climate_score),
        multiple: 3,
      },
      sortOrder: sortedInfo.columnKey === "climate_score" && sortedInfo.order,
    },
    {
      /* {
      title: "TIP(in %)",
      dataIndex: "percentage",
      key: "risk",
      width: "10%",
      editable: false,
    },*/
    },
    {
      title: "CVaR (mil US$)",
      key: "overall_loss",
      dataIndex: "overall_loss",
      sorter: {
        compare: (a, b) => a.overall_loss - b.overall_loss,
        multiple: 3,
      },
      sortOrder: sortedInfo.columnKey === "overall_loss" && sortedInfo.order,

      width: "10%",
      editable: false,
    },
    {
      title: "Ownership (0-100)",
      dataIndex: "ownership",
      key: "ownership",
      width: "10%",
      editable: props.permission.can_edit_ownership,
    },

    {
      title: "Revenue (in mil US$/year)",
      dataIndex: "revenue",
      key: "revenue",

      sorter: {
        compare: (a, b) => a.revenue - b.revenue,
        multiple: 3,
      },
      sortOrder: sortedInfo.columnKey === "revenue" && sortedInfo.order,

      width: "10%",
      editable: props.permission.can_edit_revenue,
    },
    {
      title: "Replacement Value (in US$/sqm)",
      key: "construction_cost",

      dataIndex: "construction_cost",
      sorter: {
        compare: (a, b) => a.construction_cost - b.construction_cost,
        multiple: 3,
      },
      sortOrder:
        sortedInfo.columnKey === "construction_cost" && sortedInfo.order,

      width: "10%",
      editable: props.permission.can_edit_construction_cost,
    },
    {
      title: "Analyse",
      dataIndex: "",
      width: "10%",
      render: (_, record) => (
        <Button
          style={{
            boxShadow: "10px 10px 10px #d1d9e6,-10px -10px 10px #f9f9f9",
          }}
          onClick={() => props.open(record.name)}
        >
          <Icon name="chart line" />
        </Button>
      ),
    },
    {
      title: "Action",
      width: "10%",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <a
              href="javascript:;"
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
                color: "black",
              }}
            >
              Save
            </a>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];
  const onChange = (pagination, filters, sorter) => {
    setsortedInfo(sorter);
    console.log("sorted", sortedInfo, sorter);
  };
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === "type"
            ? "select"
            : col.dataIndex === "mitigation_risk"
            ? "select_mitigation"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        className="dark-mode-table"
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        sticky={true}
        bordered
        dataSource={data}
        onChange={onChange}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
    </Form>
  );
};

export default EditableTable;
