import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PrivateRoute from "../private-route";
import Spinner from "./loader";
import Location from "./location";
import Project from "./project";
import Dashboard from "./dashboard";
import Download from "./download";
//import HazardDashboard from "./hazarddash";
import Navigation from "./navmenu";
//import Export from "./report";

import { auth } from "../actions/";
//import Invoice from "./invoice";
//import TCFD from "./tcfd";
//import DetailedReport from "./deatiledreport";
//import Application from "./pivottable";
import SideNavbar from "./oldsidebar";
import Methodology from "./methodology";
import TransitionRisk from "./transitionrisk";
import CompanyDashboard from "./company_dashboard";

import Faqs from "./faqs";
import APIDocs from "./apidocs";
import TransitionAnalysis from "./transition_analysis";
import EditTransition from "./edit_transition";
import Realestate from "./realestate";
import RealEstateDashboard from "./realestate_dashboard";
import TopNavbar from "./navmenu";
import Landingpage from "./landingpage";
import Toolkit from "./adaptation_tookit";
import ChangelogPage from "./changelog";
import ChatbotPopup from "./chatbot_popup";

class AppLayout extends Component {
  constructor() {
    super();
  }

  componentDidMount() {
    // Add event listener for back button click
    window.addEventListener("popstate", this.handleBackButton);
  }

  componentWillUnmount() {
    // Remove event listener when component unmounts
    window.removeEventListener("popstate", this.handleBackButton);
  }

  handleBackButton = () => {
    // Handle back button click if needed
    // For example, you can prevent default behavior or perform custom actions
  };
  render() {
    const { containerClassnames } = this.props;

    /*if (auth.isUserAuthenticated()) {
      if (localStorage.getItem("theme") == "dark") {
        import("../css/sass/themes/gogo.light.purple.scss").then(() => {});
      } else {
        import("../css/sass/themes/gogo.light.purple.scss").then(() => {});
      }
    }*/
    return (
      <div>
        {/* <ChatbotPopup />*/}
        {/* <ChatBot steps={steps} />*/}
        {/*<SideNavbar />*/}
        <TopNavbar />
        <div id="container-fluid">
          <PrivateRoute path="/landing" component={Landingpage} />
          <PrivateRoute path="/location" component={Location} />
          <PrivateRoute path="/realestate" component={Realestate} />
          <PrivateRoute path="/project" component={Project} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/transition" component={TransitionRisk} />
          <PrivateRoute path="/toolkit" component={Toolkit} />
          <PrivateRoute path="/changelog" component={ChangelogPage} />
          <PrivateRoute
            path="/realestate-dashboard"
            component={RealEstateDashboard}
          />
          <PrivateRoute
            path="/company-dashboard"
            component={CompanyDashboard}
          />
          <PrivateRoute path="/download" component={Download} />
          <PrivateRoute path="/spinner" component={Spinner} />
          {/*<PrivateRoute path="/report" component={Export} />*/}
          {/* <PrivateRoute path="/hazard" component={HazardDashboard} />*/}
          {/*<PrivateRoute path="/invoice" component={Invoice} />*/}
          {/*<PrivateRoute path="/tcfd" component={TCFD} />*/}
          {/*<PrivateRoute path="/detailreport" component={DetailedReport} />*/}
          <PrivateRoute path="/methodology" component={Methodology} />
          {/*<PrivateRoute path="/pivottable" component={Application} />*/}
          <PrivateRoute path="/faqs" component={Faqs} />
          <PrivateRoute path="/apidocs" component={APIDocs} />
          {/* <PrivateRoute path="/create-transition" component={TransitionRisk} />*/}
          <PrivateRoute
            path="/transition-analysis"
            component={TransitionAnalysis}
          />
          <PrivateRoute path="/edit-transition" component={EditTransition} />
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ menu }) => {
  const { containerClassnames } = menu;
  return { containerClassnames };
};
const mapActionToProps = {};

export default withRouter(
  connect(mapStateToProps, mapActionToProps)(AppLayout)
);
