import React, { useState, useCallback, useEffect } from "react";
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Typography,
  Progress,
  Select,
} from "antd";
import { Icon, Button } from "semantic-ui-react";

const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "select" ? (
      <Select onChange={handleChange}>
        <Option value="Industrial">Industrial</Option>
        <Option value="Apartments">Apartments</Option>
        <Option value="Residential">Residential</Option>
        <Option value="Commercial">Commercial</Option>
        <Option value="Home">Home</Option>
        <Option value="School">School</Option>
        <Option value="Power">Power</Option>
        <Option value="Manufacture">Manufacture</Option>
        <Option value="Parking">Parking</Option>
      </Select>
    ) : inputType === "number" ? (
      <InputNumber />
    ) : (
      <Input />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const CompanyEditableTable = (props) => {
  console.log("company_tale", props.data);

  const [form] = Form.useForm();
  const originData = [];

  const [data, setData] = useState(originData);

  for (let i = 0; i < props.data.length; i++) {
    originData.push({
      key: i.toString(),
      name: props.data[i].name,
      cvar: props.data[i].cvar,
      company_code: props.data[i].company_code,
      no_of_assets: props.data[i].no_of_assets,
      //construction_cost: props.data[i].construction_cost.toFixed(2),
      //type: props.data[i].type,
      //area: props.data[i].area.toFixed(0),
      ownership: props.data[i].ownership,
      //floors: props.data[i].floors,
      //valuation: props.data[i].valuation,
      climate_score: props.data[i].climate_score.toFixed(0),
      //overall_loss: props.data[i].overall_loss,
      //address: `London Park no. ${i}`,
    });
  }
  useEffect(() => {
    if (props.data) {
      setData(originData);
    }
  }, [setData, props.data]);

  console.log("table data", data);
  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      name: "",
      age: "",
      address: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        console.log("new", newData[index]);
        props.update(item, newData[index]);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        console.log("save ka dusra case");
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "25%",
      editable: true,
    },
    {
      title: "Company Code",
      dataIndex: "company_code",
      width: "15%",
    },
    {
      title: "No of Assets",
      dataIndex: "no_of_assets",
      width: "15%",
    },
    /* {
      title: "Type",
      dataIndex: "type",
      width: "10%",
      editable: true,
    },
    {
      title: "Area (sqm)",
      dataIndex: "area",
      width: "10%",
      editable: true,
    },
    {
      title: "Floors",
      dataIndex: "floors",
      width: "10%",
      editable: true,
    },
    {
      title: "Valuation(in US$/sqm)",
      dataIndex: "valuation",
      width: "10%",
      editable: true,
      sorter: {
        compare: (a, b) => a.valuation - b.valuation,
        multiple: 3,
      },
    },*/

    {
      title: "Climate Score",
      dataIndex: "climate_score",
      width: "12%",
      sorter: {
        compare: (a, b) => a.valuation - b.valuation,
        multiple: 3,
      },
      render: (text, record) => <Progress percent={record.climate_score} />,
    },
    {
      title: "CVaR (%)",
      dataIndex: "cvar",
      sorter: {
        compare: (a, b) => a.valuation - b.valuation,
        multiple: 3,
      },
      width: "10%",
      editable: false,
    },
    {
      title: "Ownership (0-100)",
      dataIndex: "ownership",
      width: "10%",
      editable: true,
    },

    /* {
      title: "Revenue (in mil US$/year)",
      dataIndex: "revenue",
      sorter: {
        compare: (a, b) => a.valuation - b.valuation,
        multiple: 3,
      },
      width: "25%",
      editable: true,
    },
    {
      title: "Replacement Value (in US$/sqm)",
      dataIndex: "construction_cost",
      sorter: {
        compare: (a, b) => a.valuation - b.valuation,
        multiple: 3,
      },
      width: "25%",
      editable: true,
    },
    {
      title: "Analyse",
      dataIndex: "",
      width: "10%",
      render: (_, record) => (
        <Button
          style={{
            boxShadow: "10px 10px 10px #d1d9e6,-10px -10px 10px #f9f9f9",
          }}
          onClick={() => props.open(record.name)}
        >
          <Icon name="chart line" />
        </Button>
      ),
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <a
              href="javascript:;"
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
                color: "black",
              }}
            >
              Save
            </a>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },*/
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "type" ? "select" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        className="dark-mode-table"
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
    </Form>
  );
};

export default CompanyEditableTable;
