const data = [
{
  id: "Project",
  icon: "iconsminds-file",
  label: "menu.project",
  to: "/project",
},
{
  id: "Dashboard",
  icon: "iconsminds-dashboard",
  label: "menu.dashboard",
  to: "/dashboard",
},

{
  id: "Location",
  icon: "iconsminds-home",
  label: "menu.main",
  to: "/location",
},

{
  id:"Hazards",
  icon:"iconsminds-cloud-rain",
  label:"menu.hazards",
  to:'/hazard',
  subs:[]
},{
  id:'Invoice',
  icon:"iconsminds-invoice",
  label:"menu.invoice",
  to:'/invoice',
  subs:[]
},
{
  id:'TCFD Report',
  icon:"iconsminds-pantone",
  label:'menu.tcfd',
  to:'/tcfd'
},

{
  id:'Methodology',
  icon:"iconsminds-methodology",
  label:'menu.methodology',
  to:'/methodology',
  subs:[]
}
// {
//   id: "ui",
//   icon: "iconsminds-pantone",
//   label: "menu.ui",
//   to: "/app/ui",
//   subs: [

//     {
//       id: "ui-forms",
//       label: "menu.forms",
//       to: "/app/ui/forms",
//       subs: [{
//           icon: "simple-icon-notebook",
//           label: "menu.layouts",
//           to: "/app/ui/forms/layouts"
//         },
//         {
//           icon: "simple-icon-puzzle",
//           label: "menu.components",
//           to: "/app/ui/forms/components"
//         },
//         {
//           icon: "simple-icon-check",
//           label: "menu.validations",
//           to: "/app/ui/forms/validations"
//         },
//         {
//           icon: "simple-icon-magic-wand",
//           label: "menu.wizard",
//           to: "/app/ui/forms/wizard"
//         }
//       ]
//     },
//     {
//       id: "ui-components",
//       label: "menu.components",
//       to: "/app/ui/components",
//       subs: [{
//           icon: "simple-icon-bell",
//           label: "menu.alerts",
//           to: "/app/ui/components/alerts"
//         },
//         {
//           icon: "simple-icon-badge",
//           label: "menu.badges",
//           to: "/app/ui/components/badges"
//         },
//         {
//           icon: "simple-icon-control-play",
//           label: "menu.buttons",
//           to: "/app/ui/components/buttons"
//         },
//         {
//           icon: "simple-icon-layers",
//           label: "menu.cards",
//           to: "/app/ui/components/cards"
//         },
//         {
//           icon: "simple-icon-picture",
//           label: "menu.carousel",
//           to: "/app/ui/components/carousel"
//         },
//         {
//           icon: "simple-icon-chart",
//           label: "menu.charts",
//           to: "/app/ui/components/charts"
//         },
//         {
//           icon: "simple-icon-arrow-up",
//           label: "menu.collapse",
//           to: "/app/ui/components/collapse"
//         },
//         {
//           icon: "simple-icon-arrow-down",
//           label: "menu.dropdowns",
//           to: "/app/ui/components/dropdowns"
//         },
//         {
//           icon: "simple-icon-book-open",
//           label: "menu.editors",
//           to: "/app/ui/components/editors"
//         },

//         {
//           icon: "simple-icon-star",
//           label: "menu.icons",
//           to: "/app/ui/components/icons"
//         },
//         {
//           icon: "simple-icon-note",
//           label: "menu.input-groups",
//           to: "/app/ui/components/input-groups"
//         },
//         {
//           icon: "simple-icon-screen-desktop",
//           label: "menu.jumbotron",
//           to: "/app/ui/components/jumbotron"
//         },
//         {
//           icon: "simple-icon-map",
//           label: "menu.maps",
//           to: "/app/ui/components/maps"
//         },
//         {
//           icon: "simple-icon-docs",
//           label: "menu.modal",
//           to: "/app/ui/components/modal"
//         },
//         {
//           icon: "simple-icon-cursor",
//           label: "menu.navigation",
//           to: "/app/ui/components/navigation"
//         },
//         {
//           icon: "simple-icon-pin",
//           label: "menu.popover-tooltip",
//           to: "/app/ui/components/popover-tooltip"
//         },
//         {
//           icon: "simple-icon-shuffle",
//           label: "menu.sortable",
//           to: "/app/ui/components/sortable"
//         },
//         {
//           icon: "simple-icon-grid",
//           label: "menu.tables",
//           to: "/app/ui/components/tables"
//         }
//       ]
//     }
//   ]
// },
// {
//   id: "menu",
//   icon: "iconsminds-three-arrow-fork",
//   label: "menu.menu",
//   to: "/app/menu",
//   subs: [{
//       icon: "simple-icon-logout",
//       label: "menu.types",
//       to: "/app/menu/types"
//     },
//     {
//       icon: "simple-icon-layers",
//       label: "menu.levels",
//       to: "/app/menu/levels",
//       subs: [{
//           icon: "simple-icon-arrow-right",
//           label: "menu.third-level-1",
//           to: "/app/menu/levels/third-level-1"
//         },
//         {
//           icon: "simple-icon-arrow-right",
//           label: "menu.third-level-2",
//           to: "/app/menu/levels/third-level-2"
//         },
//         {
//           icon: "simple-icon-arrow-right",
//           label: "menu.third-level-3",
//           to: "/app/menu/levels/third-level-3"
//         }
//       ]
//     }
//   ]
// },
// {
//   id: "blankpage",
//   icon: "iconsminds-bucket",
//   label: "menu.blank-page",
//   to: "/app/blank-page"
// },
// {
//   id: "docs",
//   icon: "iconsminds-library",
//   label: "menu.docs",
//   to: "https://gogo-react-docs.coloredstrategies.com/",
//   newWindow: true
// }
];
export default data;
